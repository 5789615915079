const DiscountedPrice = ({value = '', insteadOfValue = '', discountLabel = ''}) => (
  <div className={'discounted-price'}>
    <div className={'discounted-price__value'}>{value}</div>
    <div className={'discounted-price__info'}>
      <div className={'discounted-price__info__instead_of_value'}>statt {insteadOfValue}</div>
      <div className={'discounted-price__info__discount-label'}>
        {discountLabel}
      </div>
    </div>
  </div>
);

export default DiscountedPrice;

