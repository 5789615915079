import * as React from 'react';
import {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Confirm, Label, Table} from "semantic-ui-react";
import {observer} from "mobx-react";
import {TicketStates} from "../../../models/ticket";
import {i18nArAttr, i18nArModel} from "@semabit/rails-i18n-tools";
import moment from "moment";
import {formatPrice} from "../../../formatters/priceFormatter";
import {CancelButton} from "../shared/buttons";
import {Link} from "react-router-dom";
import {adminRoute} from "../../../routing/routing";
import {useStore} from "../../../hooks/useStore";

const PresaleTicketItem = ({ticket, show}) => {
  const {t} = useTranslation();
  const store = useStore();
  const [showConfirmCancel, setShowConfirmCancel] = useState(false);
  const price = store.priceStore.getById(ticket.price_id);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (ticket?.price_id && show?.id) {
      store.priceStore.load(ticket.price_id, {params: {showId: show.id}});
    }
  }, [ticket?.price_id, show?.id]);

  const handleShowConfirmCancel = () => {
    setShowConfirmCancel(true);
  }

  const hideConfirmCancel = () => {
    setShowConfirmCancel(false);
  }

  const cancelTicket = () => {
    store.ticketStore.update({...ticket, state: TicketStates.canceled})
      .then(() => {
        store.presaleOrderStore.loadAll();
        this.hideConfirmCancel();
      });
  }


  return (
    <Table.Row>
      <Table.Cell>
        # {ticket.id}
      </Table.Cell>
      <Table.Cell>
        {show && (
          <>
            <Link to={adminRoute(`/shows/${show.id}`)}>
              <b>{show.name} </b>
            </Link>
            am {show.begins_at ? moment(show.begins_at).format() : '-'}
          </>
        )}
      </Table.Cell>
      <Table.Cell>
        {ticket.state === 'canceled'
          ? <Label color={'red'} horizontal>{t(i18nArAttr('ticket.canceled'))}</Label>
          : null
        }
        {ticket.state === 'entered'
          ? <Label color={'blue'} horizontal>{t(i18nArAttr('ticket.entered'))}</Label>
          : null
        }
        {ticket.state === 'created'
          ? <Label color={'teal'} horizontal>{t(i18nArAttr('ticket.active'))}</Label>
          : null
        }
      </Table.Cell>
      <Table.Cell>
        {moment(ticket.created_at).format()}
      </Table.Cell>
      <Table.Cell>{price ? t(i18nArAttr(`price.price_types.${price.price_type}`)) : '-'}</Table.Cell>
      <Table.Cell>{formatPrice(ticket.price)}</Table.Cell>
      <Table.Cell>
        <dl>
          {ticket.remarks && price && (
            <>
              <dt>{t(i18nArAttr(`price.ticket_remark_label.${price?.price_type}`))}</dt>
              <dd>{ticket.remarks}</dd>
            </>
          )}
        </dl>
      </Table.Cell>
      <Table.Cell>
        <CancelButton
          className={ticket.isCanceled ? 'disabled' : ''}
          disabled={ticket.isCanceled}
          onClick={handleShowConfirmCancel}
        />
        <Confirm
          open={showConfirmCancel}
          onCancel={hideConfirmCancel}
          onConfirm={cancelTicket}
          content={t('admin.entity.confirm_cancel', {model_name: t(i18nArModel(`ticket.one`))})}
        />
      </Table.Cell>
    </Table.Row>
  )
}

export default observer(PresaleTicketItem);
