import {Container} from "semantic-ui-react";
import * as React from 'react';
import {useEffect} from 'react';
import {observer} from "mobx-react";
import {Route, Switch} from "react-router-dom";
import ShowList from "./showList";
import ShowDetail from "./showDetail";
import Cart from "./cart";
import CartConfirmationPage from "./cartConfirmationPage";
import CartContactForm from "./cartContactForm";
import CartPaymentPage from "./cartPaymentPage";
import NotFound from "../notFound";
import Agb from "./agb";
import {useQuery} from "../../hooks/useQuery";
import {useStore} from "../../hooks/useStore";
import {useHistory} from "react-router";
import {cartDiscountSessionStorageKey} from "../../models/cartDiscount";
import useFacebookPixel from "../../hooks/useFacebookPixel";
import CreditCardConfirm from "./CreditCardConfirm";

const PreSale = () => {

  useFacebookPixel();
  const cartDiscountUuid = useQuery('cd');
  const history = useHistory();
  const store = useStore();

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (cartDiscountUuid && cartDiscountUuid !== store.cartDiscountStore.currentCartDiscountUuid) {
      sessionStorage.setItem(cartDiscountSessionStorageKey, cartDiscountUuid);
      history.replace('/');
    }
  }, [cartDiscountUuid]);

  return (
    <Container>
      <Switch>
        <Route exact path={`/`} component={ShowList}/>
        <Route path={`/shows/:id`} component={ShowDetail}/>
        <Route path={`/cart`} component={Cart}/>
        <Route path={`/agb`} component={Agb}/>
        <Route path={`/checkout/address`} component={CartContactForm}/>
        <Route path={`/checkout/confirmation`} component={CartConfirmationPage}/>
        <Route path={`/checkout/payment`} component={CartPaymentPage}/>
        <Route path={`/3ds-confirm`} component={CreditCardConfirm}/>
        <Route path={`/*`} component={NotFound}/>
      </Switch>
    </Container>
  )
}

export default observer(PreSale);
