import * as React from "react";
import compose from "compose-function";
import {withTranslation} from "react-i18next";
import {inject, observer} from "mobx-react";
import {Item, Header, Divider, Button} from "semantic-ui-react";
import TicketCartItem from "./ticketCartItem";
import {ClearFloat} from "../shared/clearfloat";
import {Receipt} from "../../models/receipt";
import {resourcePath} from "../../middleware/apiPath";
import {showPdfFromUrl} from "../../tools/files";
import {receiptsResourcePath} from "../../middleware/endpoints/receipts";

class TicketCart extends React.Component {
  render() {
    if (!this.props.store.receiptStore.current) {
      return (
        <div className={'empty-cart'}>
          <p>Keine Tickets im Warenkorb</p>
        </div>
      )
    }

    return <div className={'cart'}>
      <Item.Group relaxed divided>
        {this.props.store.receiptStore.current.groupedTickets.map(tickets => {
          return <TicketCartItem tickets={tickets} key={tickets.map(t => t.id).join('-')} prices={this.props.prices}
                                 onIncrement={(tickets) => this.addTicket(tickets)}
                                 onDecrement={(tickets) => this.removeTicket(tickets)}
                                 onRemove={(tickets) => this.removeTickets(tickets)}
                                 onPriceChange={(tickets, priceType) => this.updateTickets(tickets, priceType)}/>;
        })}
      </Item.Group>
      <Divider/>
      <Header as={'h2'} className={'right floated price-total'}>
        <span>{`(Anzahl Tickets: ${this.props.store.receiptStore.current.tickets.length})`} Total: </span>
        {this.props.store.receiptStore.current.formatPrice()}
      </Header>
      <ClearFloat/>
      <Button primary floated={'right'} onClick={() => this.completeOrder()}>Abschliessen & Drucken</Button>
      <ClearFloat/>
    </div>
  }

  componentDidMount() {
    this.loadCurrentReceipt(this.props.show.id);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.show.id !== this.props.show.id) {
      this.loadCurrentReceipt(this.props.show.id);
    }
  }

  loadCurrentReceipt(showId) {
    this.props.store.receiptStore.loadCurrent({params: {showId: showId}});
  }

  addTicket(ticketsInGroup) {
    if (ticketsInGroup.length === 0) return;
    if (ticketsInGroup[0].reservation_id) return;

    const ticket = {
      show_id: this.props.show.id,
      price_id: ticketsInGroup[0].price_id,
      receipt_id: this.props.store.receiptStore.current.id,
      state: "created",
      sales_point_id: this.props.salesPoint.id,
    };

    this.props.store.ticketStore.create(ticket, {skipNotification: true}).then(ticket => {
      this.loadCurrentReceipt(this.props.show.id);
      this.props.store.showStore.load(this.props.show.id);
    }).catch(error => {
      this.props.store.messageStore.addMessage({type: 'error', title: error.joinMessages()});
    });
  }

  removeTicket(ticketsInGroup) {
    if (!ticketsInGroup || ticketsInGroup.length === 0) return;

    const ticket = ticketsInGroup[ticketsInGroup.length - 1];
    this.props.store.ticketStore.delete(ticket.id, {
      params: {showId: this.props.show.id},
      skipNotification: true
    }).then(() => {
      this.refresh(!!ticket.reservation_id);
    });
  }

  removeTickets(ticketsInGroup) {
    if (!ticketsInGroup || ticketsInGroup.length === 0) return;

    const options = {params: {showId: this.props.show.id}, skipNotification: true};
    const ticketIds = ticketsInGroup.map(t => t.id);

    return this.props.store.ticketStore.deleteMultiple(ticketIds, options).then(() => {
      this.refresh(!!ticketsInGroup[0].reservation_id);
    });
  }

  updateTickets(ticketsInGroup, newPrice) {
    if (!ticketsInGroup || ticketsInGroup.length === 0) return;
    if (ticketsInGroup[0].price_id === newPrice.id) return;

    const ticketIds = ticketsInGroup.map(t => t.id);

    this.props.store.ticketStore.updateMultiple(ticketIds, {
      params: {
        showId: this.props.show.id,
        updates: {price_id: newPrice.id}
      }
    }).then(() => {
      this.loadCurrentReceipt(this.props.show.id);
    });
  }

  refresh(reloadReservations = false) {
    this.loadCurrentReceipt(this.props.show.id);
    if (reloadReservations) {
      this.props.store.reservationStore.loadAll({params: {show_id: this.props.show.id}});
    }
    this.props.store.showStore.load(this.props.show.id);
  }

  completeOrder() {
    const receiptId = this.props.store.receiptStore.current.id;

    this.props.store.receiptStore.complete(receiptId, {
      params: {showId: this.props.show.id},
      skipNotification: true
    }).then(() => {
      showPdfFromUrl(resourcePath(receiptsResourcePath(this.props.show.id, `/${receiptId}.pdf`)));
      return this.loadCurrentReceipt(this.props.show.id);
    }).catch(errors => {
      const errorMessage = errors.getSpecialErrorsForModel(new Receipt()).joinMessages();
      if (errorMessage) {
        this.props.store.messageStore.addMessage({type: 'error', title: errorMessage});
      }
    });
  }
}

export default compose(
  withTranslation('translation'),
  inject('store'),
  observer,
)(TicketCart);
