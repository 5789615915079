import * as React from 'react';
import {withTranslation} from 'react-i18next';
import {inject, observer} from "mobx-react";
import compose from "compose-function";
import {i18nArAttr} from "@semabit/rails-i18n-tools";
import {EntityTable} from "../shared/tables";
import {loadEntities} from "../shared/loadEntities";
import PresaleOrderListItem from "./listItem";
import {Header, Message, Select} from "semantic-ui-react";
import {downloadPdfFromUrl, showPdfFromUrl} from "../../../tools/files";
import {resourcePath} from "../../../middleware/apiPath";
import {presaleOrderResourcePath} from "../../../middleware/endpoints/presaleOrders";

class PresaleOrderList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFestival: null
    };
    this.showPresaleOrder = this.showPresaleOrder.bind(this);
    this.downloadPresaleOrder = this.downloadPresaleOrder.bind(this);
  }

  componentDidMount() {
    if (this.props.festivals.length) {
      const firstFestival = this.props.festivals[0];
      const currentFestivals = this.props.festivals.filter((festival) => !festival.past)
      if (currentFestivals.length) {
        this.setState({selectedFestival: currentFestivals[0].id});
      } else {
        this.setState({selectedFestival: firstFestival.id});
      }
    }
  }

  showPresaleOrder(presaleOrder) {
    if (!presaleOrder?.id) {
      return;
    }
    showPdfFromUrl(resourcePath(presaleOrderResourcePath(`/${presaleOrder.id}.pdf`)));
  }

  downloadPresaleOrder(presaleOrder) {
    if (!presaleOrder?.id) {
      return;
    }
    downloadPdfFromUrl(resourcePath(presaleOrderResourcePath(`/${presaleOrder.id}.pdf`)));
  }

  render() {
    const {t, shows, festivals} = this.props;

    if (!this.state.selectedFestival) return null;

    const headers = [
      '#',
      t(i18nArAttr('presale_order.bought_at')),
      t(i18nArAttr('presale_order.firstname')),
      t(i18nArAttr('presale_order.lastname')),
      t(i18nArAttr('presale_order.email')),
      t(i18nArAttr('presale_order.email_state')),
      t(i18nArAttr('presale_order.tickets')),
      ''
    ];

    const handleOnChange = (e, data) => {
      this.setState({
        selectedFestival: data.value
      })
    }

    const options = festivals.map((festival) => ({
      key: festival.id,
      value: festival.id,
      text: festival.name,
    }))

    const filteredPresaleOrders = this.props.presaleOrders
      .reverse()
      .filter((presaleOrder) => !this.state.selectedFestival || (presaleOrder.festival_id === this.state.selectedFestival))

    return (
      <>
        <div style={{marginBottom: '40px'}}>
          <Select
            field={'selected_festival'}
            defaultValue={this.state.selectedFestival}
            placeholder={'Wählen Sie ein Festival aus'}
            options={options}
            onChange={handleOnChange}
          />
        </div>
        <div>
          <Header style={{marginBottom: 40}} as={'h2'}>{t('activerecord.models.presale_order.other')}</Header>
          {filteredPresaleOrders.length > 0 ? (
            <EntityTable headers={headers}>
              {filteredPresaleOrders.map((presaleOrder) =>(
                <PresaleOrderListItem
                  key={presaleOrder.id}
                  shows={shows}
                  presaleOrder={presaleOrder}
                  onShow={this.showPresaleOrder}
                  onDownload={this.downloadPresaleOrder}
                />
              ))}
            </EntityTable>
          ) : <Message>Für dieses Festival wurden noch keine Tickets über den Vorverkauf verkauft</Message>}
        </div>
      </>
    )
  }
}

export default compose(
  loadEntities('festivalStore', 'festivals'),
  loadEntities('showStore', 'shows'),
  loadEntities('presaleOrderStore', 'presaleOrders'),
  withTranslation('translation'),
  inject('store'),
  observer
)(PresaleOrderList);
