import * as React from "react";
import {useEffect, useState} from "react";
import NewEntity from "../shared/newEntity";
import CartDiscountForm from "./form";
import {useStore} from "../../../hooks/useStore";
import {useParams} from "react-router";
import {observer} from "mobx-react";
import {Loader} from "semantic-ui-react";

const NewCartDiscount = () => {
  const store = useStore();
  const {festivalId} = useParams();
  const festival = store.festivalStore.getById(festivalId);
  const [loading, setLoading] = useState(false);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (festivalId && !festival && !loading) {
      (async () => {
        setLoading(true);
        await store.festivalStore.load(festivalId);
        setLoading(false);
      })();
    }
  }, [festivalId]);

  if (loading) {
    return <Loader active inline className={'centered'}/>;
  }

  if (!festival) {
    return null;
  }

  return (
    <NewEntity
      entitiesPath={`/festivals/${festival.id}`}
      name={'cart_discount'}
      form={CartDiscountForm}
      festival_id={festival.id}
      titleI18nKey={'admin.entity.new.m'}
    />
  );
}

export default observer(NewCartDiscount);
