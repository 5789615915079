import {decorate, observable, runInAction} from "mobx";
import {Api} from "../middleware/api";
import {EntityStore} from "./entityStore";
import {CartTicket} from "../models/cartTicket";

decorate(CartTicket, {
  quantity: observable,
  cart_id: observable,
  show_id: observable,
  price_id: observable,
  total_price: observable,
});

export class CartTicketStore extends EntityStore {
  cartTickets = [];

  constructor(rootStore) {
    super(rootStore, 'cartTickets', Api.cartTickets, CartTicket);
  }

  preview(options) {
    this.pendingRequests++;
    return this.api.preview(options.params).then(entity => {
      entity = this.modelClass.fromPlainObject(entity);
      runInAction(() => {
          this.pendingRequests--;
        }
      );
      return entity;
    }).catch(e => this.handleApiError(e));
  }
}

decorate(CartTicketStore, {
  cartTickets: observable,
});
