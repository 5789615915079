import * as React from "react";
import {Statistic} from "semantic-ui-react";
import compose from "compose-function";
import {withTranslation} from "react-i18next";
import {inject, observer} from "mobx-react";
import {i18nArAttr} from "@semabit/rails-i18n-tools";

class TicketStatistics extends React.Component {
  render() {

    const className = typeof this.props.className === 'string' ? this.props.className : 'right floated';

    const color = this.props.show.available_tickets_count ? 'black' : 'red';

    return (
      <Statistic
        style={{minWidth: '100%'}}
        label={<span style={{textTransform: 'uppercase', fontSize: '0.8rem'}}>{this.props.t(i18nArAttr('show.available_tickets_count'))}</span>}
        value={this.props.show.available_tickets_count}
        className={className}
        color={color}
      />
    )

  }

  componentDidMount() {
    if (this.props.autoUpdate === true) {
      this.interval = setInterval(() => {
        this.props.store.showStore.load(this.props.show.id);
      }, 2000)
    }
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

}

export default compose(
  withTranslation('translation'),
  inject('store'),
  observer
)(TicketStatistics);
