import * as React from "react";
import {withTranslation} from "react-i18next";
import CustomForm from "../../forms/form";
import Text from "../../forms/text";
import TextArea from "../../forms/textarea";
import Select from "../../forms/select";
import {inject, observer} from "mobx-react";
import {Form} from "semantic-ui-react";
import {withRouter} from "react-router";
import compose from "compose-function";
import {SubmitButton} from "../shared/buttons";
import {i18nArAttr} from "@semabit/rails-i18n-tools";
import {SalesPointSettings} from "../../../models/salesPoint";

class SalesPointForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {errors: {}};
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.successCallback = this.successCallback.bind(this);
    this.errorCallback = this.errorCallback.bind(this);
  }

  handleOnSubmit(salesPoint) {
    salesPoint = {
      ...salesPoint,
      id: this.props.salesPoint ? this.props.salesPoint.id : null
    };

    if (this.props.salesPoint) {
      this.props.store.salesPointStore.update(salesPoint).then(this.successCallback).catch(this.errorCallback);
    } else {
      this.props.store.salesPointStore.create(salesPoint).then(this.successCallback).catch(this.errorCallback);
    }
  }

  errorCallback(formErrors) {
    console.error({formErrors});
    this.setState({errors: formErrors});
  }

  successCallback(salesPoint) {
    this.setState({errors: {}});
    if (this.props.redirectTo) {
      this.props.history.push(this.props.redirectTo);
    }
  }

  render() {
    // initialValues are not updated in the form once rendered, re-render does not update them either.
    const {t, salesPoint = {}} = this.props;

    const printModeOptions = [
      {
        key: SalesPointSettings.print_mode.receipt,
        value: SalesPointSettings.print_mode.receipt,
        text: t(i18nArAttr('sales_point.settings.print_mode.values.receipt'))
      },
      {
        key: SalesPointSettings.print_mode.presale_order,
        value: SalesPointSettings.print_mode.presale_order,
        text: t(i18nArAttr('sales_point.settings.print_mode.values.presale_order'))
      }
    ];

    if (!salesPoint.settings?.print_mode) {
      if (!salesPoint.settings) {
        salesPoint.settings = {};
      }
      salesPoint.settings.print_mode = printModeOptions[0].value;
    }

    return (
      <CustomForm onSubmit={this.handleOnSubmit} initialValues={{...salesPoint}}>
        <Form.Field>
          <label htmlFor={'name'}>{t(i18nArAttr('sales_point.name'))}*</label>
          <Text field="name" errors={this.state.errors.name}/>
        </Form.Field>
        <Form.Field>
          <label htmlFor={'location'}>{t(i18nArAttr('sales_point.location'))}</label>
          <Text field="location" errors={this.state.errors.location}/>
        </Form.Field>
        <Form.Field>
          <label htmlFor={'notes'}>{t(i18nArAttr('sales_point.notes'))}</label>
          <TextArea field="notes" errors={this.state.errors.notes}/>
        </Form.Field>
        <Form.Field>
          <label htmlFor={'settings.print_mode'}>{t(i18nArAttr('sales_point.settings.print_mode.label'))}</label>
          <Select
            field={'settings.print_mode'}
            placeholder={'Wählen Sie ein Druckmodus aus'}
            options={printModeOptions}
          />
        </Form.Field>
        <SubmitButton loading={this.props.store.salesPointStore.isLoading}/>
      </CustomForm>
    )
  }
}

export default compose(
  withRouter,
  withTranslation('translation'),
  inject('store'),
  observer
)(SalesPointForm);
