import * as React from "react";
import {Header} from "semantic-ui-react";
import {i18nArModel} from "@semabit/rails-i18n-tools";
import {adminRoute} from "../../../routing/routing";
import {BackButton} from "./buttons";
import compose from "compose-function";
import {withTranslation} from "react-i18next";

class NewEntity extends React.Component {
  render() {
    const {form: Form, t, name, titleI18nKey = 'admin.entity.new.m', ...rest} = this.props;
    return <div>
      <Header as={'h1'}>{t(titleI18nKey, { model_name: t(i18nArModel(`${name}.one`)) })}</Header>
      <Form redirectTo={adminRoute(this.props.entitiesPath)} {...rest}/>
      <BackButton route={adminRoute(this.props.entitiesPath)} />
    </div>
  }
}

export default compose(
  withTranslation('translation'),
)(NewEntity);