import * as React from "react";
import {inject, observer} from "mobx-react";
import compose from "compose-function";
import {withTranslation} from "react-i18next";
import {staffRoute} from "../../routing/routing";
import {loadEntities} from "../admin/shared/loadEntities";
import {loadEntity} from "../admin/shared/loadEntity";
import {EntryTypes} from "../../models/entry";
import {BackButton} from "../admin/shared/buttons";
import {Label, Table} from "semantic-ui-react";
import moment from "moment";
import {i18nArAttr} from "@semabit/rails-i18n-tools";
import {EntityTable} from "../admin/shared/tables";

class ScannedTicketsView extends React.Component {

  render() {
    const {t, show, entries} = this.props;
    const scannedEntries = entries.filter(
      e => (
        e.entry_type === EntryTypes.eveningSale && e.ticket_id !== null)
        || (e.entry_type === EntryTypes.festivalPass && e.festival_pass_id !== null)
        || (e.entry_type === EntryTypes.reservation && e.ticket_id !== null)
    );
    const headers = [
      '',
      <>
        <div>{t(i18nArAttr('ticket.number'))}</div>
        <div>{t(i18nArAttr('festival_pass.number'))}</div>
      </>,
      t(i18nArAttr('entry.created_at')),
      t(i18nArAttr('entry.entry_type')),
    ];

    return (
      <div className={'reservations-view'}>
        <BackButton route={staffRoute(`/entry/shows/${show.id}`)} style={{margin: '0', marginBottom: '20px'}}/>
        {scannedEntries.length <= 0
          ? <p>Bisher wurden für diese Show keine Tickets eingescannt.</p>
          : <EntityTable headers={headers}>
            {scannedEntries.map(entry => {
              return (
                <Table.Row key={entry.id}>
                  <Table.Cell>
                    <Label color='teal' horizontal>{this.props.t(i18nArAttr('ticket.entered'))}</Label>
                  </Table.Cell>
                  <Table.Cell>
                    {entry.ticket_number ? <div>{this.props.t(i18nArAttr('ticket.number'))}: {entry.ticket_number}</div> : null}
                    {entry.festival_pass_number ? <div>{this.props.t(i18nArAttr('festival_pass.number'))}: {entry.festival_pass_number}</div> : null}
                  </Table.Cell>
                  <Table.Cell>
                    {entry.created_at ? moment(entry.created_at).format() : '-'}
                  </Table.Cell>
                  <Table.Cell>
                    {this.props.t(i18nArAttr('entry.entry_types.' + entry.entry_type))}<br/>
                  </Table.Cell>
                </Table.Row>
              )
            })}
          </EntityTable>
        }
      </div>
    )
  }
}

export default compose(
  withTranslation('translation'),
  loadEntity('showStore', 'show', staffRoute('/entry'), {lazyLoad: true}),
  loadEntities('entryStore', 'entries', {
    storeParams: (props) => ({params: {showId: props.show.id}}),
    autoUpdate: true
  }),
  loadEntities('ticketStore', 'tickets', {
    storeParams: (props) => ({params: {showId: props.show.id}}),
    autoUpdate: true
  }),
  inject('store'),
  observer
)(ScannedTicketsView);

