import * as React from 'react';
import {withTranslation} from 'react-i18next';
import {inject, observer} from "mobx-react";
import compose from "compose-function";
import {i18nArAttr, i18nArModel} from "@semabit/rails-i18n-tools";
import {EntityTable} from "../shared/tables";
import {loadEntities} from "../shared/loadEntities";
import ReservationListItem from "./listItem";
import ReservationSearchInput from "../shared/reservationSearchInput";

class ReservationSearch extends React.Component {
  constructor(props) {
    super(props);
    const filteredReservations = this.props.reservations.filter(r => !this.props.shows.find(s => s.id === r.show_id).past);

    this.state = {
      results: filteredReservations,
      filteredResults: filteredReservations
    }
  }

  render() {
    const {t} = this.props;
    const headers = [
      t(i18nArAttr('reservation.first_name')),
      t(i18nArAttr('reservation.last_name')),
      t(i18nArAttr('reservation.already_paid')),
      t(i18nArModel('show.one')),
      t(i18nArAttr('reservation.number_of_persons')),
      t(i18nArAttr('reservation.notes')),
      ''
    ];

    return (
      <div>
        <ReservationSearchInput
          reservations={this.state.results}
          onResultsChange={(results) => this.setState({'filteredResults': results})}
        />
        {this.state.filteredResults.length <= 0 ?
          <p style={{marginTop: 20}}>Keine passenden Reservationen gefunden</p>
          :
          <EntityTable headers={headers}>
            {
              this.state.filteredResults.map((reservation) =>
                <ReservationListItem
                  searchTable={true}
                  key={reservation.id}
                  reservation={reservation}
                  show={this.props.shows.find(show => reservation.show_id === show.id)}
                />)
            }
          </EntityTable>
        }
      </div>
    )
  }
}

export default compose(
  loadEntities('reservationStore', 'reservations',{
    storeParams: (props) => ({params: {current_festival: true}})
  }),
  loadEntities('showStore', 'shows'),
  withTranslation('translation'),
  inject('store'),
  observer
)(ReservationSearch);
