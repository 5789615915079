import * as React from "react";
import {Statistic} from "semantic-ui-react";
import compose from "compose-function";
import {withTranslation} from "react-i18next";
import {inject, observer} from "mobx-react";
import {i18nArModel} from "@semabit/rails-i18n-tools";

class EntryStatistics extends React.Component {
  render() {
    const {entries} = this.props
    const className = typeof this.props.className === 'string' ? this.props.className : 'right floated';
    const entered = entries.reduce((prev, current) => {
      return prev + current.quantity
    }, 0);

    const color = 'black';

    return (
      <Statistic
        style={{minWidth: '100%'}}
        label={<span style={{textTransform: 'uppercase', fontSize: '0.8rem'}}>{this.props.t(i18nArModel('entry.other'))}</span>}
        value={entered}
        className={className}
        color={color}
      />
    )

  }

  componentDidMount() {
    if (this.props.autoUpdate === true) {
      this.interval = setInterval(() => {
        this.props.store.showStore.load(this.props.show.id);
        this.props.store.entryStore.loadAll({params: {showId: this.props.show.id}})
      }, 2000)
    }
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

}

export default compose(
  withTranslation('translation'),
  inject('store'),
  observer
)(EntryStatistics);
