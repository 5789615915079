import * as React from "react";
import {Tab, Table} from "semantic-ui-react";
import compose from "compose-function";
import {withTranslation} from "react-i18next";
import {inject, observer} from "mobx-react";
import ReservationListItem from "../point-of-sale/reservationListItem";
import {EntryTypes} from "../../models/entry";

const ReservationsTabs = (props) => {

  const {t, reservations = [], show, store} = props;

  const checkInReservation = (reservation) => {
    createEntry(reservation, reservation.number_of_persons);
  };

  const checkOutReservation = (reservation) => {
    createEntry(reservation, -1 * reservation.number_of_persons);
  };

  const createEntry = (reservation, quantity) => {
    const entry = {
      entry_type: EntryTypes.reservation,
      show_id: show.id,
      user_id: store.authStore.user.id,
      quantity: quantity,
      reservation_id: reservation.id
    };
    store.entryStore.create(entry, {skipNotification: true}).then(() => {
      store.reservationStore.loadAll({params: {show_id: show.id}})
    });
  };

  const Placeholder = <p className={'no-reservations'}>Keine Einträge</p>;

  const openReservations = reservations.filter(r => r.entered === false);
  const checkedReservations = reservations.filter(r => r.entered === true);

  const OpenReservationsTable = (
    <Table unstackable>
      <Table.Body>
        {openReservations.map((reservation) => {
          return <ReservationListItem key={reservation.id} reservation={reservation} show={show}
                                      onAdd={checkInReservation} showAlreadyPaidBadge={false} showNotes={false} icon={'add'}/>;
        })}
      </Table.Body>
    </Table>
  );

  const CheckedReservationsTable = (
    <Table unstackable>
      <Table.Body>
        {checkedReservations.map((reservation) => {
          return <ReservationListItem key={reservation.id} reservation={reservation} show={show}
                                      onAdd={checkOutReservation} showAlreadyPaidBadge={false}
                                      icon={'minus'} showNotes={false}/>;
        })}
      </Table.Body>
    </Table>
  );

  return (
    <Tab className={'reservations-tabs'} panes={[
      {
        menuItem: t('entry.reservations.not_yet_entered'),
        render: () => (<Tab.Pane>{openReservations.length > 0 ? OpenReservationsTable : Placeholder}</Tab.Pane>)
      },
      {
        menuItem: t('entry.reservations.already_entered'),
        render: () => (<Tab.Pane>{checkedReservations.length > 0 ? CheckedReservationsTable : Placeholder}</Tab.Pane>)
      }
    ]}/>
  )
};

export default compose(
  withTranslation('translation'),
  inject('store'),
  observer
)(ReservationsTabs);