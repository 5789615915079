import * as React from "react";
import {useEffect, useState} from "react";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import {i18nArModel} from "@semabit/rails-i18n-tools";
import {adminRoute} from "../../../routing/routing";
import {EntityDetailLayout} from "../shared/entityDetailLayout";
import CartDiscountForm from "./form";
import {useStore} from "../../../hooks/useStore";
import {useParams} from "react-router";
import {Loader} from "semantic-ui-react";

const EditCartDiscount = () => {
  const store = useStore();
  const {festivalId, id: cartDiscountId} = useParams();
  const {t} = useTranslation();
  const cartDiscountPath = `/festivals/${festivalId}/cart_discounts/${cartDiscountId}`;
  const [loadingCartDiscount, setLoadingCartDiscount] = useState(false);
  const cartDiscount = store.cartDiscountStore.getById(cartDiscountId);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (cartDiscountId && !cartDiscount && !loadingCartDiscount) {
      (async () => {
        setLoadingCartDiscount(true);
        await store.cartDiscountStore.load(cartDiscountId, {params: {festivalId}});
        setLoadingCartDiscount(false);
      })();
    }
  }, [cartDiscountId]);

  if (loadingCartDiscount) {
    return (
      <EntityDetailLayout
        title={t('admin.entity.edit', {model_name: t(i18nArModel(`cart_discount.one`))})}
      >
        <Loader/>
      </EntityDetailLayout>
    )
  }

  if (!cartDiscount) {
    return null;
  }

  return (
    <EntityDetailLayout
      title={t('admin.entity.edit', {model_name: t(i18nArModel(`cart_discount.one`))})}
      backPath={cartDiscountPath}
    >
      <CartDiscountForm
        redirectTo={adminRoute(cartDiscountPath)}
        cartDiscount={cartDiscount}
      />
    </EntityDetailLayout>
  )
}

export default observer(EditCartDiscount);

