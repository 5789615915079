import {useEffect} from "react";
import {useStore} from "./useStore";

const useCartDiscount = ({festivalId}) => {
  const store = useStore();

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (festivalId && store.cartDiscountStore.currentCartDiscountUuid && !store.cartDiscountStore.currentDiscount) {
      store.cartDiscountStore.loadAll({
        params: {
          festivalId: festivalId,
          uuid: store.cartDiscountStore.currentCartDiscountUuid
        }
      })
    }
  }, [festivalId, store.cartDiscountStore.currentCartDiscountUuid, store.cartDiscountStore.currentDiscount]);
}

export default useCartDiscount;
