import * as React from "react";
import {useState} from "react";
import {observer} from "mobx-react";
import {useStore} from "../../../hooks/useStore";
import {useHistory, useParams} from "react-router";
import {useTranslation} from "react-i18next";
import CustomForm from "../../forms/form";
import {Form, Label} from "semantic-ui-react";
import {SubmitButton} from "../shared/buttons";
import {i18nArAttr} from "@semabit/rails-i18n-tools";
import Text from "../../forms/text";
import TextArea from "../../forms/textarea";
import {CartDiscount, cartDiscountTypes} from "../../../models/cartDiscount";

const CartDiscountForm = ({cartDiscount = new CartDiscount(), redirectTo}) => {

  const store = useStore();
  const history = useHistory();
  const {festivalId} = useParams();
  const [errors, setErrors] = useState({});
  const {t} = useTranslation();
  const initialValues = {
    ...cartDiscount,
    ...(!cartDiscount.id ? {
      percentage: 20
    } : {}),
    ...(cartDiscount.id ? {
      percentage: cartDiscount.percentage * 100
    } : {})
  }

  const errorCallback = (formErrors) => {
    setErrors(formErrors);
  }

  const successCallback = () => {
    setErrors({});

    this.setState({errors: {}});
    if (redirectTo) {
      history.push(redirectTo);
    }
  }

  const handleOnSubmit = (values) => {
    const request = {
      festival_id: festivalId,
      discount_type: cartDiscountTypes.percentage,
      ...values,
      percentage: values.percentage / 100,
      id: cartDiscount.id || undefined,
      description: values.description || ''
    };

    if (cartDiscount.id) {
      store.cartDiscountStore.update(request).then(successCallback).catch(errorCallback);
    } else {
      store.cartDiscountStore.create(request).then(successCallback).catch(errorCallback);
    }
  }


  return (
    <CustomForm onSubmit={handleOnSubmit} initialValues={initialValues}>
      <Form.Field>
        <label htmlFor={'label'}>{t(i18nArAttr('cart_discount.label'))}*</label>
        <Text field={'label'} errors={errors.label} placeholder={'z.B. SBB Rabatt'}/>
      </Form.Field>
      <Form.Field>
        <label htmlFor={'percentage'}>{t(i18nArAttr('cart_discount.percentage'))}*</label>
        <Text
          field={'percentage'}
          errors={errors.label}
          labelPosition='right'
          type={'number'}
          min={1}
          max={100}
        >
          <input/>
          <Label>%</Label>
        </Text>
      </Form.Field>
      <Form.Field>
        <label htmlFor={'description'}>{t(i18nArAttr('cart_discount.description'))}</label>
        <TextArea field={'description'} errors={errors.label}/>
      </Form.Field>
      <SubmitButton style={{marginTop: 30}}/>
    </CustomForm>
  )
}

export default observer(CartDiscountForm);
