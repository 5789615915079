import {BaseModel} from "./base";
import {formatPrice} from "../formatters/priceFormatter";

export class Price extends BaseModel {
  id = 0;
  show_id = 0;
  price = 0;
  price_currency = '';
  price_type = '';
  internal = false;
  require_ticket_remarks = false;

  formatPrice() {
    return formatPrice(this.price, this.price_currency);
  }

  static fromPlainObject(plainObject) {
    const price = new Price();
    return Object.assign(price, plainObject);
  }
}

export const priceTypes = [
  'normal',
  'reduced',
  'free',
  'special',
  'culture',
  'patron',
  'festivalpass',
  'donation1',
  'donation2',
  'donation3',
  'donation4',
  'donation5',
];
