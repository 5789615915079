import * as React from "react";
import {Button, Confirm, Label, Popup, Table} from "semantic-ui-react";
import {formatPrice} from "../../formatters/priceFormatter";
import moment from "moment";
import compose from "compose-function";
import {withTranslation} from "react-i18next";
import {inject, observer} from "mobx-react";
import {i18nArAttr, i18nArModel} from "@semabit/rails-i18n-tools";
import {TicketStates} from "../../models/ticket";

class ReceiptListItem extends React.Component {

  state = {showConfirmCancel: false}

  constructor(props) {
    super(props);

    this.showConfirmCancel = this.showConfirmCancel.bind(this);
    this.hideConfirmCancel = this.hideConfirmCancel.bind(this);
    this.showClicked = this.showClicked.bind(this);
    this.downloadClicked = this.downloadClicked.bind(this);
    this.cancelTickets = this.cancelTickets.bind(this);
  }

  showConfirmCancel() {
    this.setState({showConfirmCancel: true});
  }

  hideConfirmCancel() {
    this.setState({showConfirmCancel: false});
  }

  showClicked() {
    if (this.props.onShow) this.props.onShow(this.props.receipt);
  }

  downloadClicked() {
    if (this.props.onDownload) this.props.onDownload(this.props.receipt);
  }

  cancelTickets() {
    this.props.store.ticketStore.updateMultiple(this.props.tickets.map(ticket => ticket.id), {
      params: {
        showId: this.props.show.id,
        updates: {state: TicketStates.canceled}
      }
    }).then(() => {
      this.props.store.showStore.load(this.props.show.id);
      this.hideConfirmCancel();
    });
  }

  render() {
    const receiptCanceled = this.props.receipt.tickets_not_canceled_count === 0;

    return (
      <Table.Row>
        <Table.Cell>
          {"#" + this.props.receipt.id}
          {receiptCanceled
            ?
            <Label style={{marginLeft: 20}} color='red' horizontal>
              {this.props.t(i18nArAttr('ticket.canceled'))}
            </Label>
            : ''
          }
        </Table.Cell>
        <Table.Cell>
          {this.props.receipt.tickets_not_canceled_count}
        </Table.Cell>
        <Table.Cell>
          {formatPrice(this.props.receipt.total_price)}
        </Table.Cell>
        <Table.Cell>
          {moment(this.props.receipt.updated_at).format()}
        </Table.Cell>
        <Table.Cell collapsing>
          <div style={{display: "inline"}}>
            <Popup content={`${this.props.t(i18nArModel('ticket.other'))} öffnen`} trigger={
              <Button icon={'file pdf'} onClick={this.showClicked} disabled={receiptCanceled}/>
            }/>
            <Popup content={`${this.props.t(i18nArModel('ticket.other'))} herunterladen`} trigger={
              <Button icon={'download'} onClick={this.downloadClicked} disabled={receiptCanceled}/>
            }/>
          </div>
          <Popup content='Alle Tickets Stornieren' disabled={receiptCanceled} trigger={(
            <div style={{display: 'inline'}}>
              <Button icon={'ban'} onClick={this.showConfirmCancel} disabled={receiptCanceled}/>
              <Confirm
                open={this.state.showConfirmCancel}
                onCancel={this.hideConfirmCancel}
                onConfirm={this.cancelTickets}
                content={this.props.t('admin.entity.confirm_cancel', {model_name: this.props.t(i18nArModel(`ticket.other`))})}
              />
            </div>
          )}/>
        </Table.Cell>
      </Table.Row>
    )
  }
}

export default compose(
  withTranslation('translation'),
  inject('store'),
  observer
)(ReceiptListItem);
