import * as React from 'react';
import {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {Button, Popup, Table} from "semantic-ui-react";
import {adminRoute} from "../../../routing/routing";
import {DetailsButton} from "../shared/buttons";
import {i18nArModel, i18nBool} from "@semabit/rails-i18n-tools";
import {Link} from "react-router-dom";
import moment from "moment";
import {observer} from "mobx-react";
import {downloadPdfFromUrl} from "../../../tools/files";
import {resourcePath} from "../../../middleware/apiPath";
import {receiptsResourcePath} from "../../../middleware/endpoints/receipts";
import {useStore} from "../../../hooks/useStore";


const ReservationListItem = ({reservation, show, searchTable = false}) => {
  const store = useStore();
  const {t} = useTranslation();
  const receipt = store.receiptStore.getById(reservation.receipt_id);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (reservation.receipt_id && show.id) {
      store.receiptStore.load(reservation.receipt_id, {params: {showId: show.id}});
    }
  }, [reservation, show]);

  const downloadReceipt = (receipt) => {
    if (!receipt) return;
    downloadPdfFromUrl(
      resourcePath(receiptsResourcePath(show.id, `/${receipt.id}.pdf`)),
      {}
    );
  }

  return (
    <Table.Row>
      <Table.Cell>{reservation.first_name}</Table.Cell>
      <Table.Cell>{reservation.last_name}</Table.Cell>
      <Table.Cell>{t(i18nBool(reservation.already_paid))}</Table.Cell>
      {
        searchTable
          ? <Table.Cell>
            <Link to={adminRoute(`/shows/${show.id}`)}>
              {show.name}
            </Link>
            {show.begins_at ? " (" + moment(show.begins_at).format() + ")" : '-'}
          </Table.Cell>
          : <></>
      }
      <Table.Cell width={2}>{reservation.number_of_persons}</Table.Cell>
      <Table.Cell width={6}>{reservation.notes}</Table.Cell>
      <Table.Cell textAlign={'right'} collapsing>
        {receipt ?
          <Popup
            content={`${t(i18nArModel('ticket.other'))} herunterladen`}
            trigger={
              <Button
                icon={'download'}
                onClick={() => downloadReceipt(receipt)}
                disabled={receipt.tickets_not_canceled_count === 0}
              />
            }
          />
          : <></>
        }
        <DetailsButton
          route={adminRoute(`/shows/${show.id}/reservations/${reservation.id}`)}
        />
      </Table.Cell>
    </Table.Row>
  )
}

export default observer(ReservationListItem);
