import * as React from 'react';
import {withTranslation} from "react-i18next";
import {inject, observer} from "mobx-react";
import compose from "compose-function";
import {EntityDetailTable, EntityDetailTableEntry} from "../shared/tables";
import {adminRoute} from "../../../routing/routing";
import {DeleteButton, EditButton} from "../shared/buttons";
import {withRouter} from "react-router";
import {confirmDelete} from "../shared/confirm";
import {EntityDetailLayout} from "../shared/entityDetailLayout";
import {loadEntity} from "../shared/loadEntity";
import UserDetailTableEntry from "./../users/detailTableEntry"
import {i18nArAttr, i18nArModel, i18nBool} from "@semabit/rails-i18n-tools";
import {Link} from "react-router-dom";
import moment from "moment";

class ReservationDetail extends React.Component {

  componentDidMount() {
    const user = this.props.store.userStore.getById(this.props.reservation.user_id);
    if (!user) {
      this.props.store.userStore.load(this.props.reservation.user_id).then(user => {
        this.props.reservation.user = user;
      });
    }
  }

  render() {
    const {t} = this.props;
    return (
      <EntityDetailLayout title={t(i18nArModel('reservation.one'))} backPath={`/shows/${this.props.show.id}`}>
        <EditButton route={adminRoute(`/shows/${this.props.show.id}/reservations/${this.props.reservation.id}/edit`)}/>
        <DeleteButton onClick={() => this.delete()}/>

        <EntityDetailTable>
          <EntityDetailTableEntry
            name={t(i18nArModel('show.one'))}><b>{this.props.show.name}</b></EntityDetailTableEntry>
          <EntityDetailTableEntry
            name={t(i18nArAttr('reservation.first_name'))}><b>{this.props.reservation.first_name}</b></EntityDetailTableEntry>
          <EntityDetailTableEntry
            name={t(i18nArAttr('reservation.last_name'))}>{this.props.reservation.last_name}</EntityDetailTableEntry>
          <EntityDetailTableEntry
            name={t(i18nArAttr('reservation.already_paid'))}>{t(i18nBool(this.props.reservation.already_paid))}</EntityDetailTableEntry>
          <EntityDetailTableEntry
            name={t(i18nArModel('show.one'))}>
            <Link to={adminRoute(`/shows/${this.props.show.id}`)}>
              {this.props.show.name}
            </Link>
            {this.props.show.begins_at ? " (" + moment(this.props.show.begins_at).format() + ")" : '-'}
          </EntityDetailTableEntry>
          <EntityDetailTableEntry
            name={t(i18nArAttr('reservation.number_of_persons'))}>{this.props.reservation.number_of_persons}</EntityDetailTableEntry>
          {this.props.reservation.already_paid ? (
            <EntityDetailTableEntry
              name={t(i18nArAttr('reservation.entered'))}>{t(i18nBool(this.props.reservation.entered))}</EntityDetailTableEntry>
          ) : (
            <EntityDetailTableEntry
              name={t(i18nArAttr('reservation.tickets_picked_up'))}>{t(i18nBool(this.props.reservation.tickets_picked_up))}</EntityDetailTableEntry>
          )}
          <EntityDetailTableEntry
            name={t(i18nArAttr('reservation.notes'))}>{this.props.reservation.notes}</EntityDetailTableEntry>
          <UserDetailTableEntry userId={this.props.reservation.user_id}/>
        </EntityDetailTable>
      </EntityDetailLayout>
    )
  }

  delete() {
    if (confirmDelete(this.props.t, 'reservation')) {
      this.props.store.reservationStore.delete(this.props.reservation.id).then(() => {
        this.props.history.push(adminRoute(`/shows/${this.props.show.id}`));
      });
    }
  }
}

export default compose(
  loadEntity('showStore', 'show', adminRoute('/shows'), {paramName: 'showId'}),
  loadEntity('reservationStore', 'reservation', (props) => adminRoute(`/shows/${props.show.id}`)),
  withRouter,
  withTranslation('translation'),
  inject('store'),
  observer
)(ReservationDetail);
