import {useEffect} from "react";
import {useFormApi, useFormState} from "informed";
import {useTranslation} from "react-i18next";
import {Form} from "semantic-ui-react";
import {i18nArAttr} from "@semabit/rails-i18n-tools";
import Text from "../../forms/text";

const RemarksFormInput = ({remarksFormRefs, field = '', placeholder = ''}) => {
  const formApi = useFormApi();
  const {errors} = useFormState();
  const {t} = useTranslation();

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (remarksFormRefs?.current && remarksFormRefs.current instanceof Map) {
      remarksFormRefs.current.set(field, formApi);
    }
  }, []);

  return (
    <Form.Field required style={{width: '100%'}}>
      <label
        className={'visually-hidden'}
        htmlFor={field}
      >
        {t(i18nArAttr(`price.price_types.${field}`))}
      </label>
      <Text
        placeholder={placeholder}
        field={field}
        errors={[errors[field]].filter((e) => !!e)}
        validate={(value) => {
          const label = t(i18nArAttr(`price.ticket_remark_label.${field}`));
          if (!value) return t('activerecord.errors.models.cart_ticket.remarks.required', {label})
        }}
      />
    </Form.Field>
  );
}

export default RemarksFormInput;
