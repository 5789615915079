import * as React from 'react';
import {withTranslation} from 'react-i18next';
import {inject, observer} from "mobx-react";
import compose from "compose-function";
import {i18nArAttr, i18nArModel} from "@semabit/rails-i18n-tools";
import {Message} from "semantic-ui-react";
import loadEntities from "../../shared/loadEntities";
import {LabeledIconLinkButton} from "../../shared/buttons";
import {adminRoute} from "../../../../routing/routing";
import {EntityTable} from "../../shared/tables";
import PresaleOrderListItem from "../../presale_orders/listItem";

class PresaleOrderList extends React.Component {

  render() {
    const {t, festival, shows} = this.props;
    const headers = [
      '#',
      t(i18nArAttr('presale_order.bought_at')),
      t(i18nArAttr('presale_order.firstname')),
      t(i18nArAttr('presale_order.lastname')),
      t(i18nArAttr('presale_order.email')),
      t(i18nArAttr('presale_order.tickets')),
      ''
    ];

    return (
      <div>
        <LabeledIconLinkButton
          text={t(i18nArModel('festival_pass.one'))}
          route={adminRoute(`/festivals/${festival.id}/festival_passes/create/`)}
        />
        {this.props.presaleOrders.length > 0
          ? (
            <EntityTable headers={headers}>
              {this.props.presaleOrders.map((presaleOrder) =>
                <PresaleOrderListItem key={presaleOrder.id} shows={shows} presaleOrder={presaleOrder}/>
              )}
            </EntityTable>
          )
          : <Message>Für dieses Festival wurden noch keine Tickets über den Vorverkauf verkauft</Message>
        }
      </div>
    )
  }
}

export default compose(
  loadEntities('presaleOrderStore', 'presaleOrders', {
    storeParams: (props) => ({params: {festival_id: props.festival.id}})
  }, adminRoute('/festivals')),
  withTranslation('translation'),
  inject('store'),
  observer
)(PresaleOrderList);
