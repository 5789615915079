import * as React from 'react';
import {withTranslation} from "react-i18next";
import {Table} from "semantic-ui-react";
import compose from "compose-function";
import {adminRoute} from "../../../routing/routing";
import {inject, observer} from "mobx-react";
import {DetailsButton} from "../shared/buttons";
import moment from "moment";
import {i18nBool} from "@semabit/rails-i18n-tools";

class FestivalListItem extends React.Component {

  render() {
    return (
      <Table.Row className={moment().isAfter(this.props.festival.ends_at) ? 'inactive' : ''}>
        <Table.Cell>{this.props.festival.name}</Table.Cell>
        <Table.Cell>{this.props.festival.location}</Table.Cell>
        <Table.Cell>{this.props.festival.starts_at_formatted}</Table.Cell>
        <Table.Cell>{this.props.festival.ends_at_formatted}</Table.Cell>
        <Table.Cell>{this.props.t(i18nBool(this.props.festival.presale_active))}</Table.Cell>
        <Table.Cell collapsing>
          <DetailsButton route={adminRoute(`/festivals/${this.props.festival.id}`)}/>
        </Table.Cell>
      </Table.Row>
    )
  }
}

export default compose(
  withTranslation('translation'),
  inject('store'),
  observer
)(FestivalListItem);
