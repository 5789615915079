import React, {useEffect, useRef, useState} from "react";
import {BarcodeFormat, BrowserMultiFormatReader, DecodeHintType} from '@zxing/library';
import {Button, Grid, Icon} from "semantic-ui-react";
import {useTranslation} from "react-i18next";

const QRCodeReader = ({onScan = (text) => undefined, loading = false, overlay, ...props}) => {
  const {t} = useTranslation();

  const [selectedDeviceId, setSelectedDeviceId] = useState(undefined);
  const [videoDevices, setVideoDevices] = useState([]);
  const [isReaderActive, setIsReaderActive] = useState(true);
  const [reader, setReader] = useState(null);

  const deviceListIntervalRef = useRef(null);

  useEffect(() => {
    const hints = new Map();
    const formats = [BarcodeFormat.QR_CODE];
    hints.set(DecodeHintType.POSSIBLE_FORMATS, formats);
    hints.set(DecodeHintType.TRY_HARDER, true);
    const reader = new BrowserMultiFormatReader(hints);
    setReader(reader);

    return () => {
      reader.reset();
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (reader) {
        if (!videoDevices.length) {
          deviceListIntervalRef.current = setInterval(async () => {
            const videoInputDevices = (await reader.listVideoInputDevices()).filter((device) => !!device.deviceId);
            if (videoInputDevices.length) {
              setVideoDevices(() => videoInputDevices);
            }
          }, 1000);
        } else {
          if (deviceListIntervalRef.current) {
            clearInterval(deviceListIntervalRef.current);
          }
        }
      }
    })();
  }, [videoDevices.length, reader])

  useEffect(() => {
    if (reader && onScan) {
      if (isReaderActive) {
        reader.decodeOnceFromVideoDevice(selectedDeviceId, 'video').then((result) => {
          setIsReaderActive(false);
          onScan(result.getText());
        });
      } else if (selectedDeviceId) {
        reader.decodeOnceFromVideoDevice(selectedDeviceId, 'video');
      }
    }
  }, [isReaderActive, selectedDeviceId, reader, onScan]);

  const activateReader = () => {
    setIsReaderActive(true);
  }

  const handleVideoDeviceRotation = (e) => {
    if (videoDevices.length) {
      const currentSelectedIdx = videoDevices.findIndex((device) => device.deviceId === selectedDeviceId);
      if (currentSelectedIdx < 0) {
        const nextDevice = videoDevices[0];
        setSelectedDeviceId(nextDevice.deviceId);
      } else {
        const nextDeviceIdx = (currentSelectedIdx + 1) % videoDevices.length;
        const nextDevice = videoDevices[nextDeviceIdx];
        setSelectedDeviceId(nextDevice.deviceId);
      }
    }
  }

  return (
    <Grid centered {...props}>
      <Grid.Row>
        <Grid.Column mobile={16} tablet={8} computer={4}>
          <div className={"ratio ratio-1x1"}>
            <div className="ratio-element">
              <video style={{
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                width: '100%',
                height: '100%',
                objectFit: 'cover'
              }} id="video"/>
              {overlay && !isReaderActive && (
                <div style={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                  witdt: '100%',
                  heigth: '100%',
                }}>{overlay}</div>
              )}
            </div>
          </div>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column mobile={16} tablet={8} computer={4}>
          <Grid>
            <Grid.Row style={{gap: '1rem'}} columns={1}>
              <Grid.Column>
                <Button
                  className={'w-100'}
                  icon
                  primary={true}
                  labelPosition={'left'}
                  size={'large'}
                  onClick={activateReader}
                  disabled={isReaderActive}
                  loading={loading}
                >
                  <Icon name='qrcode'/>
                  {t('entry.next_qr_scan')}
                </Button>
              </Grid.Column>
              {(videoDevices.length > 1) && (
                <Grid.Column>
                  <Button
                    className={'w-100'}
                    labelPosition={'left'}
                    onClick={handleVideoDeviceRotation}
                    icon
                    size={'large'}>
                    <Icon name='camera'/>
                    {t('entry.change_device')}
                  </Button>
                </Grid.Column>
              )}
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

export default QRCodeReader;
