import {Table} from "semantic-ui-react";
import * as React from "react";

export const EntityTable = (props) => (
  <Table celled>
    {props.headers?.length ? (
      <Table.Header>
        <Table.Row>
          {props.headers.map((header, i) => <Table.HeaderCell key={i}>{header}</Table.HeaderCell>)}
        </Table.Row>
      </Table.Header>
    ) : null}
    <Table.Body>
      {props.children}
    </Table.Body>
  </Table>
);

export const EntityDetailTable = (props) => {
  return <Table definition>
    <Table.Body>
      {props.children}
    </Table.Body>
  </Table>
};

export const EntityDetailTableEntry = (props) => {
  return (
    <Table.Row>
      <Table.Cell>{props.name}</Table.Cell>
      <Table.Cell>{props.children}</Table.Cell>
    </Table.Row>
  )
};
