import * as React from 'react';
import {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react";
import {EntityDetailTable, EntityDetailTableEntry, EntityTable} from "../shared/tables";
import {i18nArAttr, i18nArModel} from "@semabit/rails-i18n-tools";
import {adminRoute} from "../../../routing/routing";
import {EditButton} from "../shared/buttons";
import {EntityDetailLayout} from "../shared/entityDetailLayout";
import {Button, Icon, Label, Loader, Tab} from "semantic-ui-react";
import PresaleTicketItem from "./presaleTicketItem";
import {downloadPdfFromUrl, showPdfFromUrl} from "../../../tools/files";
import {resourcePath} from "../../../middleware/apiPath";
import {presaleOrderResourcePath} from "../../../middleware/endpoints/presaleOrders";
import {useStore} from "../../../hooks/useStore";
import {useParams} from "react-router";
import {uniq} from "lodash/array";
import PresaleOrderMailState from "../../shared/PresaleOrderMailState";

const PresaleOrderDetail = () => {
  const {t} = useTranslation();
  const store = useStore();
  const shows = store.showStore.shows.toJS();
  const {id: presaleOrderId} = useParams();
  const presaleOrder = store.presaleOrderStore.getById(presaleOrderId);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (presaleOrderId) {
      store.presaleOrderStore.load(presaleOrderId);
    }
  }, [presaleOrderId]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (presaleOrder?.tickets?.length) {
      const showIds = uniq(presaleOrder.tickets.map((ticket) => ticket.show_id));
      store.showStore.loadAll({params: {ids: showIds}});
    }
  }, [presaleOrder?.tickets?.length])

  const downloadPdf = (presaleOrder) => {
    downloadPdfFromUrl(resourcePath(presaleOrderResourcePath(`/${presaleOrder.id}.pdf`)));
  }

  const showPdf = (presaleOrder) => {
    showPdfFromUrl(resourcePath(presaleOrderResourcePath(`/${presaleOrder.id}.pdf`)));
  }

  if (store.presaleOrderStore.isLoading) {
    return (
      <EntityDetailLayout title={t(i18nArModel('presale_order.one'))} backPath={`/presale_orders`}>
        <Loader/>
      </EntityDetailLayout>
    )
  }

  if (!presaleOrder) {
    return null;
  }

  return (
    <EntityDetailLayout title={t(i18nArModel('presale_order.one'))} backPath={`/presale_orders`}>
      <EditButton route={adminRoute(`/presale_orders/${presaleOrder.id}/edit`)}/>
      <Button icon onClick={() => showPdf(presaleOrder)} labelPosition='left'>
        <Icon name='eye'/>
        Vorschau
      </Button>
      <Button icon onClick={() => downloadPdf(presaleOrder)} labelPosition='left'>
        <Icon name='download'/>
        PDF
      </Button>
      <EntityDetailTable>
        <EntityDetailTableEntry name={t(i18nArAttr('presale_order.firstname'))}>
          <b>{presaleOrder.address.firstname}</b>
        </EntityDetailTableEntry>
        <EntityDetailTableEntry name={t(i18nArAttr('presale_order.lastname'))}>
          <b>{presaleOrder.address.lastname}</b>
        </EntityDetailTableEntry>
        <EntityDetailTableEntry name={t(i18nArAttr('presale_order.email'))}>
          {presaleOrder.address.email}
        </EntityDetailTableEntry>
        <EntityDetailTableEntry name={t(i18nArAttr('presale_order.email_state'))}>
          <PresaleOrderMailState
            state={presaleOrder.current_mail_delivery_state}
            error={presaleOrder.current_mail_delivery_error}
          />
        </EntityDetailTableEntry>
        <EntityDetailTableEntry name={t(i18nArAttr('presale_order.city'))}>
          {presaleOrder.address.zipcode} {presaleOrder.address.city}
        </EntityDetailTableEntry>
        <EntityDetailTableEntry name={t(i18nArAttr('presale_order.street'))}>
          {presaleOrder.address.street} {presaleOrder.address.house}
        </EntityDetailTableEntry>
        <EntityDetailTableEntry name={t(i18nArAttr('ticket.state'))}>
          <Label color={'green'} horizontal>{t(i18nArAttr('reservation.already_paid'))}</Label>
        </EntityDetailTableEntry>
      </EntityDetailTable>

      <Tab panes={[
        {
          menuItem: t(i18nArModel('ticket.other')),
          render: () =>
            <Tab.Pane>
              <EntityTable headers={[
                t(i18nArAttr('ticket.id')),
                t(i18nArModel('show.one')),
                t(i18nArAttr('ticket.state')),
                t(i18nArAttr('common.created_at')),
                t(i18nArAttr('price.price_type')),
                t(i18nArAttr('price.price')),
                t(i18nArAttr('ticket.remarks')),
                ''
              ]}>
                {presaleOrder.tickets.map((ticket) =>
                  <PresaleTicketItem
                    key={ticket.id}
                    ticket={ticket}
                    price={ticket.price_id}
                    show={shows.find(s => s.id === ticket.show_id)}
                  />
                )}
              </EntityTable>
            </Tab.Pane>
        }
      ]}/>
    </EntityDetailLayout>
  )
}

export default observer(PresaleOrderDetail);
