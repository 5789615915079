import * as React from "react";
import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react";
import CartItem from "./cartItem";
import {Link} from "react-router-dom";
import {Button, Grid, Message, Table} from "semantic-ui-react";
import CheckoutSteps from "./checkoutSteps";
import {i18nArModel} from "@semabit/rails-i18n-tools";
import {LabeledIconLinkButton} from "../admin/shared/buttons";
import {useStore} from "../../hooks/useStore";
import useCart from "../../hooks/useCart";

const Cart = () => {
  const store = useStore();
  const {t} = useTranslation();
  const {cart} = useCart();
  const shows = store.showStore.shows;
  const ticketAmountSum = cart ? cart.cart_tickets.reduce((a, b) => a + (b["quantity"] || 0), 0) : 0;

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    store.showStore.loadAll();
  }, []);

  return (
    <>
      <CheckoutSteps active={'cart'}/>
      <Grid>
        {cart && cart.cart_tickets.length > 0 ?
          <>
            <Grid.Row>
              <Grid.Column>
                <Table style={{fontSize: '1.2em'}}>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell width={4}>{t(i18nArModel(`show.one`))}</Table.HeaderCell>
                      <Table.HeaderCell width={5}>{t('components.presale.cart.additional_info')}</Table.HeaderCell>
                      <Table.HeaderCell width={3}>{t(`components.presale.cart.ticket_amount`)}</Table.HeaderCell>
                      <Table.HeaderCell width={2} textAlign={'right'}>{t(i18nArModel(`price.one`))}</Table.HeaderCell>
                      <Table.HeaderCell width={2}/>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {cart ? cart.cart_tickets.map((cartTicket) =>
                        <CartItem key={cartTicket.id} cart={cart} shows={shows} cartItem={cartTicket} editable={true}/>
                      )
                      : null
                    }
                  </Table.Body>
                  {cart.cart_tickets.length >= 2 ?
                    <Table.Footer>
                      <Table.Row>
                        <Table.HeaderCell>Total</Table.HeaderCell>
                        <Table.HeaderCell/>
                        <Table.HeaderCell textAlign={'left'}>
                          {ticketAmountSum} {ticketAmountSum === 1 ? t(i18nArModel(`ticket.one`)) : t(i18nArModel(`ticket.other`))}
                        </Table.HeaderCell>
                        <Table.HeaderCell/>
                        <Table.HeaderCell textAlign={'right'}>{cart.formatPrice()}</Table.HeaderCell>
                      </Table.Row>
                    </Table.Footer>
                    : null
                  }
                </Table>
              </Grid.Column>
            </Grid.Row>
          </>
          : <Grid.Row>
            <Grid.Column>
              <Message>
                {t(`components.presale.cart.no_tickets_in_cart`)}
              </Message>
            </Grid.Column>
          </Grid.Row>
        }
      </Grid>

      <Grid style={{marginTop: 40}}>
        <Grid.Row style={{paddingTop: 32, paddingBottom: 32}}>
          <Grid.Column floated={'left'} width={8}>
            <LabeledIconLinkButton
              primary={false}
              text={t('components.presale.show_details.buy_more')}
              route={'/'}
              icon={'ticket'}
            />
          </Grid.Column>
          <Grid.Column floated={'right'} width={8} style={{textAlign: 'right'}}>
            <Link to={'/checkout/address'}>
              <Button
                disabled={cart && cart.cart_tickets.length <= 0}
                primary
              >
                Weiter
              </Button>
            </Link>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  )
}

export default observer(Cart);
