import * as React from "react";
import {Table} from "semantic-ui-react";
import CartItem from "./cartItem";
import {i18nArModel} from "@semabit/rails-i18n-tools";
import {withTranslation} from "react-i18next";
import compose from "compose-function";

class PaymentOverview extends React.Component {

  render() {
    const {cart, shows, t} = this.props

    return (
      <Table style={{fontSize: '1.2em'}}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={4}>{t(i18nArModel(`show.one`))}</Table.HeaderCell>
            <Table.HeaderCell width={5}>{t('components.presale.cart.additional_info')}</Table.HeaderCell>
            <Table.HeaderCell width={3}>{t(`components.presale.cart.ticket_amount`)}</Table.HeaderCell>
            <Table.HeaderCell width={4} textAlign={'right'}>{t(i18nArModel(`price.one`))}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {cart ? cart.cart_tickets.map((cartTicket) =>
              <CartItem key={cartTicket.id} cart={cart} cartItem={cartTicket} shows={shows} editable={false}/>
            )
            : null
          }
        </Table.Body>
      </Table>
    )
  }
}

export default compose(
  withTranslation('translation'),
)(PaymentOverview);
