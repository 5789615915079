import CustomForm from "../../forms/form";
import RemarksFormInput from "./remarksFormInput";

const RemarksForm = ({remarksFormRefs, placeholder = '', field = ''}) => (
  <CustomForm style={{fontSize: '1em'}}>
    <RemarksFormInput
      field={field}
      remarksFormRefs={remarksFormRefs}
      placeholder={placeholder}
    />
  </CustomForm>
);

export default RemarksForm;
