import {asField} from 'informed';
import withStyle from "./withStyle";
import compose from "compose-function";
import withDefaultId from "./withDefaultId";
import React from "react";
import {Form} from "semantic-ui-react";

const InformedSelect = asField(({fieldState, fieldApi, ...props}) => {
  const {value} = fieldState;
  const {setValue} = fieldApi;
  const {onChange, initialValue, forwardedRef, ...rest} = props;

  return (
    <Form.Select
      {...rest}
      value={value || initialValue}
      onChange={(e, f) => {
        setValue(f.value);
        if (onChange) {
          onChange(e);
        }
      }}
    />
  );
});

export default compose(
  withStyle,
  withDefaultId
)(InformedSelect);
