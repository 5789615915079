import {createEntity, executeDelete, executeGet, handleError, updateEntity} from "./base";
import axios from "../axios";
import {resourcePath} from "../apiPath";

export const resourcesName = 'entries';
export const resourceName = 'entry';

const pathPrefix = (showId) => `shows/${showId}`;

const scan = (value, path, paramName, additionalParams = {}) => {
  return axios.post(resourcePath(path), {value, ...additionalParams}).then(({data}) => data).catch(handleError)
};

export const EntriesApi = {
  all: ({showId}) => executeGet(pathPrefix(showId) + `/${resourcesName}`),
  byId: (id, {showId}) => executeGet(pathPrefix(showId) + `/${resourcesName}/${id}`),
  create: (entity) => createEntity(entity, pathPrefix(entity.show_id) + `/${resourcesName}`, resourceName),
  update: (entity) => updateEntity(entity, pathPrefix(entity.show_id) + `/${resourcesName}/${entity.id}`, resourceName),
  destroy: (id, {showId}) => executeDelete(pathPrefix(showId) + `/${resourcesName}/${id}`),
  scan: (value, showId) => scan(value, pathPrefix(showId) + `/${resourcesName}/scan`, resourceName),
};
