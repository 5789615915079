import * as React from 'react';
import {useEffect, useState} from 'react';
import {observer} from "mobx-react";
import {Grid, Header, Loader, Message} from "semantic-ui-react";
import ShowListItem from "./showListItem";
import {useStore} from "../../hooks/useStore";
import {groupBy} from "lodash/collection";
import moment from "moment";
import {useTranslation} from "react-i18next";
import useCart from "../../hooks/useCart";

const ShowList = () => {
  const store = useStore();
  const {t} = useTranslation();
  const festival = store.festivalStore.festivals.length ? store.festivalStore.festivals[0] : null;
  const shows = store.showStore.shows.toJS();
  const sortedShows = shows
    .filter((show) => show.presale_active && show.festival_id === festival?.id)
    .sort((a, b) => new Date(a.begins_at) - new Date(b.begins_at))
  const groupedShows = groupBy(sortedShows, (show) => moment(show.begins_at).format('dd D.M'));
  const [isLoadingShows, setIsLoadingShows] = useState(true);
  const [isLoadingFestivals, setIsLoadingFestivals] = useState(true);
  const isLoading = isLoadingShows || isLoadingFestivals;
  const {reload: reloadCart} = useCart();

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    reloadCart();
  }, [])

  useEffect(() => {
    store.festivalStore.loadAll({params: {current: true}});
    (async () => {
      store.festivalStore.loadAll({params: {current: true}});
      setIsLoadingFestivals(false);
    })();
  }, [])

  useEffect(() => {
    if (festival) {
      (async () => {
        await store.showStore.loadAll({params: {current_festival: true}});
        setIsLoadingShows(false);
      })();
    }
  }, [festival])

  const description = festival ? festival.presale_description.split(/\r?\n/) : [];

  const Shows = () => {
    if (isLoading) {
      return (
        <Grid.Row className={'show-list'}>
          <Grid.Column>
            <Loader active inline='centered'/>
          </Grid.Column>
        </Grid.Row>
      );
    }

    if (!sortedShows.length) {
      return (
        <Grid.Row className={'show-list'}>
          <Grid.Column>
            <Message>
              {t('components.presale.show_list.show_shows_in_presale')}
            </Message>
          </Grid.Column>
        </Grid.Row>
      )
    }

    return (
      <>
        {Object.entries(groupedShows).map(([key, group]) => {
          return (
            <React.Fragment key={key}>
              <Grid.Row columns={1}>
                <Grid.Column>
                  <Header as='h2'>{key.toUpperCase()}</Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={3} className={'show-list'}>
                {group.map((show) => <ShowListItem key={show.id} show={show}/>)}
              </Grid.Row>
            </React.Fragment>
          )
        })}
      </>
    )
  }

  return (
    <Grid stackable>
      <Grid.Row>
        <Grid.Column>
          <Header as='h1' style={{marginBottom: 40}}>{t('components.presale.show_list.presale')}</Header>
          {store.festivalStore.isLoading && <Loader active/>}
          {!store.festivalStore.isLoading && (
            <p style={{marginBottom: 40}}>
              {
                description.map((line, index) => (
                  <React.Fragment key={index}>{line}{index < (description.length - 1) ? <br/> : ''}</React.Fragment>
                ))
              }
            </p>
          )}
        </Grid.Column>
      </Grid.Row>
      <Shows/>
    </Grid>
  )
}

export default observer(ShowList);
