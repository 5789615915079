import * as React from 'react';
import {withTranslation} from 'react-i18next';
import {inject, observer} from "mobx-react";
import {adminRoute} from "../../../routing/routing";
import compose from "compose-function";
import SalesPointListItem from "./listItem";
import {LabeledIconLinkButton} from "../shared/buttons";
import {i18nArAttr, i18nArModel} from "@semabit/rails-i18n-tools";
import {EntityTable} from "../shared/tables";
import {loadEntities} from "../shared/loadEntities";

class SalesPointList extends React.Component {

  render() {
    const {t} = this.props;
    const headers = [
      t(i18nArAttr('sales_point.name')),
      t(i18nArAttr('sales_point.location')),
      t(i18nArAttr('sales_point.settings.print_mode.label')),
      ''
    ];

    return (
      <div>
        <LabeledIconLinkButton text={t(i18nArModel('sales_point.one'))}
                               route={adminRoute(`/sales_points/create`)}/>
        <EntityTable headers={headers}>
          {this.props.salesPoints.map((salesPoint) => <SalesPointListItem key={salesPoint.id} salesPoint={salesPoint}/>)}
        </EntityTable>
      </div>
    )
  }
}

export default compose(
  loadEntities('salesPointStore', 'salesPoints'),
  withTranslation('translation'),
  inject('store'),
  observer
)(SalesPointList);
