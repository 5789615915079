import {Form} from "semantic-ui-react";
import {i18nArAttr} from "@semabit/rails-i18n-tools";
import Checkbox from "../../../forms/checkbox";
import * as React from "react";
import {useTranslation} from "react-i18next";

const TicketRemarksRequired = () => {
  const {t} = useTranslation();

  return (
    <Form.Field>
      <label htmlFor={'internal'}>{t(i18nArAttr('price.require_ticket_remarks'))}*</label>
      <Checkbox
        toggle
        field={'require_ticket_remarks'}
      />
    </Form.Field>
  );
}

export default TicketRemarksRequired;
