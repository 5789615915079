import * as React from 'react';
import {withTranslation} from 'react-i18next';
import {inject, observer} from "mobx-react";
import {adminRoute} from "../../../routing/routing";
import compose from "compose-function";
import {LabeledIconLinkButton} from "../shared/buttons";
import {i18nArAttr, i18nArModel} from "@semabit/rails-i18n-tools";
import {EntityTable} from "../shared/tables";
import {loadEntities} from "../shared/loadEntities";
import FestivalListItem from "./listItem";

class FestivalList extends React.Component {

  render() {
    const {t} = this.props;
    const headers = [
      t(i18nArAttr('festival.name')),
      t(i18nArAttr('festival.location')),
      t(i18nArAttr('festival.starts_at')),
      t(i18nArAttr('festival.ends_at')),
      t(i18nArAttr('festival.presale_active')),
      ''
    ];

    return (
      <div>
        <LabeledIconLinkButton
          text={t(i18nArModel('festival.one'))}
          route={adminRoute(`/festivals/create`)}
        />
        <EntityTable headers={headers}>
          {this.props.festivals.map((festival) =>
            <FestivalListItem key={festival.id} festival={festival}/>)}
        </EntityTable>
      </div>
    )
  }
}

export default compose(
  loadEntities('festivalStore', 'festivals'),
  withTranslation('translation'),
  inject('store'),
  observer
)(FestivalList);
