import {BaseModel} from "./base";
import {formatPrice} from "../formatters/priceFormatter";

export class Ticket extends BaseModel {
  show_id = 0;
  price_id = 0;
  price = 0;
  receipt_id = 0;
  reservation_id = 0;
  state = '';
  presale_order_id = null;

  formatPrice() {
    return formatPrice(this.price);
  }

  get isCanceled() {
    return this.state === TicketStates.canceled;
  }

  get isEntered() {
    return this.state === TicketStates.entered;
  }

  get isOpen() {
    return this.state === TicketStates.created;
  }

  get isReserved() {
    return this.state === TicketStates.reserved;
  }

  static fromPlainObject(plainObject) {
    const ticket = new Ticket();
    return Object.assign(ticket, plainObject);
  }
}

export const TicketStates = {
  created: "created",
  canceled: "canceled",
  entered: "entered",
  reserved: "reserved",
};

