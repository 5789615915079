import * as React from "react";
import compose from "compose-function";
import {loadEntity} from "../admin/shared/loadEntity";
import {staffRoute} from "../../routing/routing";
import TicketStatistics from "../point-of-sale/ticketStatistics";
import {inject, Observer, observer} from "mobx-react";
import EntryCounter from "./entryCounter";
import {Grid, Header, Message, Tab} from "semantic-ui-react";
import {loadEntities} from "../admin/shared/loadEntities";
import {withTranslation} from "react-i18next";
import {EntryTypes} from "../../models/entry";
import ReservationsWidget from "./reservationsWidget";
import moment from "moment";
import QRCodeReader from "./QRCodeReader";
import ScannedTicketsWidget from "./scannedTicketsWidget";
import EntryStatistics from "../point-of-sale/entryStatistics";
import MessageOverlay from "../shared/MessageOverlay";
import {i18nArAttr, i18nArModel} from "@semabit/rails-i18n-tools";
import {t} from "i18next";

class EntryView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showQrReader: false,
      scanResult: '',
      errorMessage: '',
      isLoading: false,
      scannedValue: '',
      additionalInformation: undefined
    };
  }

  addEntry = (entryType) => {
    this.createEntry(entryType, 1);
  };
  addExit = (entryType) => {
    this.createEntry(entryType, -1);
  };

  createEntry = (entryType, quantity, ticketNumber, festivalPassNumber) => {
    const entry = {
      entry_type: entryType,
      show_id: this.props.show.id,
      user_id: this.props.store.authStore.user.id,
      quantity: quantity,
      ticket_number: ticketNumber,
      festival_pass_number: festivalPassNumber,
    };
    return this.props.store.entryStore.create(entry, {skipNotification: true}).then(() => {
      this.props.store.showStore.load(this.props.show.id);
    });
  };

  handleQrShow = () => {
    this.setState({
      showQrReader: !this.state.showQrReader
    });
  }

  startScanning = () => {
    this.setState({scanResult: '', errorMessage: ''});
  }

  handleFestivalPassScan = async (festivalPass, value) => {
    const additionalInfos = await (async () => {
      if (festivalPass) {
        const festivalPassCategory = await this.props.store.festivalPassCategoryStore.load(festivalPass.festival_pass_category_id);

        if (festivalPassCategory) {
          return (
            <>
              <Message.Header>{t(i18nArModel('festival_pass_category.one'))}</Message.Header>
              <p>{festivalPassCategory.name}</p>
            </>
          );
        }

        return null;
      }

      return null;
    })();


    this.setState({
      scanResult: 'success',
      scannedValue: value,
      additionalInformation: additionalInfos,
    });
  };

  handleTicketScan = (ticket, value) => {
    const additionalInfos = (() => {
      if (ticket && ticket.remarks) {
        const price = this.props.prices.find(p => p.id.toString() === ticket.price_id.toString());
        if (price) {
          return (
            <>
              <Message.Header>{t(i18nArAttr(`price.ticket_remark_label.${price?.price_type}`))}</Message.Header>
              <p>{ticket.remarks}</p>
            </>
          );
        }

        return null;
      }
    })();

    this.setState({
      scanResult: 'success',
      scannedValue: value,
      additionalInformation: additionalInfos,
    });
  };

  handleQrScan = (value) => {
    this.setState({isLoading: true});
    this.props.store.entryStore.scan(value, this.props.show.id, {skipNotification: true})
      .then((r) => {
        if (r.festival_pass_id) {
          const festivalPass = this.props.store.festivalPassStore.getById(r.festival_pass_id);
          this.handleFestivalPassScan(festivalPass, value);
        } else {
          const ticket = this.props.store.ticketStore.getById(r.ticket_id);
          this.handleTicketScan(ticket, value);
        }
      })
      .catch((error) => {
        if (error.festival_pass || error.ticket) {
          this.setState({
            scanResult: 'error',
            scannedValue: value,
            errorMessage: error.festival_pass ? error.festival_pass : error.ticket
          });
        } else {
          this.setState({
            scanResult: 'error',
            scannedValue: value,
            errorMessage: [t('entry.scan.error.other')]
          });
        }
      })
      .finally(() => {
        this.setState({isLoading: false});
      });
  }

  render() {
    const {t, show, entries, store} = this.props;
    const reservationEntries = entries.filter(e => e.entry_type === EntryTypes.reservation);
    const isCreatingEntry = (entryType) => {
      return store.entryStore.isActionInProgress('create') && store.entryStore.actionState('create')?.entry_type === entryType;
    };

    let scannerWrapperClasses = '';
    if (this.state.scanResult === '') scannerWrapperClasses = 'empty-scan';
    if (this.state.scanResult === 'success') scannerWrapperClasses = 'successful-scan';
    if (this.state.scanResult === 'error') scannerWrapperClasses = 'failed-scan';

    const message = (() => {
      if (this.state.scanResult === 'success') {
        return (
          <MessageOverlay
            className={"ticket-scanner-message-overlay"}
            backgroundColor={'#2C662D'}
            border={'5px solid #008000'}
          >
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <Message positive size={'large'}>
                <Message.Header style={{fontSize: '1.2em'}}>
                  {this.state.scannedValue}: {t('entry.scan.result.valid')}
                </Message.Header>
              </Message>
              {this.state.additionalInformation && (
                <Message color={'black'} size={'large'}>
                  {this.state.additionalInformation}
                </Message>
              )}
            </div>
          </MessageOverlay>
        )
      }

      if (this.state.scanResult === 'error') {
        return (
          <MessageOverlay
            className={"ticket-scanner-message-overlay"}
            backgroundColor={'#9F3A38'}
            border={'5px solid #ff0000'}
          >
            <Message negative size={'large'}>
              <Message.Header style={{fontSize: '1.2em'}}>
                {this.state.scannedValue}: {t('entry.scan.result.invalid')}
              </Message.Header>
              <p>{this.state.errorMessage[0]}</p>
            </Message>
          </MessageOverlay>
        )
      }

      return null;
    })();

    const panes = [
      {
        menuItem: 'Manueller Einlass', render: () =>
          <Tab.Pane>
            <Grid stackable columns={2}>
              <Grid.Column width={16}>
                <ReservationsWidget show={show} entries={reservationEntries}/>
              </Grid.Column>
              <Grid.Column>
                <EntryCounter
                  title={t('entry.festival_pass_no_ticket')}
                  entryType={EntryTypes.festivalPass}
                  onIncrement={this.addEntry}
                  onDecrement={this.addExit}
                  count={show.festival_pass_no_ticket_entries_count}
                  canIncrement={show.available_tickets_count > 0}
                  loading={isCreatingEntry(EntryTypes.festivalPass)}
                />
              </Grid.Column>
              <Grid.Column>
                <EntryCounter
                  title={t('entry.evening_sale')}
                  entryType={EntryTypes.eveningSale}
                  count={show.entries_with_ticket_count}
                  loading={isCreatingEntry(EntryTypes.eveningSale)}
                  readonly
                >
                  {show.entries_with_ticket_count} / <Observer>{() => show.tickets_count}</Observer>
                </EntryCounter>
              </Grid.Column>
            </Grid>
          </Tab.Pane>
      },
      {
        menuItem: 'QR-Code Einlass', render: () =>
          <Tab.Pane>
            <Grid>
              <Grid.Column
                width={16}
                className={scannerWrapperClasses}
              >
                <QRCodeReader
                  onScan={this.handleQrScan}
                  startScanning={this.startScanning}
                  loading={this.state.isLoading}
                  overlay={message}
                />
              </Grid.Column>
              <Grid.Column width={16}>
                <ScannedTicketsWidget/>
              </Grid.Column>
            </Grid>
          </Tab.Pane>
      }
    ];

    return (
      <div className={'entry-view'}>
        <div className={'header-centered'} style={{textAlign: 'center'}}>
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Header as='h2' style={{marginTop: 0}}>{show.name}</Header>
                <p className={'show-begin'}>{moment(show.begins_at).format()}</p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Grid style={{marginBottom: 40}} columns={2}>
            <Grid.Row>
              <Grid.Column>
                <TicketStatistics show={show} autoUpdate={true} className={'block header'}/>
              </Grid.Column>
              <Grid.Column>
                <EntryStatistics show={show} entries={entries.toJS()} autoUpdate={true} className={'block header'}/>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
        <Tab panes={panes}/>
      </div>
    )
  }
}

export default compose(
  withTranslation('translation'),
  loadEntity('showStore', 'show', staffRoute('/entry'), {lazyLoad: true}),
  loadEntities('festivalPassStore', 'festivalPasses', {
    storeParams: (props) => ({params: {festivalId: props.show.festival_id}})
  }),
  loadEntities('entryStore', 'entries', {
    storeParams: (props) => ({params: {showId: props.show.id}}),
    autoUpdate: true
  }),
  loadEntities('ticketStore', 'tickets', {
    storeParams: (props) => ({params: {showId: props.show.id}}),
    autoUpdate: true
  }),
  loadEntities('priceStore', 'prices', {
    lazyLoad: true,
    storeParams: (props) => ({params: {showId: props.show.id}})
  }),
  inject('store'),
  observer
)(EntryView);
