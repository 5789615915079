import {UsersApi} from "./endpoints/users";
import {ShowsApi} from "./endpoints/shows";
import {PricesApi} from "./endpoints/prices";
import {SalesPointsApi} from "./endpoints/salesPoints";
import {ReservationsApi} from "./endpoints/reservations";
import {TicketsApi} from "./endpoints/tickets";
import {ReceiptsApi} from "./endpoints/receipts";
import {EntriesApi} from "./endpoints/entries";
import {FestivalsApi} from "./endpoints/festivals";
import {FestivalPassesApi} from "./endpoints/festivalPasses";
import {CartsApi} from "./endpoints/carts";
import {CartTicketsApi} from "./endpoints/cartTickets";
import {AddressesApi} from "./endpoints/addresses";
import {PresaleOrdersApi} from "./endpoints/presaleOrders";
import {FestivalPassCategoriesApi} from "./endpoints/festivalPassCategories";
import {EnvironmentApi} from "./endpoints/environment";
import {CartDiscountsApi} from "./endpoints/cartDiscounts";

export const Api = {
  users: UsersApi,
  festivals: FestivalsApi,
  shows: ShowsApi,
  prices: PricesApi,
  salesPoints: SalesPointsApi,
  reservations: ReservationsApi,
  tickets: TicketsApi,
  receipts: ReceiptsApi,
  entries: EntriesApi,
  festivalPasses: FestivalPassesApi,
  carts: CartsApi,
  cartTickets: CartTicketsApi,
  addresses: AddressesApi,
  presaleOrders: PresaleOrdersApi,
  festivalPassCategories: FestivalPassCategoriesApi,
  environments: EnvironmentApi,
  cartDiscounts: CartDiscountsApi,
};
