import {computed, decorate, observable} from 'mobx';
import {BaseModel} from './base';

export class Environment extends BaseModel {
  name = '';
  version = '';

  get humanName() {
    return this.name.charAt(0).toUpperCase() + this.name.slice(1);
  }

  static fromPlainObject(plainObject) {
    const environment = new Environment();
    return Object.assign(environment, plainObject);
  }
}

decorate(Environment, {
  name: observable,
  version: observable,
  humanName: computed
});
