import {Icon, Image, Label, Menu} from "semantic-ui-react";
import * as React from 'react';
import {Link} from "react-router-dom";
import {route} from "../../routing/routing";
import cirqu_logo from "../../assets/images/cirqu_logo.svg";
import {observer} from "mobx-react";
import useCart from "../../hooks/useCart";

const Navbar = () => {

  const {cart} = useCart();

  return (
    <Menu id={'navbar'} fixed={'top'}>
      <Menu.Item
        as={Link}
        to={route('/')}
        header
        className={'brand'}
      >
        <Image src={cirqu_logo}/>
      </Menu.Item>
      <Menu.Item
        as={Link}
        to={route('/')}
        name='cirqu'
      >
        Showübersicht
      </Menu.Item>
      <Menu.Menu position='right'>
        <Menu.Item
          as={Link}
          to={route('/cart')}
          name='cart'
        >
          <Icon name={'cart'}/>
          {cart ?
            <Label className={'ui primary'}>
              {cart?.cart_tickets.reduce((a, b) => a + (b["quantity"] || 0), 0)}
            </Label>
            :
            <></>
          }
        </Menu.Item>
      </Menu.Menu>
    </Menu>
  )
}

export default observer(Navbar);
