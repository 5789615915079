import * as React from "react";

const MessageOverlay = ({
                          children,
                          backgroundColor = '#000',
                          border = '2px solid #000',
                          display = 'flex',
                          justifyContent = 'center',
                          alignItems = 'center',
                          ...props
                        }) => {

  return (
    <div
      {...props}
    >
      <div
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: backgroundColor,
          opacity: 0.5,
        }}
      >
      </div>
      <div
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display,
          justifyContent,
          alignItems,
          border,
          padding: '2rem',
        }}
      >
        {children}
      </div>
    </div>
  )
};

export default MessageOverlay;
