import * as React from "react";
import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react";
import {Button, Grid, Message, Table} from "semantic-ui-react";
import {Link} from "react-router-dom";
import CartItem from "./cartItem";
import CheckoutSteps from "./checkoutSteps";
import {i18nArModel} from "@semabit/rails-i18n-tools";
import {useStore} from "../../hooks/useStore";
import useCart from "../../hooks/useCart";
import {useHistory} from "react-router";

const CartConfirmationPage = () => {
  const store = useStore();
  const {t} = useTranslation();
  const {cart} = useCart();
  const shows = store.showStore.shows;
  const history = useHistory();

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    store.showStore.loadAll();
  }, []);

  if (cart && cart.address === null) {
    history.push('/checkout/address');
  }

  return (
    <>
      <CheckoutSteps active={'confirmation'}/>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            {cart && cart.cart_tickets.length > 0 ?
              <Table style={{fontSize: '1.2em'}}>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={4}>{t(i18nArModel(`show.one`))}</Table.HeaderCell>
                    <Table.HeaderCell width={5}>{t('components.presale.cart.additional_info')}</Table.HeaderCell>
                    <Table.HeaderCell width={3}>{t(`components.presale.cart.ticket_amount`)}</Table.HeaderCell>
                    <Table.HeaderCell width={4} textAlign={'right'}>{t(i18nArModel(`price.one`))}</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {cart.cart_tickets.map((cartTicket) =>
                    <CartItem key={cartTicket.id} cart={cart} cartItem={cartTicket} shows={shows} editable={false}/>
                  )
                  }
                </Table.Body>
                {cart.cart_tickets.length >= 2 ?
                  <Table.Footer>
                    <Table.Row>
                      <Table.HeaderCell>Total</Table.HeaderCell>
                      <Table.HeaderCell></Table.HeaderCell>
                      <Table.HeaderCell
                        textAlign={'left'}>{cart.cart_tickets.reduce((a, b) => a + (b["quantity"] || 0), 0)} Tickets</Table.HeaderCell>
                      <Table.HeaderCell textAlign={'right'}>{cart.formatPrice()}</Table.HeaderCell>
                    </Table.Row>
                  </Table.Footer>
                  : null
                }
              </Table>
              : <Message>Leider befinden sich keine Tickets in Ihrem Warenkorb</Message>
            }
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid style={{marginTop: 40}}>
        <Grid.Row>
          <Grid.Column textAlign={'right'}>
            <Link to={'/checkout/payment'}>
              <Button
                disabled={cart && cart.cart_tickets.length <= 0}
                style={{margin: 0}}
                primary
              >
                Zur Bezahlung
              </Button>
            </Link>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  )
}

export default observer(CartConfirmationPage);
