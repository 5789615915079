import * as React from "react";
import {useEffect} from "react";
import {NavLink} from "react-router-dom";
import {adminRoute} from "../../../routing/routing";
import {Icon, Menu} from "semantic-ui-react";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react";
import {useStore} from "../../../hooks/useStore";

const SidebarContent = ({onNavigate = (args) => undefined}) => {

  const store = useStore();
  const {t} = useTranslation();

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    store.environmentStore.show();
  }, []);

  const handleMenuItemClick = (...args) => {
    onNavigate(args)
  }

  return (
    <React.Fragment>
      <Menu.Item as={NavLink} to={adminRoute('/festivals')} onClick={handleMenuItemClick}>
        <Icon name='child'/>
        {t('activerecord.models.festival.other')}
      </Menu.Item>
      <Menu.Item as={NavLink} to={adminRoute('/festival_pass_categories')} onClick={handleMenuItemClick}>
        <Icon name='tags'/>
        {t('activerecord.models.festival_pass_category.other')}
      </Menu.Item>
      <Menu.Item as={NavLink} to={adminRoute('/shows')} onClick={handleMenuItemClick}>
        <Icon name='play'/>
        {t('activerecord.models.show.other')}
      </Menu.Item>
      <Menu.Item as={NavLink} to={adminRoute('/sales_points')} onClick={handleMenuItemClick}>
        <Icon name='ticket'/>
        {t('activerecord.models.sales_point.other')}
      </Menu.Item>
      <Menu.Item as={NavLink} to={adminRoute('/presale_orders')} onClick={handleMenuItemClick}>
        <Icon name='cart'/>
        {t('activerecord.models.presale_order.other')}
      </Menu.Item>
      <Menu.Item as={NavLink} to={adminRoute('/reservations/search')} onClick={handleMenuItemClick}>
        <Icon name='search'/>
        {t('activerecord.attributes.reservation.search')}
      </Menu.Item>
      <Menu.Item as={NavLink} to={adminRoute('/users')} onClick={handleMenuItemClick}>
        <Icon name='user'/>
        {t('activerecord.models.user.other')}
      </Menu.Item>
      <Menu.Item style={{opacity: 0.5, fontSize: '0.8em'}}>
        <div>{store.environmentStore.environment.humanName}</div>
        <div>v{store.environmentStore.environment.version}</div>
      </Menu.Item>
    </React.Fragment>
  );
}

export default observer(SidebarContent);
