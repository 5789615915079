const CHF = new Intl.NumberFormat('de-CH', {
  style: 'currency',
  currency: 'CHF',
});

export const formatCHF = (value) => {
  if (value === undefined || value === null) {
    return '–';
  }

  return CHF.format(value);
};
