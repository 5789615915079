import * as React from "react";
import {
  Route,
  Switch
} from 'react-router-dom'
import NotFound from "../../notFound";
import NewCartDiscount from "./new";
import EditCartDiscount from "./edit";
import CartDiscountDetail from "./detail";

class Routes extends React.Component {
  render() {
    const match = this.props.match;
    return <Switch>
      <Route exact path={`${match.path}/create`} component={NewCartDiscount}/>
      <Route exact path={`${match.path}/:id/edit`} component={EditCartDiscount}/>
      <Route exact path={`${match.path}/:id`} component={CartDiscountDetail}/>
      <Route path={`${match.path}/*`} component={NotFound}/>
    </Switch>
  }
}

export default Routes;
