import * as React from "react";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react";
import {Button, Dimmer, Form, Grid, Header, Icon, Loader, Message, Segment} from "semantic-ui-react";
import {CardElement, Elements, useElements, useStripe} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import axios from "../../middleware/axios";
import {Link} from "react-router-dom";
import CheckoutSteps from "./checkoutSteps";
import config from "../../config";
import PaymentOverview from "./paymentOverview";
import {useStore} from "../../hooks/useStore";
import useCart from "../../hooks/useCart";
import LineBreakText from "../shared/lineBreakText";
import {downloadPdfFromUrl} from "../../tools/files";
import {resourcePath} from "../../middleware/apiPath";
import {presaleOrderResourcePath} from "../../middleware/endpoints/presaleOrders";

const CARD_ELEMENT_OPTIONS = {
  iconStyle: "solid",
  hidePostalCode: true,
  style: {
    base: {
      lineHeight: '1.429',
      iconColor: "#17454e",
      color: "#17454e",
      height: "50px",
      fontSize: "16px",
      fontFamily: '"Open Sans", sans-serif',
      fontSmoothing: "antialiased",
      "::placeholder": {
        color: "#5D7D83"
      }
    },
    invalid: {
      color: "#e5424d",
      ":focus": {
        color: "#303238"
      }
    }
  }
};

const CartPaymentPage = () => {

  const store = useStore();
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [presaleOrder, setPresaleOrder] = React.useState(undefined);
  const {cart} = useCart();
  const stripePromise = loadStripe(config.STRIPE_PUBLIC_KEY, {apiVersion: '2020-08-27'});
  const shows = store.showStore.shows;

  const hasDiscount = !!store.cartDiscountStore.currentCartDiscountUuid;

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    (async () => {
      if (cart?.address?.id) {
        await store.addressStore.load(cart.address.id);
      }
    })();
  }, [cart])

  useEffect(() => {
    store.showStore.loadAll();
  }, [])

  const submitWithoutPayment = async (cart) => {
    setLoading(true);
    await axios.post('/api/v1/checkout', {
        stripe_cart: cart.number,
        stripe_email: cart.address.email
      }
    ).then((result) => setPresaleOrder(result.data));

    setLoading(false);
    setSuccess(true);
  }

  if (!cart?.cart_tickets?.length) {
    return (
      <>
        <CheckoutSteps active={'payment'}/>
        <Message style={{marginTop: '2rem'}}>
          {t(`components.presale.cart.no_tickets_in_cart`)}
        </Message>
        <Segment style={{marginTop: '2rem'}}>
          <Grid columns={1} textAlign={'center'}>
            <Grid.Row verticalAlign={'middle'}>
              <Grid.Column>
                <Link to={'/'}>
                  <div>
                    <Header style={{margin: 0}} icon>
                      <Icon name={'ticket'}/>
                      {t('components.presale.cart_payment_page.continue_shopping')}
                    </Header>
                  </div>
                </Link>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </>
    )
  }

  return (
    <>
      <CheckoutSteps active={'payment'}/>
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <PaymentOverview cart={cart} shows={shows}/>
            {hasDiscount && store.cartDiscountStore.currentDiscount?.description && (
              <Grid style={{marginTop: '2rem', marginBottom: '2rem'}}>
                <Grid.Row>
                  <Grid.Column>
                    <Message color={'teal'}>
                      <Message.Header>
                        {store.cartDiscountStore.currentDiscount.fullLabel}
                      </Message.Header>
                      <LineBreakText style={{marginTop: '1em'}}>
                        {store.cartDiscountStore.currentDiscount.description}
                      </LineBreakText>
                    </Message>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            )}
            {loading ?
              <Dimmer active inverted>
                <Loader>{t('components.presale.cart_payment_page.ordering')}</Loader>
              </Dimmer>
              : <></>
            }
            {cart && !cart.payment_required ?
              success
                ? <SuccessComponent paymentRequired={false} presaleOrder={presaleOrder} cart={cart}/>
                :
                <Button
                  floated={'right'}
                  type={'submit'}
                  style={{margin: 0, marginTop: 30}}
                  primary
                  onClick={() => submitWithoutPayment(cart)}
                >
                  {t('components.presale.cart_payment_page.finish')}
                </Button>
              :
              <Elements stripe={stripePromise}>
                <CheckoutForm cart={cart}/>
              </Elements>
            }
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  )
}

const CheckoutForm = ({cart}) => {
  const stripe = useStripe();
  const {t} = useTranslation();
  const elements = useElements();
  const [error, setError] = useState(undefined);
  const [presaleOrder, setPresaleOrder] = useState(undefined);
  const [success, setSuccess] = useState(false);
  const [paymentRunning, setPaymentRunning] = useState(false)

  const handleSubmit = async (event) => {
    event.preventDefault();
    setPaymentRunning(true)

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const {error, paymentMethod} = await stripe.createPaymentMethod({
      type: 'card',
      billing_details: {
        address: {
          city: cart.address.city,
          country: "CH",
          line1: cart.address.street + " " + cart.address.house,
          postal_code: cart.address.zipcode,
        },
        email: cart.address.email,
        name: cart.address.firstname + " " + cart.address.lastname
      },
      card: elements.getElement(CardElement)
    });

    if (error) {
      setError(error);
      setPaymentRunning(false);
    } else {
      setError(null);
      const {id} = paymentMethod
      const cartNumber = cart.number

      try {
        await axios.post("/api/v1/checkout", {
          stripe_token: id,
          stripe_cart: cartNumber,
          stripe_email: cart.address.email
        }).then((result) => setPresaleOrder(result.data));
        setSuccess(true);
      } catch (error) {
        const stripeError = error.response.data.stripe;
        if (stripeError?.length) {
          setError({
            message: stripeError[0]
          })
        }
      } finally {
        setPaymentRunning(false);
      }
    }
  };

  return (
    <>
      {success ?
        <SuccessComponent paymentRequired={true} presaleOrder={presaleOrder} cart={cart}/>
        :
        <Segment style={{marginTop: '2rem'}} padded>
          {paymentRunning ?
            <Dimmer active inverted>
              <Loader>
                {t('components.presale.cart_payment_page.payment_running')}
              </Loader>
            </Dimmer>
            : <></>
          }

          <Form onSubmit={handleSubmit}>
            <h2 style={{marginBottom: '2rem'}}>
              {t('components.presale.cart_payment_page.payment_by_credit_card')}
            </h2>
            <Message>
              {t('stripe.custom_errors.3ds_not_supported')}
            </Message>
            <div className={"card-elements-stripe"}>
              <CardElement options={CARD_ELEMENT_OPTIONS}/>
            </div>
            {error && (
              <Message negative>
                {error.message}
              </Message>
            )}
            <Button
              disabled={!stripe}
              type={'submit'}
              style={{margin: 0, marginTop: 30}}
              primary
            >
              {t('components.presale.cart_payment_page.pay')}
            </Button>
          </Form>
        </Segment>
      }
    </>
  )
}

const SuccessComponent = ({paymentRequired, presaleOrder, cart}) => {
  const {t} = useTranslation();
  const [isDownloading, setIsDownloading] = useState(false);
  const [pdfDownloadError, setPdfDownloadError] = useState(undefined);
  const [pdfDownloadSuccess, setPdfDownloadSuccess] = useState(undefined);

  const downloadPresaleOrder = () => {
    if (!presaleOrder?.id || !cart) {
      return;
    }

    setPdfDownloadSuccess(null);
    setPdfDownloadError(null);
    setIsDownloading(true);
    downloadPdfFromUrl(resourcePath(presaleOrderResourcePath(`/${presaleOrder.id}.pdf`)), {}, {
      cartId: cart.number
    })
      .then(() => setPdfDownloadSuccess(true))
      .catch((e) => {
        if (e.response.status === 401) {
          setPdfDownloadError({message: t('messages.presale_order_pdf_download_unauthorized')})
        }
      })
      .finally(() => setIsDownloading(false));
  }

  return (
    <>
      <Grid style={{marginTop: '2rem', marginBottom: '2rem'}}>
        <Grid.Row>
          <Grid.Column>
            <Message>
              <Message.Header>
                {t('components.presale.cart_payment_page.thanks')}
              </Message.Header>
              {paymentRequired ? (
                <p>
                  {t('components.presale.cart_payment_page.successful_payment')}
                </p>
              ) : (
                <p>
                  {t('components.presale.cart_payment_page.successful_order')}
                </p>
              )}
            </Message>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Segment>
        <Grid columns={2} divided stackable textAlign={'center'}>
          <Grid.Row verticalAlign={'middle'}>
            <Grid.Column>
              <Link to={'/'}>
                <div>
                  <Header style={{margin: 0}} icon>
                    <Icon name={'ticket'}/>
                    {t('components.presale.cart_payment_page.continue_shopping')}
                  </Header>
                </div>
              </Link>
            </Grid.Column>
            <Grid.Column>
              <Button style={{width: '100%', backgroundColor: 'transparent'}} onClick={() => downloadPresaleOrder()}
                      loading={isDownloading}>
                <div>
                  <Header style={{margin: 0}} icon>
                    <Icon name={'file pdf'}/>
                    {t('components.presale.cart_payment_page.download_tickets')}
                  </Header>
                </div>
                {pdfDownloadSuccess !== undefined && pdfDownloadSuccess !== null && pdfDownloadSuccess && (
                  <Icon name={'check'} color={'green'} style={{marginTop: '1rem'}}/>
                )}
                {pdfDownloadError && (
                  <Message negative>
                    {pdfDownloadError.message}
                  </Message>
                )}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </>
  )
}

export default observer(CartPaymentPage)
