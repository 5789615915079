import React from 'react'
import Navbar from "./components/presale/navbar";
import PreSale from "./components/presale/presale";
import Footer from "./components/presale/footer";
import {useStore} from "./hooks/useStore";
import {observer} from "mobx-react";

export const App = () => {
  const store = useStore();

  return (
    <>
      <Navbar/>
      <div
        style={{minHeight: 'calc(100% - 100px)'}}
        className={'app-container ' + (store.configStore.isMobile ? 'mobile' : 'desktop')}
      >
        <PreSale/>
      </div>
      <Footer/>
    </>
  )
}

export default observer(App);
