import {observer} from "mobx-react";
import {useStore} from "../../../hooks/useStore";
import {useTranslation} from "react-i18next";
import * as React from "react";
import {useEffect} from "react";
import {LabeledIconLinkButton} from "../shared/buttons";
import {i18nArAttr, i18nArModel} from "@semabit/rails-i18n-tools";
import {adminRoute} from "../../../routing/routing";
import {EntityTable} from "../shared/tables";
import {Message} from "semantic-ui-react";
import CartDiscountListItem from "./listitem";

const CartDiscountList = ({festivalId}) => {
  const store = useStore();
  const {t} = useTranslation();
  const cartDiscounts = store.cartDiscountStore.cartDiscounts;
  const festival = store.festivalStore.getById(festivalId);

  const headers = [
    t(i18nArAttr('cart_discount.uuid')),
    t(i18nArAttr('cart_discount.label')),
    t(i18nArAttr('cart_discount.percentage')),
    ''
  ];

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (festivalId) {
      store.cartDiscountStore.loadAll({
        params: {
          festivalId: festivalId
        }
      });
    }
  }, [festivalId])

  return (
    <div>
      <LabeledIconLinkButton
        text={t(i18nArModel('cart_discount.one'))}
        route={adminRoute(`/festivals/${festival?.id}/cart_discounts/create/`)}
      />
      {!!cartDiscounts.length && (
        <EntityTable headers={headers}>
          {cartDiscounts.map((cartDiscount) => (
            <CartDiscountListItem key={cartDiscount.id} cartDiscount={cartDiscount} festivalId={festivalId}/>
          ))}
        </EntityTable>
      )}
      {!cartDiscounts.length && (
        <Message>{t('components.admin.festivals.partials.cart_discount_list.no_items')}</Message>
      )}
    </div>
  )
}

export default observer(CartDiscountList);
