import * as React from 'react';
import {Grid} from "semantic-ui-react";
import {Link} from "react-router-dom";

const Footer = () => {

  return (
    <footer className={'presale-footer'}>
      <Grid container>
        <Grid.Column mobile={16} tablet={8} computer={8}>
          <address>
            <b>cirqu'Aarau</b><br/>
            5000 Aarau
          </address>
        </Grid.Column>
        <Grid.Column mobile={16} tablet={8} computer={8} style={{textAlign: "right"}}>
          <a style={{color: "white"}} href="mailto:info@cirquaarau.ch">
            info@cirquaarau.ch<br/><br/>
          </a>
          <p style={{textAlign: "right", fontWeight: 'bold', fontSize: '0.8em'}}>
            <Link to={'/agb'} target={'_blank'} style={{color: 'white', marginRight: 10}}>AGB</Link>
            |
            <a
              href={'https://www.cirquaarau.ch/datenschutz.289de.html'}
              target={'_blank'}
              rel={'noreferrer noopener'}
              style={{color: 'white', marginLeft: 10, marginRight: 10}}
            >
              Datenschutz
            </a>
            |
            <a
              href={'https://www.cirquaarau.ch/impressum.288de.html'}
              target={'_blank'}
              rel={'noreferrer noopener'}
              style={{color: 'white', marginLeft: 10, marginRight: 10}}
            >
              Impressum
            </a>
            |
            <span style={{marginLeft: 10}}>Copyright © {(new Date().getFullYear())} cirqu'</span>
          </p>
        </Grid.Column>
      </Grid>
    </footer>
  );
}

export default (Footer)
