import * as React from 'react';
import {withTranslation} from "react-i18next";
import {Table} from "semantic-ui-react";
import compose from "compose-function";
import {adminRoute} from "../../../routing/routing";
import {inject, observer} from "mobx-react";
import {DetailsButton} from "../shared/buttons";
import {i18nArAttr} from "@semabit/rails-i18n-tools";

class SalesPointListItem extends React.Component {

  render() {
    return (
      <Table.Row>
        <Table.Cell>{this.props.salesPoint.name}</Table.Cell>
        <Table.Cell>{this.props.salesPoint.location}</Table.Cell>
        <Table.Cell>{this.props.t(i18nArAttr(`sales_point.settings.print_mode.values.${this.props.salesPoint.settings.print_mode}`))}</Table.Cell>
        <Table.Cell collapsing>
          <DetailsButton route={adminRoute(`/sales_points/${this.props.salesPoint.id}`)}/>
        </Table.Cell>
      </Table.Row>
    )
  }
}

export default compose(
  withTranslation('translation'),
  inject('store'),
  observer
)(SalesPointListItem);
