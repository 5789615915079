import {Table} from "semantic-ui-react";
import {groupBy} from "lodash/collection";
import * as React from "react";
import CustomForm from "../../forms/form";
import FestivalPassTableCells from "./festivalPassTableCells";
import DefaultTableCells from "./defaultTableCells";

const ShowDetailsPrices = ({
                             prices,
                             show,
                             cart,
                             totalCounter,
                             priceCounters,
                             handleAdd,
                             handleMinus,
                             addTicketsToCart,
                             festivalpassFormRef,
                             remarksFormRefs
                           }) => {
  const priceGroups = groupBy(prices, (price) => price.price_type);
  const defaultPriceGroups = Object.entries(priceGroups)
    .filter(([key, value]) => key !== 'festivalpass')
    .map(([key, value]) => value);
  const festivalPassPriceGroups = Object.entries(priceGroups)
    .filter(([key, value]) => key === 'festivalpass')
    .map(([key, value]) => value);

  const handleFestivalPassValidation = (values) => {
    addTicketsToCart(cart, values, 1);
  };

  return (
    <>
      <Table style={{fontSize: '1.2em'}}>
        <Table.Header className={'table-header-hidden'}>
          <Table.Row>
            <Table.HeaderCell width={4}></Table.HeaderCell>
            <Table.HeaderCell width={4}></Table.HeaderCell>
            <Table.HeaderCell width={4}></Table.HeaderCell>
            <Table.HeaderCell width={4}></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {defaultPriceGroups.map((prices) => {
              const price = prices.length ? prices[0] : null;

              return (
                <DefaultTableCells
                  price={price}
                  show={show}
                  handleAdd={handleAdd}
                  handleMinus={handleMinus}
                  priceCounters={priceCounters}
                  totalCounter={totalCounter}
                  addTicketsToCart={addTicketsToCart}
                  cart={cart}
                  remarksFormRefs={remarksFormRefs}
                />
              )
            }
          )}
        </Table.Body>
      </Table>
      {festivalPassPriceGroups.length > 0 && (
        <CustomForm onSubmit={handleFestivalPassValidation}>
          <Table style={{fontSize: '1.2em'}}>
            <Table.Header className={'table-header-hidden'}>
              <Table.Row>
                <Table.HeaderCell width={8}></Table.HeaderCell>
                <Table.HeaderCell width={4}></Table.HeaderCell>
                <Table.HeaderCell width={4}></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {festivalPassPriceGroups.map((prices) => {
                  const price = prices.length ? prices[0] : null;

                  return (
                    <FestivalPassTableCells
                      key={price.id}
                      price={price}
                      show={show}
                      festivalpassFormRef={festivalpassFormRef}
                    />
                  )
                }
              )}
            </Table.Body>
          </Table>
        </CustomForm>
      )}
    </>
  )
}

export default ShowDetailsPrices;
