import * as React from 'react';
import {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react";
import {EntityDetailTable, EntityDetailTableEntry} from "../shared/tables";
import {i18nArAttr, i18nArModel, i18nBool} from "@semabit/rails-i18n-tools";
import {adminRoute} from "../../../routing/routing";
import {DeleteButton, EditButton} from "../shared/buttons";
import {useHistory, useParams} from "react-router";
import {confirmDelete} from "../shared/confirm";
import {EntityDetailLayout} from "../shared/entityDetailLayout";
import {useStore} from "../../../hooks/useStore";
import {Loader} from "semantic-ui-react";

const PriceDetail = () => {
  const {t} = useTranslation();
  const store = useStore();
  const history = useHistory();
  const {showId, id} = useParams();
  const show = store.showStore.getById(showId);
  const price = store.priceStore.getById(id)
  const festivalPassCategory = store.festivalPassCategoryStore.getById(price?.festival_pass_category_id);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    store.showStore.load(showId);
    if (showId) {
      store.priceStore.load(id, {params: {showId: showId}});
    }
  }, [showId, id]);

  useEffect(() => {
    if (price) {
      if (price.price_type === 'festivalpass') {
        store.festivalPassCategoryStore.load(price.festival_pass_category_id)
      }
    }
  }, [price])

  const del = () => {
    if (confirmDelete(t, 'price')) {
      store.priceStore.delete(price.id, {params: {showId: show.id}}).then(() => {
        history.push(adminRoute(`/shows/${show.id}`));
      });
    }
  };

  if (store.showStore.isLoading || store.priceStore.isLoading) {
    return (
      <EntityDetailLayout title={t(i18nArModel('price.one'))}>
        <Loader/>
      </EntityDetailLayout>
    )
  }

  if (!show || !price) {
    return null;
  }

  return (
    <EntityDetailLayout title={t(i18nArModel('price.one'))} backPath={`/shows/${show.id}`}>
      <EditButton route={adminRoute(`/shows/${show.id}/prices/${price.id}/edit`)}/>
      <DeleteButton onClick={() => del()}/>
      <EntityDetailTable>
        <EntityDetailTableEntry
          name={t(i18nArModel('show.one'))}><b>{show.name}</b></EntityDetailTableEntry>
        <EntityDetailTableEntry
          name={t(i18nArAttr('price.price'))}><b>{price.formatPrice()}</b></EntityDetailTableEntry>
        <EntityDetailTableEntry
          name={t(i18nArAttr('price.internal'))}>{t(i18nBool(price.internal))}</EntityDetailTableEntry>
        <EntityDetailTableEntry
          name={t(i18nArAttr('price.price_type'))}
        >
          {t(i18nArAttr(`price.price_types.${price.price_type}`))}
          {festivalPassCategory ? <br/> : ''}
          {festivalPassCategory ? festivalPassCategory.name : ''}
        </EntityDetailTableEntry>
      </EntityDetailTable>
    </EntityDetailLayout>
  )
}

export default observer(PriceDetail);
