import {computed, decorate, observable} from "mobx";
import {Api} from "../middleware/api";
import {EntityStore} from "./entityStore";
import {CartDiscount, cartDiscountSessionStorageKey} from "../models/cartDiscount";

export class CartDiscountStore extends EntityStore {
  cartDiscounts = [];

  constructor(rootStore) {
    super(rootStore, 'cartDiscounts', Api.cartDiscounts, CartDiscount);
  }

  get currentDiscount() {
    return this.cartDiscounts.find((cartDiscount) => cartDiscount.uuid === this.currentCartDiscountUuid);
  }

  get currentCartDiscountUuid() {
    return sessionStorage.getItem(cartDiscountSessionStorageKey);
  }
}

decorate(CartDiscountStore, {
  cartDiscounts: observable,
  currentCartDiscountUuid: computed,
  currentDiscount: computed
});
