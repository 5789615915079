import * as React from "react";
import {useEffect, useState} from "react";
import {Button, Confirm, Table} from "semantic-ui-react";
import moment from "moment";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react";
import {i18nArAttr, i18nArModel} from "@semabit/rails-i18n-tools";
import {useStore} from "../../hooks/useStore";
import {LoadStrategies} from "../../stores/entityStore";
import DiscountedPrice from "../shared/discountedPrice";
import useCartDiscount from "../../hooks/useCartDiscount";

const CartItem = ({shows, cart, cartItem, editable}) => {
  const {t} = useTranslation();
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const store = useStore();
  const show = store.showStore.getById(cartItem.show_id);
  const price = store.priceStore.getById(cartItem.price_id);
  cartItem.cart_number = cart.number;
  const hasDiscount = !!store.cartDiscountStore.currentCartDiscountUuid && (cartItem.total_price_formatted !== cartItem.original_total_price_formatted);

  useCartDiscount({festivalId: show?.festival_id});

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    (async () => {
      if (show) {
        await store.priceStore.loadAll({params: {showId: show.id}, strategy: LoadStrategies.add});
      }
    })();
  }, [show]);

  const handleMinus = (item) => {
    if (item.quantity > 1) {
      item.quantity -= 1;
      store.cartTicketStore.update(item).then(() => {
        store.cartStore.load(cart.number)
        store.showStore.load(item.show_id)
      })
    } else {
      handleDelete(item)
    }
  }

  const handleAdd = (item) => {
    const show = shows.find(show => show.id === item.show_id)
    if (show.available_presale_tickets_count > 0) {
      item.quantity += 1;
      store.cartTicketStore.update(item).then(() => {
        store.cartStore.load(cart.number)
        store.showStore.load(item.show_id)
      })
    }
  }

  const handleDelete = (item) => {
    store.cartTicketStore.delete(item).then(() => {
      store.cartStore.load(cart.number);
      handleHideConfirmDelete();
    })
  }

  const handleShowConfirmDelete = () => {
    setShowConfirmDelete(true)
  }

  const handleHideConfirmDelete = () => {
    setShowConfirmDelete(false);
  }

  return (
    <Table.Row key={cartItem.id}>
      <Table.Cell>
        {show?.name && <div className={'fw-bold'}>{show.name}</div>}
        {show?.begins_at && <div className={'fw-normal'}>{moment(show.begins_at).format()}</div>}
        {show?.location && <div className={'fw-normal'}>{show.location}</div>}
      </Table.Cell>
      <Table.Cell>
        <dl>
          <>
            <dt>{t(i18nArAttr('price.price_type'))}</dt>
            <dd>{t(i18nArAttr(`price.price_types.${price?.price_type}`))}</dd>
          </>
          {cartItem.remarks && price && (
            <>
              <dt>{t(i18nArAttr(`price.ticket_remark_label.${price?.price_type}`))}</dt>
              <dd>{cartItem.remarks}</dd>
            </>
          )}
        </dl>
      </Table.Cell>
      <Table.Cell>
        {!editable && (
          <span>{cartItem.quantity} {cartItem.quantity === 1 ? t(i18nArModel(`ticket.one`)) : t(i18nArModel(`ticket.other`))}</span>
        )}
        {editable && (
          <div className={'flex-column-gap'} style={{'--max-width': '220px'}}>
            <div className={'flex-spaced-row'}>
              <Button icon='minus' onClick={() => handleMinus(cartItem)}/>
              {cartItem.quantity} {cartItem.quantity === 1 ? t(i18nArModel(`ticket.one`)) : t(i18nArModel(`ticket.other`))}
              <Button
                icon='plus'
                onClick={() => handleAdd(cartItem)}
                disabled={price?.price_type === 'festivalpass' && cartItem.quantity > 0}
              />
            </div>
          </div>
        )}
      </Table.Cell>
      <Table.Cell textAlign={'right'}>
        {hasDiscount && cartItem && (
          <DiscountedPrice
            value={cartItem.total_price_formatted}
            insteadOfValue={cartItem.original_total_price_formatted}
            discountLabel={store.cartDiscountStore.currentDiscount?.fullLabel}
          />
        )}
        {!hasDiscount && cartItem && (
          cartItem.total_price_formatted
        )}
      </Table.Cell>
      {editable ?
        <Table.Cell textAlign={'right'}>
          <Button icon='trash' onClick={handleShowConfirmDelete}/>
          <Confirm
            open={showConfirmDelete}
            onCancel={handleHideConfirmDelete}
            onConfirm={() => handleDelete(cartItem)}
            content={t('admin.entity.confirm_delete', {model_name: t(i18nArModel(`ticket.one`))})}
          />
        </Table.Cell>
        : null
      }
    </Table.Row>
  )
}

export default observer(CartItem);
