import {BaseModel} from "./base";
import {computed, decorate, observable} from "mobx";

export const cartDiscountTypePercentage = 'percentage';

export const cartDiscountSessionStorageKey = 'session-storage';

export const cartDiscountTypes = {
  percentage: 'cartDiscountTypePercentage'
}

export class CartDiscount extends BaseModel {
  id = 0;
  festival_id = 0;
  label = '';
  description = '';
  uuid = '';
  discount_type = cartDiscountTypePercentage;
  percentage = 0.0;
  created_at = '';
  update_at = '';

  static fromPlainObject(plainObject) {
    const cartDiscount = new CartDiscount();
    return Object.assign(cartDiscount, plainObject);
  }

  get percentageFormatted() {
    return `${this.percentage * 100}%`;
  }

  get queryString() {
    return `cd=${this.uuid}`
  }

  get fullLabel() {
    return `${this.percentageFormatted} ${this.label}`
  }
}

decorate(CartDiscount, {
  id: observable,
  uuid: observable,
  label: observable,
  description: observable,
  percentageFormatted: computed,
  fullLabel: computed,
});
