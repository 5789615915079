import * as React from "react";
import {Button, Header, Segment} from "semantic-ui-react";
import compose from "compose-function";
import {observer} from "mobx-react";
import classnames from "classnames";

const EntryCounter = (props) => {

  const {
    title,
    entryType,
    count = 0,
    onDecrement,
    onIncrement,
    canIncrement = true,
    loading = false,
    children,
    readonly = false
  } = props;

  const decrementDisabled = count === 0 || loading;
  const incrementDisabled = !canIncrement || loading;

  const handleDecrement = (e) => {
    e.preventDefault();
    if (decrementDisabled) return;
    if (onDecrement) onDecrement(entryType);
  };

  const handleIncrement = (e) => {
    e.preventDefault();
    if (incrementDisabled) return;
    if (onIncrement) onIncrement(entryType);
  };

  // disabled state is handled custom because for ios we need to prevent default behaviour,
  // when button is disabled double tapping on it will zoom in
  return (
    <Segment>
      <Header as='h3' style={{textAlign: 'center'}}>{title}</Header>
      <div className={'entry-counter-content'} style={{justifyContent: readonly ? 'center' : undefined}}>
        {!readonly && (
          <Button
            className={classnames({disabled: decrementDisabled})}
            icon={'minus'}
            onClick={handleDecrement}
            loading={loading} disabled={loading}
          />
        )}
        <p className={'entries-count'}>{children ? children : count}</p>
        {!readonly && (
          <Button
            className={classnames({disabled: incrementDisabled})}
            icon={'add'}
            onClick={handleIncrement}
            loading={loading}
            disabled={loading}
          />
        )}
      </div>
    </Segment>
  )
};

export default compose(observer)(EntryCounter);
