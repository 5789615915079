import * as React from 'react';
import {withTranslation} from "react-i18next";
import {Button, Popup, Table} from "semantic-ui-react";
import compose from "compose-function";
import {adminRoute} from "../../../routing/routing";
import {inject, observer} from "mobx-react";
import {DetailsButton} from "../shared/buttons";
import moment from "moment";
import {Link} from "react-router-dom";
import {i18nArModel} from "@semabit/rails-i18n-tools";
import PresaleOrderMailState from "../../shared/PresaleOrderMailState";

class PresaleOrderListItem extends React.Component {

  constructor(props) {
    super(props);
    this.showClicked = this.showClicked.bind(this);
    this.downloadClicked = this.downloadClicked.bind(this);
  }

  showClicked() {
    if (this.props.onShow) this.props.onShow(this.props.presaleOrder);
  }

  downloadClicked() {
    if (this.props.onDownload) this.props.onDownload(this.props.presaleOrder);
  }

  render() {
    const {shows, key} = this.props
    const ticketCount = this.props.presaleOrder.tickets.reduce((allShows, ticket) => {
      if (ticket.show_id in allShows) {
        allShows[ticket.show_id]++;
      } else {
        allShows[ticket.show_id] = 1;
      }
      return allShows;
    }, {})

    const show = shows.find((s) => s.id.toString() === Object.keys(ticketCount)[0]);
    const presaleActive = show && show.presale_active

    return (
      <Table.Row key={key} className={presaleActive ? '' : 'inactive'}>
        <Table.Cell>{this.props.presaleOrder.id}</Table.Cell>
        <Table.Cell>
          {this.props.presaleOrder.created_at
            ? moment(this.props.presaleOrder.created_at).format()
            : '-'}
        </Table.Cell>
        <Table.Cell>{this.props.presaleOrder.address.firstname}</Table.Cell>
        <Table.Cell>{this.props.presaleOrder.address.lastname}</Table.Cell>
        <Table.Cell>
          {this.props.presaleOrder.address.email}
        </Table.Cell>
        <Table.Cell>
          <PresaleOrderMailState
            state={this.props.presaleOrder.current_mail_delivery_state}
            error={this.props.presaleOrder.current_mail_delivery_error}
          />
        </Table.Cell>
        <Table.Cell>
          {Object.keys(ticketCount).map(showId => {
              let show = shows.find((s) => s.id.toString() === showId)
              if (show) {
                return (
                  <p key={show.id}>
                    <Link to={adminRoute(`/shows/${show.id}`)}>
                      <b>{show.name} </b>
                    </Link>
                    am {show.begins_at ? moment(show.begins_at).format() : '-'} ({ticketCount[showId]} Tickets)
                  </p>
                )
              } else {
                return <></>;
              }
            }
          )}
        </Table.Cell>
        <Table.Cell collapsing>
          <DetailsButton
            route={adminRoute(`/presale_orders/${this.props.presaleOrder.id}`)}
          />
          <Popup
            content={`${this.props.t(i18nArModel('presale_order.one'))} ${this.props.t(i18nArModel('ticket.one'))} öffnen`}
            trigger={
              <Button icon={'file pdf'} onClick={this.showClicked}/>
            }/>
          <Popup
            content={`${this.props.t(i18nArModel('presale_order.one'))} ${this.props.t(i18nArModel('ticket.one'))} herunterladen`}
            trigger={
              <Button icon={'download'} onClick={this.downloadClicked}/>
            }/>
        </Table.Cell>
      </Table.Row>
    )
  }
}

export default compose(
  withTranslation('translation'),
  inject('store'),
  observer
)(PresaleOrderListItem);
