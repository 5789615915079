import {action, computed, decorate, observable, runInAction} from "mobx";
import {ApiError} from "../models/apiError";

export const LoadStrategies = {
  replace: 'replace',
  add: 'add'
};

export class EntityStore {

  pendingRequests = 0;
  pendingActions = {
    loadAll: new Map(),
    create: new Map(),
    update: new Map()
  };

  constructor(rootStore, entityNamePlural, api, modelClass) {
    this.rootStore = rootStore;
    this.listName = entityNamePlural;
    this.api = api;
    this.modelClass = modelClass;
    this.comparator = (id) => (e) => e.id.toString() === id.toString();
    this.actionKey = this.actionKey.bind(this);
    this.addAll = this.addAll.bind(this);
  }

  add(entity) {
    if (!entity) {
      return;
    }
    if (!this[this.listName].find(this.comparator(entity.id))) {
      this[this.listName].push(entity);
    } else {
      this.replace(entity);
    }
  }

  load(id, options = {}) {
    if (id === undefined || id === null) {
      return null;
    }

    this.pendingRequests++;
    return this.api.byId(id, options.params).then(entity => {
      entity = this.modelClass.fromPlainObject(entity);
      runInAction(() => {
          this.add(entity);
          this.pendingRequests--;
        }
      );
      return entity;
    }).catch(e => this.handleApiError(e));
  }

  actionKey(action, params) {
    return [action, this.listName, JSON.stringify(params)].join('');
  }

  addAll(entities) {
    for (let entity of entities) {
      this.add(entity);
    }
  }

  loadAll(options = {}) {
    const defaultOptions = {
      strategy: LoadStrategies.replace,
      params: {},
    };
    options = Object.assign(defaultOptions, options);

    const actionKey = this.actionKey('loadAll', options.params);
    if (this.pendingActions['loadAll'].has(actionKey)) {
      return this[this.listName];
    }
    this.pendingActions['loadAll'].set(actionKey, true);
    this.pendingRequests++;
    return this.api.all(options.params).then(entities => {
      entities = entities.map(e => this.modelClass.fromPlainObject(e));
      runInAction(() => {
        if (options.strategy === LoadStrategies.replace) {
          this[this.listName].replace(entities);
        } else if (options.strategy === LoadStrategies.add) {
          this.addAll(entities);
        }
        this.pendingRequests--;
        this.pendingActions['loadAll'].delete(actionKey);
      });

      return this[this.listName];
    }).catch(e => this.handleApiError(e, 'loadAll', actionKey));
  }

  getById(id) {
    if (!id) {
      return null;
    }
    return this[this.listName].find(this.comparator(id));
  }

  replace(entity) {
    const oldEntity = this.getById(entity.id);
    if (!oldEntity) {
      return null;
    }
    return oldEntity.copyAttributesFrom(entity);
  }

  remove(id) {
    if (!id) {
      return null;
    }
    const index = this[this.listName].findIndex(this.comparator(id));
    if (index >= 0) {
      this[this.listName].splice(index, 1);
    }
  }

  delete(id, options = {}) {
    this.pendingRequests++;
    return this.api.destroy(id, options.params).then((response) => {
      this.addMessage({type: 'success', title: 'messages.delete_success'}, options);
      runInAction(() => this.pendingRequests--);
      this.remove(id);
      return response;
    }).catch(e => this.handleApiError(e));
  }

  create(entity, options = {}) {
    const {action = 'create'} = options;
    const actionKey = this.actionKey(action, options.params);
    this.pendingActions[action].set(actionKey, entity);
    this.pendingRequests++;
    return this.api.create(entity).then((entity) => {
      this.addMessage({type: 'success', title: 'messages.create_success'}, options);
      entity = this.modelClass.fromPlainObject(entity);
      runInAction(() => {
        this.pendingRequests--;
        this.pendingActions[action].delete(actionKey);
      });
      this.add(entity);
      return entity;
    }).catch(e => this.handleApiError(e, action, actionKey));
  }

  update(entity, options = {}) {
    const {action = 'update'} = options;
    const actionKey = this.actionKey(action, options.params);
    this.pendingActions[action].set(actionKey, true);
    this.pendingRequests++;
    return this.api.update(entity).then((entity) => {
      this.addMessage({type: 'success', title: 'messages.update_success'}, options);
      entity = this.modelClass.fromPlainObject(entity);
      runInAction(() => {
        this.pendingRequests--;
        this.pendingActions[action].delete(actionKey);
      });
      return this.replace(entity);
    }).catch(e => this.handleApiError(e, action, actionKey));
  }

  get isLoading() {
    return this.pendingRequests > 0;
  }

  isActionInProgress(action) {
    return this.pendingActions[action].size > 0;
  }

  actionState(action) {
    const act = this.pendingActions[action];
    if (act.size) {
      return this.pendingActions[action].values()[0];
    }

    return undefined;
  }

  handleApiError(e, action, actionKey) {
    if (action && actionKey) {
      this.pendingActions[action].delete(actionKey);
    }
    runInAction(() => this.pendingRequests--);
    return Promise.reject(ApiError.fromPlainObject(e));
  }

  addMessage(message, {skipNotification}) {
    if (!skipNotification) {
      this.rootStore.messageStore.addMessage(message);
    }
  }
}

decorate(EntityStore, {
  add: action,
  create: action,
  load: action,
  loadAll: action,
  remove: action,
  replace: action,
  update: action,
  pendingRequests: observable,
  pendingActions: observable,
  isLoading: computed,
});
