import * as React from 'react';
import {useEffect} from 'react';
import {useTranslation} from "react-i18next";
import {Table} from "semantic-ui-react";
import {adminRoute} from "../../../routing/routing";
import {observer} from "mobx-react";
import {DetailsButton} from "../shared/buttons";
import {i18nArAttr, i18nBool} from "@semabit/rails-i18n-tools";
import {useStore} from "../../../hooks/useStore";

const PriceListItem = ({price, show}) => {
  const {t} = useTranslation();
  const store = useStore();
  const festivalPassCategory = store.festivalPassCategoryStore.getById(price?.festival_pass_category_id);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (price) {
      if (price.price_type === 'festivalpass') {
        store.festivalPassCategoryStore.load(price.festival_pass_category_id)
      }
    }
  }, [price])

  return (
    <Table.Row>
      <Table.Cell>{price.formatPrice()}</Table.Cell>
      <Table.Cell>
        {t(i18nArAttr(`price.price_types.${price.price_type}`))}
        {festivalPassCategory ? <br/> : ''}
        {festivalPassCategory ? festivalPassCategory.name : ''}
      </Table.Cell>
      <Table.Cell>{t(i18nBool(price.internal))}</Table.Cell>
      <Table.Cell collapsing>
        <DetailsButton route={adminRoute(`/shows/${show.id}/prices/${price.id}`)}/>
      </Table.Cell>
    </Table.Row>
  );
}

export default observer(PriceListItem);
