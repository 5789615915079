import * as React from "react";
import {i18nArAttr} from "@semabit/rails-i18n-tools";
import {EntityDetailTableEntry} from "../shared/tables";
import {withTranslation} from "react-i18next";
import {inject, observer} from "mobx-react";
import compose from "compose-function";
import loadEntity from "../shared/loadEntity";
import {adminRoute} from "../../../routing/routing";
import {Loader} from "semantic-ui-react";
import {Link} from "react-router-dom";

class UserDetailTableEntry extends React.Component {

  render() {
    const {t} = this.props;
    return <EntityDetailTableEntry name={t(i18nArAttr('reservation.user'))}>
      {this.props.user ? <Link to={adminRoute(`/users/${this.props.user.id}`)}>{this.props.user.email}</Link> :
        <Loader active inline/>}
    </EntityDetailTableEntry>
  }
}

export default compose(
  loadEntity('userStore', 'user', adminRoute('/users'), {
    identifier: (props) => props.userId,
    showLoader: false
  }),
  withTranslation('translation'), inject('store'), observer
)(UserDetailTableEntry);
