import * as React from 'react';
import {withTranslation} from "react-i18next";
import {inject, observer} from "mobx-react";
import compose from "compose-function";
import {EntityDetailTable, EntityDetailTableEntry} from "../shared/tables";
import {adminRoute} from "../../../routing/routing";
import {DeleteButton, EditButton} from "../shared/buttons";
import {withRouter} from "react-router";
import {confirmDelete} from "../shared/confirm";
import {EntityDetailLayout} from "../shared/entityDetailLayout";
import {loadEntity} from "../shared/loadEntity";
import {UserPolicy} from "../../../policies/userPolicy";
import {i18nArAttr, i18nArModel, i18nBool} from "@semabit/rails-i18n-tools";

class UserDetail extends React.Component {

  render() {
    const {t} = this.props;
    return (
      <EntityDetailLayout title={t(i18nArModel('user.one'))} backPath={`/users`}>
        <EditButton route={adminRoute(`/users/${this.props.user.id}/edit`)}/>
        {new UserPolicy(this.props.store).canDeleteUser(this.props.user) ?
          <DeleteButton onClick={() => this.delete()}/> : ''}

        <EntityDetailTable>
          <EntityDetailTableEntry
            name={t(i18nArAttr('user.email'))}><b>{this.props.user.email}</b></EntityDetailTableEntry>
          <EntityDetailTableEntry
            name={t(i18nArAttr('user.first_name'))}>{this.props.user.first_name}</EntityDetailTableEntry>
          <EntityDetailTableEntry
            name={t(i18nArAttr('user.last_name'))}>{this.props.user.last_name}</EntityDetailTableEntry>
          <EntityDetailTableEntry
            name={t(i18nArAttr('user.admin'))}>{t(i18nBool(this.props.user.admin))}</EntityDetailTableEntry>
        </EntityDetailTable>
      </EntityDetailLayout>
    )
  }

  delete() {
    if (confirmDelete(this.props.t, 'user')) {
      this.props.store.userStore.delete(this.props.user.id).then(() => {
        this.props.history.push(adminRoute('/users'));
      });
    }
  }
}

export default compose(
  loadEntity('userStore', 'user', adminRoute('/users')),
  withRouter,
  withTranslation('translation'),
  inject('store'),
  observer
)(UserDetail);
