import * as React from "react";
import {withTranslation} from "react-i18next";
import CustomForm from "../../forms/form";
import Text from "../../forms/text";
import {inject, observer} from "mobx-react";
import {Checkbox, Form} from "semantic-ui-react";
import {withRouter} from "react-router";
import compose from "compose-function";
import {SubmitButton} from "../shared/buttons";
import {i18nArAttr} from "@semabit/rails-i18n-tools";
import {Festival} from "../../../models/festival";
import DateTimePicker from "../../forms/dateTimePicker";
import TextArea from "../../forms/textarea";

class FestivalForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      presale_active_checked: props.festival ? props.festival.presale_active : false
    };
    this.handleOnSubmit = this.handleOnSubmit.bind(this);
    this.successCallback = this.successCallback.bind(this);
    this.errorCallback = this.errorCallback.bind(this);
  }

  handleOnSubmit(festival) {
    festival = {
      ...festival,
      presale_description: festival.presale_description || '',
      id: this.props.festival ? this.props.festival.id : null,
      presale_active: this.state.presale_active_checked,
    };

    if (this.props.festival) {
      this.props.store.festivalStore.update(festival).then(this.successCallback).catch(this.errorCallback);
    } else {
      this.props.store.festivalStore.create(festival).then(this.successCallback).catch(this.errorCallback);
    }
  }

  errorCallback(formErrors) {
    this.setState({errors: formErrors});
  }

  successCallback(show) {
    this.setState({errors: {}});
    if (this.props.redirectTo) {
      this.props.history.push(this.props.redirectTo);
    }
  }

  endsAtValid(currentDate) {
    return !this.state.starts_at || currentDate.endOf('day').isSameOrAfter(this.state.starts_at);
  }

  presaleEndsAtValid(currentDate) {
    return !this.state.starts_at || currentDate.endOf('day').isSameOrBefore(this.state.starts_at);
  }

  render() {
    // initialValues are not updated in the form once rendered, re-render does not update them either.
    const {t, festival = new Festival()} = this.props;

    return <CustomForm onSubmit={this.handleOnSubmit} initialValues={{...festival}}>
      <Form.Field>
        <label htmlFor={'name'}>{t(i18nArAttr('festival.name'))}*</label>
        <Text field="name" errors={this.state.errors.name}/>
      </Form.Field>
      <Form.Field>
        <label htmlFor={'location'}>{t(i18nArAttr('festival.location'))}</label>
        <Text field="location" errors={this.state.errors.location}/>
      </Form.Field>
      <Form.Field>
        <label htmlFor={'starts_at'}>{t(i18nArAttr('festival.starts_at'))}*</label>
        <DateTimePicker
          field="starts_at"
          errors={this.state.errors.starts_at}
          onChange={(v) => this.setState((prevState) => ({...prevState, starts_at: v}))}
        />
      </Form.Field>
      <Form.Field>
        <label htmlFor={'ends_at'}>{t(i18nArAttr('festival.ends_at'))}*</label>
        <DateTimePicker
          field="ends_at"
          errors={this.state.errors.ends_at}
          isValidDate={(current) => this.endsAtValid(current)}
          viewDate={this.state.starts_at}
        />
      </Form.Field>
      <Form.Field>
        <label htmlFor={'presale_active'}>{t(i18nArAttr('festival.presale_active'))}</label>
        <Checkbox checked={this.state.presale_active_checked}
                  onClick={() => {
                    this.setState({presale_active_checked: !this.state.presale_active_checked})
                  }} toggle field="presale_active"/>
      </Form.Field>
      <Form.Field>
        <label htmlFor={'presale_description'}>{t(i18nArAttr('festival.presale_description'))}</label>
        <TextArea field="presale_description" errors={this.state.errors.presale_description}/>
      </Form.Field>
      <SubmitButton loading={this.props.store.festivalStore.isLoading}/>
    </CustomForm>
  }
}

export default compose(
  withRouter,
  withTranslation('translation'),
  inject('store'),
  observer
)(FestivalForm);
