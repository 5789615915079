import * as React from 'react';
import {withTranslation} from 'react-i18next';
import {inject, observer} from "mobx-react";
import compose from "compose-function";
import {LabeledIconLinkButton} from "../shared/buttons";
import {i18nArAttr, i18nArModel} from "@semabit/rails-i18n-tools";
import {EntityTable} from "../shared/tables";
import {adminRoute} from "../../../routing/routing";
import FestivalPassCategoryListItem from "./listitem";
import {Select} from "semantic-ui-react";
import loadEntities from "../shared/loadEntities";

class FestivalPassCategoryList extends React.Component {

  state = {
    selectedFestivalId: undefined
  }

  componentDidMount() {
    const {store} = this.props;

    (async () => {
      if (store.festivalStore.current?.id) {
        await store.festivalPassCategoryStore.loadAll({
          params: {
            festival_id: store.festivalStore.current.id,
          }
        });

        this.setState({
          selectedFestivalId: store.festivalStore.current.id,
        })
      }
    })();
  }

  handleFestivalSelect(festivalId) {
    const {store} = this.props;

    (async () => {
      if (store.festivalStore.current?.id) {
        await store.festivalPassCategoryStore.loadAll({
          params: {
            festival_id: festivalId,
          }
        });

        this.setState({
          selectedFestivalId: festivalId
        })
      }
    })();
  }

  render() {
    const {t, store} = this.props;
    const headers = [
      t(i18nArAttr('festival_pass_category.name')),
      t(i18nArModel('festival.one')),
      t(i18nArAttr('festival_pass_category.starts_at')),
      t(i18nArAttr('festival_pass_category.ends_at')),
      ''
    ];

    const festivalOptions = store.festivalStore.festivals.map((festival) => ({
      key: festival.id,
      value: festival.id,
      text: festival.name,
    }));
    const selectedFestival = store.festivalStore.getById(this.state.selectedFestivalId);

    if (!selectedFestival) {
      return null;
    }

    const festivalPassCategories = store.festivalPassCategoryStore.festivalPassCategories
      .filter((festivalPassCategory) => festivalPassCategory.festival_id === selectedFestival.id);

    return (
      <div>
        <div style={{marginBottom: '40px'}}>
          <Select
            field={'selected_festival'}
            defaultValue={selectedFestival.id}
            placeholder={'Wählen Sie ein Festival aus'}
            options={festivalOptions}
            onChange={(e, {value}) => this.handleFestivalSelect(value)}
          />
        </div>
        <LabeledIconLinkButton
          text={t(i18nArModel('festival_pass_category.one'))}
          route={adminRoute(`/festival_pass_categories/create/`)}
        />
        <EntityTable headers={headers}>
          {festivalPassCategories.map((festivalPassCategory) =>
            <FestivalPassCategoryListItem
              festival={selectedFestival}
              key={festivalPassCategory.id}
              festivalPassCategory={festivalPassCategory}
            />
          )}
        </EntityTable>
      </div>
    )
  }
}

export default compose(
  loadEntities('festivalStore', 'festivals'),
  withTranslation('translation'),
  inject('store'),
  observer
)(FestivalPassCategoryList);
