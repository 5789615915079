import * as React from "react";
import {inject, observer} from "mobx-react";
import compose from "compose-function";
import {Message} from "semantic-ui-react";
import {withTranslation} from "react-i18next";

class FlashMessage extends React.Component {

  componentDidMount() {
    setTimeout(() => {
      this.props.store.messageStore.removeMessage(this.props.message);
    }, this.props.timeout || 2500);
  }

  render() {
    const {t, message} = this.props;
    const messageProps = {
      [message.type]: true
    };

    const interpolationParams = (message.options && message.options.params) ? message.options.params : {};
    return <Message {...messageProps} size='tiny'>
      <Message.Header>{t(message.title, {...interpolationParams})}</Message.Header>
      <p>{t(message.message, {...interpolationParams})}</p>
    </Message>
  }
}

export default compose(withTranslation('translation'), inject('store'), observer)(FlashMessage);