import * as React from "react";
import {Icon, Label, Popup} from "semantic-ui-react";
import {useTranslation} from "react-i18next";

const PresaleOrderMailState = ({
                                 state,
                                 error
                               }) => {

  const {t} = useTranslation();

  if (!state) {
    return null;
  }

  return (
    <>
      {state === 'sent' && (
        <Label size={'tiny'} color={'green'} style={{marginRight: '5px', whiteSpace: 'nowrap'}}>
          <Icon name={'check'}/>{t('mailing.sent')}
        </Label>
      )}
      {state === 'failed' && (
        <Popup
          content={error}
          trigger={(
            <Label size={'tiny'} color={'red'} style={{marginRight: '5px', whiteSpace: 'nowrap'}}
            >
              <Icon name={'times'}/>{t('mailing.failed')}
            </Label>
          )}
        />
      )}
    </>
  )
};

export default PresaleOrderMailState;
