import * as React from "react";
import compose from "compose-function";
import {withTranslation} from "react-i18next";

class NotFound extends React.Component {
  render() {
    return <h3>{this.props.t('errors.not_found')}</h3>
  }
}

export default compose(withTranslation('translation'))(NotFound);