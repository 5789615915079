import * as React from 'react';
import {Table} from "semantic-ui-react";
import {adminRoute} from "../../../routing/routing";
import {observer} from "mobx-react";
import {DetailsButton} from "../shared/buttons";
import {useStore} from "../../../hooks/useStore";

const CartDiscountListItem = ({cartDiscount, festivalId}) => {

  const store = useStore();
  const festival = store.festivalStore.getById(festivalId);

  if (!festival) {
    return null;
  }

  return (
    <Table.Row>
      <Table.Cell>{cartDiscount?.uuid}</Table.Cell>
      <Table.Cell>{cartDiscount?.label}</Table.Cell>
      <Table.Cell>{cartDiscount?.percentageFormatted}</Table.Cell>
      <Table.Cell collapsing>
        <DetailsButton route={adminRoute(`/festivals/${festival.id}/cart_discounts/${cartDiscount.id}`)}/>
      </Table.Cell>
    </Table.Row>
  )
}

export default observer(CartDiscountListItem);

