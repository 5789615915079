import * as React from 'react';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {observer} from "mobx-react";
import {adminRoute} from "../../../routing/routing";
import ReservationListItem from "./listItem";
import {LabeledIconLinkButton} from "../shared/buttons";
import {i18nArAttr, i18nArModel} from "@semabit/rails-i18n-tools";
import {EntityTable} from "../shared/tables";
import {useStore} from "../../../hooks/useStore";

const ReservationList = ({show}) => {
  const store = useStore();
  const {t} = useTranslation();
  const headers = [
    t(i18nArAttr('reservation.first_name')),
    t(i18nArAttr('reservation.last_name')),
    t(i18nArAttr('reservation.already_paid')),
    t(i18nArAttr('reservation.number_of_persons')),
    t(i18nArAttr('reservation.notes')),
    ''
  ];
  const reservations = store.reservationStore.reservations;

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (show?.id) {
      store.reservationStore.loadAll({params: {show_id: show.id}});
    }
  }, [show]);

  return (
    <div>
      <LabeledIconLinkButton
        text={t(i18nArModel('reservation.one'))}
        route={adminRoute(`/shows/${show.id}/reservations/create`)}
      />
      <EntityTable headers={headers}>
        {reservations.map((reservation) =>
          <ReservationListItem
            key={reservation.id}
            reservation={reservation}
            show={show}
          />
        )}
      </EntityTable>
    </div>
  );
}

export default observer(ReservationList);
