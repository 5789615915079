const config = (() => {
  const envString = `%cCIRQU REACT ENVIRONMENT: ${process.env?.REACT_APP_ENV?.toUpperCase()}`;
  const separator = `%c${''.padEnd(envString.length - 2, '-')}`;

  if (!process.env.REACT_APP_ENV) {
    return require('./config/environments/production');
  }

  if (process.env.REACT_APP_ENV === 'staging') {
    console.info(separator, 'color: blue');
    console.info(envString, 'color: blue');
    console.info(separator, 'color: blue');
    return require('./config/environments/staging');
  }

  if (process.env.REACT_APP_ENV === 'development') {
    console.info(separator, 'color: blue');
    console.info(envString, 'color: blue');
    console.info(separator, 'color: blue');
    return require('./config/environments/development');
  }
})();

export default config.default;
