import {BaseModel} from "./base";

export class CartTicket extends BaseModel {
  id = 0;
  quantity = 0;
  cart_id = 0;
  show_id = 0;
  price_id = 0;
  price = 0;
  price_formatted = '';
  total_price = 0;
  total_price_formatted = '';
  discount = 0;
  discount_formatted = '';
  festival_pass_id = 0;
  original_total_price= 0;
  original_total_price_formatted = '';


  static fromPlainObject(plainObject) {
    const cartTicket = new CartTicket();
    return Object.assign(cartTicket, plainObject);
  }
}
