import * as React from "react";
import {Header, Segment} from "semantic-ui-react";
import compose from "compose-function";
import {withTranslation} from "react-i18next";
import {loadEntities} from "../admin/shared/loadEntities";
import {inject, observer} from "mobx-react";
import ReservationsTabs from "./reservationsTabs";
import {LabeledIconLinkButton} from "../admin/shared/buttons";
import {staffRoute} from "../../routing/routing";

const ReservationsWidget = (props) => {

  const {t, reservations, show, store} = props;

  const paidReservations = reservations.filter(res => res.already_paid);
  const checkedInReservationsCount = paidReservations.filter(res => res.entered).length;

  const ReservationWidgetContent = store.configStore.isDesktop ? (
    <ReservationsTabs show={show} reservations={reservations.filter(r => r.already_paid)}/>
  ) : (
    <LabeledIconLinkButton primary={true} icon={'tasks'} route={staffRoute(`/entry/shows/${show.id}/reservations`)}
                           text={t('entry.reservations_widget.show_reservations')}/>
  );

  return (
    <Segment className={'reservations-widget'}>
      <Header as='h3' style={{textAlign: 'center'}}>{t('entry.reservations_widget.title')}</Header>
      <p className={'reservations-counter'}>{checkedInReservationsCount} / {paidReservations.length}</p>
      {ReservationWidgetContent}
    </Segment>
  )
};

export default compose(
  withTranslation('translation'),
  loadEntities('reservationStore', 'reservations', {
    storeParams: (props) => ({params: {show_id: props.show.id}}),
    autoUpdate: true
  }),
  inject('store'),
  observer
)(ReservationsWidget);
