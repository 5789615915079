import * as React from "react";
import {useState} from "react";
import {useStore} from "../../../hooks/useStore";
import {observer} from "mobx-react";
import CustomForm from "../../forms/form";
import Text from "../../forms/text";
import Select from "../../forms/select";
import {Form} from "semantic-ui-react";
import {SubmitButton} from "../shared/buttons";
import {useHistory} from "react-router";
import {useTranslation} from "react-i18next";
import {i18nArAttr} from "@semabit/rails-i18n-tools";
import {priceTypes} from "../../../models/price";
import {Relevant} from "informed";
import PriceInternalFormField from "./form_fields/price_internal_form_field";
import PriceFestivalPassCategoriesSelect from "./form_fields/price_festival_pass_categories_select";
import TicketRemarksRequired from "./form_fields/ticket_remarks_required_form_field";

const PriceForm = ({price = {}, show, redirectTo}) => {
  const store = useStore();
  const [errors, setErrors] = useState({});
  const history = useHistory();
  const {t} = useTranslation();

  const priceTypeOptions = priceTypes.map(priceType => {
    return {value: priceType, text: t(i18nArAttr(`price.price_types.${priceType}`))}
  });

  const errorCallback = (formErrors) => {
    setErrors(formErrors);
  }

  const successCallback = () => {
    setErrors({});
    if (history && redirectTo) {
      history.push(redirectTo);
    }
  }

  const handleOnSubmit = (values) => {
    const request = {
      ...values,
      id: price ? price.id : null,
      show_id: show.id,
    };

    if (price.id) {
      store.priceStore.update(request).then(successCallback).catch(errorCallback);
    } else {
      store.priceStore.create(request).then(successCallback).catch(errorCallback);
    }
  }

  return (
    <CustomForm onSubmit={handleOnSubmit} initialValues={{...price}}>
      <Form.Field>
        <label htmlFor={'price'}>{t(i18nArAttr('price.price'))}*</label>
        <Text type={'number'} field="price" errors={errors.price} min={0}/>
      </Form.Field>
      <Form.Field>
        <label htmlFor={'price_type'}>{t(i18nArAttr('price.price_type'))}*</label>
        <Select
          field={'price_type'}
          errors={errors.price_type}
          options={priceTypeOptions}
        />
      </Form.Field>
      <Relevant when={({values}) => values.price_type === 'festivalpass'}>
        <PriceFestivalPassCategoriesSelect errors={errors} show={show}/>
      </Relevant>
      <PriceInternalFormField/>
      <TicketRemarksRequired/>
      <SubmitButton loading={store.priceStore.isLoading}/>
    </CustomForm>
  );
}

export default observer(PriceForm);
