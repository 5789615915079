import * as React from 'react';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {observer} from "mobx-react";
import {adminRoute} from "../../../routing/routing";
import PriceListItem from "./listItem";
import {LabeledIconLinkButton} from "../shared/buttons";
import {i18nArAttr, i18nArModel} from "@semabit/rails-i18n-tools";
import {EntityTable} from "../shared/tables";
import {useStore} from "../../../hooks/useStore";
import {Loader} from "semantic-ui-react";

const PriceList = ({show}) => {

  const {t} = useTranslation();
  const store = useStore();

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (show) {
      store.priceStore.loadAll({params: {showId: show.id}});
    }
  }, [show]);

  const headers = [
    t(i18nArAttr('price.price')),
    t(i18nArAttr('price.price_type')),
    t(i18nArAttr('price.internal')),
    ''
  ];

  if (store.priceStore.isLoading) {
    return <Loader/>
  }

  return (
    <div>
      <LabeledIconLinkButton
        text={t(i18nArModel('price.one'))}
        route={adminRoute(`/shows/${show.id}/prices/create`)}
      />
      <EntityTable headers={headers}>
        {store.priceStore.prices.map((price) => <PriceListItem key={price.id} price={price} show={show}/>)}
      </EntityTable>
    </div>
  )
}

export default observer(PriceList);
