import {useFieldState, useFormApi} from "informed";
import {Form} from "semantic-ui-react";
import {i18nArAttr} from "@semabit/rails-i18n-tools";
import Checkbox from "../../../forms/checkbox";
import * as React from "react";
import {useEffect} from "react";
import {useTranslation} from "react-i18next";

const PriceInternalFormField = () => {

  const {t} = useTranslation();
  const {value: priceType} = useFieldState('price_type');
  const formApi = useFormApi();
  const isFestivalpass = priceType === 'festivalpass';

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (isFestivalpass) {
      formApi.setValue('internal', false);
    }
  }, [isFestivalpass])

  return (
    <Form.Field>
      <label htmlFor={'internal'}>{t(i18nArAttr('price.internal'))}*</label>
      <Checkbox
        toggle
        field={'internal'}
        disabled={isFestivalpass}
      />
    </Form.Field>
  );
}

export default PriceInternalFormField;
