import {computed, decorate, observable} from "mobx";
import {Api} from "../middleware/api";
import {EntityStore} from "./entityStore";
import {Festival} from "../models/festival";

decorate(Festival, {
  name: observable,
  location: observable,
  starts_at: observable,
  ends_at: observable,
  presale_active: observable,
  past: observable,
  presale_description: observable,
});

export class FestivalStore extends EntityStore {

  festivals = [];

  constructor(rootStore) {
    super(rootStore, 'festivals', Api.festivals, Festival);
  }

  get current() {
    return this.festivals.find((festival) => festival.current);
  }
}

decorate(FestivalStore, {
  festivals: observable,
  current: computed
});
