import * as React from "react";
import {useEffect, useState} from "react";
import {observer} from "mobx-react";
import {Dimmer, Form, Grid, Loader, Segment} from "semantic-ui-react";
import CheckoutSteps from "./checkoutSteps";
import {useStore} from "../../hooks/useStore";
import useCart from "../../hooks/useCart";
import {useHistory} from "react-router";

const CartContactForm = () => {

  const store = useStore();
  const {cart} = useCart();
  const history = useHistory();
  const [address, setAddress] = useState({
    firstname: '',
    lastname: '',
    street: '',
    house: '',
    zipcode: '',
    city: '',
    email: '',
    check: false
  });
  const {firstname, lastname, street, house, zipcode, city, email, check} = address;

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (cart?.address) {
      setAddress(cart.address);
    }
  }, [cart]);

  const handleChange = (e, {name, value}) => {
    setAddress({
      ...address,
      ...({[name]: value}),
    });
  };

  const handleCheckboxChange = (e, data) => {
    setAddress({
      ...address,
      check: data.checked
    });
  }

  const handleSubmit = () => {
    const entity = {
      ...address,
      cart_id: cart.id
    };
    if (cart && cart.address === null) {
      store.addressStore.create(entity).then(() => {
        store.cartStore.load(sessionStorage.getItem('cart')).then(() => history.push('/checkout/confirmation'))
      })
    } else {
      store.addressStore.update(entity).then(() => {
        store.cartStore.load(sessionStorage.getItem('cart')).then(() => history.push('/checkout/confirmation'))
      })
    }
  }

  return (
    <>
      <CheckoutSteps active={'address'}/>
      <Segment>
        {!cart ?
          <Dimmer active inverted>
            <Loader inverted content='Einen Moment bitte'/>
          </Dimmer>
          :
          <Grid columns={'equal'}>
            <Grid.Row>
              <Grid.Column>
                <Form
                  onSubmit={handleSubmit}
                >
                  <Form.Group>
                    <Form.Input
                      fluid
                      required
                      label={'Vorname'}
                      placeholder={'Vorname'}
                      name={'firstname'}
                      value={firstname}
                      width={8}
                      onChange={handleChange}
                    />
                    <Form.Input
                      fluid
                      required
                      label={'Nachname'}
                      placeholder={'Nachname'}
                      name={'lastname'}
                      value={lastname}
                      width={8}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Input
                      fluid
                      required
                      label={'Strasse'}
                      placeholder={'Strasse'}
                      width={12}
                      name={'street'}
                      value={street}
                      onChange={handleChange}
                    />
                    <Form.Input
                      fluid
                      required
                      label={'Hausnummer'}
                      placeholder={'Haus Nr.'}
                      name={'house'}
                      value={house}
                      width={4}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Input
                      fluid
                      required
                      label={'PLZ'}
                      placeholder={'PLZ'}
                      width={4}
                      name={'zipcode'}
                      value={zipcode}
                      onChange={handleChange}
                    />
                    <Form.Input
                      required
                      label={'Ort'}
                      placeholder={'Ort'}
                      width={12}
                      name={'city'}
                      value={city}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Input
                      required
                      label={'Email'}
                      placeholder={'joe@schmoe.com'}
                      width={16}
                      name={'email'}
                      value={email}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Checkbox
                      required
                      style={{marginTop: 20}}
                      width={16}
                      name={'check'}
                      onChange={handleCheckboxChange}
                      label={{
                        children:
                          <>
                            Hiermit akzeptiere ich die <a target={'_blank'} style={{textDecoration: 'underline'}}
                                                          rel={'noreferrer noopener'} href={'/agb'}>AGB</a> von Cirqu
                          </>
                      }}
                    >
                    </Form.Checkbox>
                  </Form.Group>
                  <Form.Button
                    disabled={!check}
                    floated={'right'}
                    primary
                  >
                    Weiter
                  </Form.Button>
                </Form>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        }
      </Segment>
    </>
  )
}

export default observer(CartContactForm);
