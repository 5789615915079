import * as React from "react";
import {Button, Form, Header, Icon, Item} from "semantic-ui-react";
import {i18nArAttr} from "@semabit/rails-i18n-tools";
import compose from "compose-function";
import {withTranslation} from "react-i18next";
import {inject, observer} from "mobx-react";

class TicketCartItem extends React.Component {

  render() {
    if (this.props.tickets.length === 0) return <React.Fragment/>;

    const price = this.props.store.priceStore.getById(this.props.tickets[0].price_id);
    const reservation = this.props.store.reservationStore.getById(this.props.tickets[0].reservation_id);

    const priceTypeOptions = this.props.prices.map(price => {
      return {value: price.price_type, text: this.props.t(i18nArAttr(`price.price_types.${price.price_type}`))}
    });

    return <Item className={'cart-item'}>
      <Icon size='huge' name={'ticket'}/>
      <Item.Content verticalAlign='middle'>
        <Item.Header>
          <Form.Select
            field="price_type"
            options={priceTypeOptions}
            value={price ? price.price_type : null}
            onChange={(e, {value}) => this.updateTicketPrice(value)}
          />
        </Item.Header>
        <Item.Description>{reservation ? 'Reservation: ' + reservation.fullName() : ''}</Item.Description>
      </Item.Content>
      <Item.Extra>
        <Header as={'h3'}>{this.props.tickets.length} Stk.</Header>
        {reservation ? '' :
          <React.Fragment>
            <Button className={'remove-ticket'} size={'tiny'} icon={'minus'}
                    onClick={this.decrementItemsClicked.bind(this)}/>
            <Button className={'add-ticket'} size={'tiny'} icon={'plus'}
                    onClick={this.incrementItemsClicked.bind(this)}/>
          </React.Fragment>}
        <Button className={'remove-tickets red'} size={'tiny'} icon={'close'}
                onClick={this.removeTicketsClicked.bind(this)}/>
      </Item.Extra>
    </Item>;
  }

  incrementItemsClicked() {
    if (this.props.onIncrement) {
      this.props.onIncrement(this.props.tickets);
    }
  }

  decrementItemsClicked() {
    if (this.props.onDecrement) {
      this.props.onDecrement(this.props.tickets);
    }
  }

  removeTicketsClicked() {
    if (this.props.onRemove) {
      this.props.onRemove(this.props.tickets);
    }
  }

  updateTicketPrice(priceType) {
    const price = this.props.prices.find(p => p.price_type === priceType);
    if (this.props.onPriceChange && price) {
      this.props.onPriceChange(this.props.tickets, price);
    }
  }
}

export default compose(
  withTranslation('translation'),
  inject('store'),
  observer
)(TicketCartItem);