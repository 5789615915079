import {decorate, observable} from "mobx";
import {Api} from "../middleware/api";
import {EntityStore} from "./entityStore";
import {Cart} from "../models/cart";

decorate(Cart, {
  number: observable,
  expire_date: observable,
  cart_tickets: observable,
  address: observable,
  payment_required: observable,
});

export class CartStore extends EntityStore {
  carts = [];

  constructor(rootStore) {
    super(rootStore, 'carts', Api.carts, Cart);
  }

  getByNumber(number) {
    if (!number) {
      return null;
    }
    return this[this.listName].find((cart) => cart.number === number);
  }
}

decorate(CartStore, {
  carts: observable,
});
