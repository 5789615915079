import {BaseModel} from "./base";

export class Entry extends BaseModel {
  id = 0;
  show_id = 0;
  user_id = 0;
  ticket_id = 0;
  festival_pass_id = 0;
  user = null;
  quantity = 0;
  entry_type = '';
  ticket_number = '';
  festival_pass_number = '';

  static fromPlainObject(plainObject) {
    const entry = new Entry();
    return Object.assign(entry, plainObject);
  }
}

export const EntryTypes = {
  festivalPass: "festival_pass",
  reservation: "reservation",
  eveningSale: "evening_sale",
};
