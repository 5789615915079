import {createEntity, executeDelete, executeGet, updateEntity} from "./base";

export const resourcesName = 'prices';
export const resourceName = 'price';

const pathPrefix = (showId) => `shows/${showId}`;

export const PricesApi = {
  all: ({showId, ...params}) => executeGet(pathPrefix(showId) + `/${resourcesName}`, {params}),
  byId: (id, {showId}) => executeGet(pathPrefix(showId) + `/${resourcesName}/${id}`),
  create: (entity) => createEntity(entity, pathPrefix(entity.show_id) + `/${resourcesName}`, resourceName),
  update: (entity) => updateEntity(entity, pathPrefix(entity.show_id) + `/${resourcesName}/${entity.id}`, resourceName),
  destroy: (id, {showId}) => executeDelete(pathPrefix(showId) + `/${resourcesName}/${id}`)
};
