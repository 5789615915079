import * as React from "react";
import compose from "compose-function";
import {withRouter} from "react-router";
import {inject, observer} from "mobx-react";
import {Loader} from "semantic-ui-react";
import {withTranslation} from "react-i18next";
import {i18nArModel, toSnakeCase} from "@semabit/rails-i18n-tools";

export const loadEntity = (storeName, propertyName, fallbackPath, options = {}) => {
  return (WrappedComponent) => {
    class LoadEntityHOC extends React.Component {

      componentDidUpdate(prevProps, prevState, snapshot) {
        const newId = this.getIdFromProps(this.props);
        const currentId = this.getIdFromProps(prevProps);
        if (newId !== currentId) {
          this.loadEntity(newId);
        }
      }

      componentDidMount() {
        const id = this.getIdFromProps(this.props);
        if (options.lazyLoad && this.getEntityFromStore(this.props)) {
          return;
        }

        this.loadEntity(id);
      }

      loadEntity(id) {
        this.props.store[storeName].load(id, {params: this.props.match.params}).catch(e => {
          console.error(`${propertyName} with id ${id} not found`);
          this.props.store.messageStore.addMessage({
            type: 'warning',
            title: 'messages.not_found',
            options: {params: {model: this.props.t(i18nArModel(`${toSnakeCase(propertyName)}.one`))}}
          });
          this.props.history.push(this.redirectPath());
        });
      }

      render() {
        const showLoader = options.hasOwnProperty('showLoader') ? options.showLoader : true;
        const entity = this.getEntityFromStore(this.props);

        if (!entity && showLoader) {
          return <Loader active inline className={'centered'}/>
        }

        const props = {
          [propertyName]: entity,
          ...this.props
        };

        return <WrappedComponent {...props}/>
      }

      getIdFromProps(props) {
        if (options.identifier) {
          return options.identifier(props);
        }
        const paramName = options.paramName ? options.paramName : 'id';
        return props.match.params[paramName];
      }

      redirectPath() {
        if (typeof fallbackPath === 'string') {
          return fallbackPath;
        }
        return fallbackPath(this.props);
      }

      getEntityFromStore(props) {
        return props.store[storeName].getById(this.getIdFromProps(props)) || null;
      }
    }

    LoadEntityHOC.displayName = `LoadEntity(${WrappedComponent.displayName})`;

    return compose(withTranslation('translation'), withRouter, inject('store'), observer)(LoadEntityHOC);
  }
};

export default loadEntity;
