import * as React from 'react';
import {withTranslation} from "react-i18next";
import {Table} from "semantic-ui-react";
import compose from "compose-function";
import {adminRoute} from "../../../routing/routing";
import {inject, observer} from "mobx-react";
import {DetailsButton} from "../shared/buttons";
import moment from "moment";

class FestivalPassCategoryListItem extends React.Component {

  render() {
    const {festivalPassCategory, festival} = this.props;

    if (!festival || !festivalPassCategory.festival) return <></>;
    const isExpired = festival.past;

    return (
      <Table.Row className={isExpired ? 'inactive' : ''}>
        <Table.Cell>{festivalPassCategory.name}</Table.Cell>
        <Table.Cell>{festivalPassCategory.festival.name}</Table.Cell>
        <Table.Cell>{festivalPassCategory.starts_at ? moment(festivalPassCategory.starts_at).format("DD.MM.YYYY") : '-'}</Table.Cell>
        <Table.Cell>{festivalPassCategory.ends_at ? moment(festivalPassCategory.ends_at).format("DD.MM.YYYY") : '-'}</Table.Cell>
        <Table.Cell collapsing>
          <DetailsButton route={adminRoute(`/festival_pass_categories/${festivalPassCategory.id}`)}/>
        </Table.Cell>
      </Table.Row>
    )
  }
}

export default compose(
  withTranslation('translation'),
  inject('store'),
  observer
)(FestivalPassCategoryListItem);
