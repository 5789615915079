import * as React from "react";
import {
  Route,
  Switch
} from 'react-router-dom'
import NotFound from "../../notFound";
import New from "./new"
import Detail from "./detail"
import Edit from "./edit"

class Routes extends React.Component {
  render() {
    const match = this.props.match;
    return (
      <Switch>
        <Route exact path={`${match.path}/create`} component={New}/>
        <Route exact path={`${match.path}/:id/edit`} component={Edit}/>
        <Route exact path={`${match.path}/:id`} component={Detail}/>
        <Route path={`${match.path}/*`} component={NotFound}/>
      </Switch>
    )
  }
}

export default Routes;
