import {createEntity, executeDelete, executeGet, updateEntity} from "./base";

export const resourcesName = 'cart_discounts';
export const resourceName = 'cart_discount';

const pathPrefix = (festivalId) => `festivals/${festivalId}`;
export const CartDiscountsApi = {
  all: ({festivalId, ...params}) => executeGet(pathPrefix(festivalId) + `/${resourcesName}`, {params}),
  byId: (id, {festivalId}) => executeGet(pathPrefix(festivalId) + `/${resourcesName}/${id}`),
  create: (entity) => createEntity(entity, pathPrefix(entity.festival_id) + `/${resourcesName}`, resourceName),
  update: (entity) => updateEntity(entity, pathPrefix(entity.festival_id) + `/${resourcesName}/${entity.id}`, resourceName),
  destroy: (id, {festivalId}) => executeDelete(pathPrefix(festivalId) + `/${resourcesName}/${id}`)
};
