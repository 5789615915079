import {createEntity, executeDelete, executeGet, updateEntity} from "./base";

export const resourcesName = 'festival_passes';
export const resourceName = 'festivalPass';

const pathPrefix = (festivalId) => `festivals/${festivalId}`;

export const festivalPassResourcePath = (festivalId, path = '') => {
  return pathPrefix(festivalId) + `/${resourcesName}` + path;
};

export const FestivalPassesApi = {
  all: ({festivalId}) => executeGet(pathPrefix(festivalId) + `/${resourcesName}`),
  byId: (id, {festivalId}) => executeGet(pathPrefix(festivalId) + `/${resourcesName}/${id}`),
  create: (entity) => createEntity(entity, pathPrefix(entity.festival_id) + `/${resourcesName}`, resourceName),
  update: (entity) => updateEntity(entity, pathPrefix(entity.festival_id) + `/${resourcesName}/${entity.id}`, resourceName),
  destroy: (id, {festivalId}) => executeDelete(pathPrefix(festivalId) + `/${resourcesName}/${id}`)
};
