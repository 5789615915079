import * as React from 'react';
import {withTranslation} from "react-i18next";
import {inject, observer} from "mobx-react";
import compose from "compose-function";
import {EntityDetailTable, EntityDetailTableEntry} from "../shared/tables";
import {i18nArAttr, i18nArModel, i18nBool} from "@semabit/rails-i18n-tools";
import {adminRoute} from "../../../routing/routing";
import {DeleteButton, EditButton} from "../shared/buttons";
import {withRouter} from "react-router";
import {confirmDelete} from "../shared/confirm";
import {EntityDetailLayout} from "../shared/entityDetailLayout";
import {loadEntity} from "../shared/loadEntity";
import {Tab} from "semantic-ui-react";
import FestivalPassList from "../festival_pass/list";
import loadEntities from "../shared/loadEntities";
import moment from "moment";
import PresaleOrderList from "./partials/presale_order_list";
import CartDiscountList from "../cart_discount/list";

class FestivalDetail extends React.Component {

  render() {
    const {t, shows} = this.props;

    return (
      <EntityDetailLayout title={t(i18nArModel('festival.one'))} backPath={`/festivals`}>
        <EditButton route={adminRoute(`/festivals/${this.props.festival.id}/edit`)}/>
        <DeleteButton onClick={() => this.delete()}/>
        <EntityDetailTable>
          <EntityDetailTableEntry name={t(i18nArAttr('festival.name'))}>
            <b>{this.props.festival.name}</b>
          </EntityDetailTableEntry>
          <EntityDetailTableEntry name={t(i18nArAttr('festival.location'))}>
            {this.props.festival.location}
          </EntityDetailTableEntry>
          <EntityDetailTableEntry name={t(i18nArAttr('festival.starts_at'))}>
            {this.props.festival.starts_at ? moment(this.props.festival.starts_at).format() : '-'}
          </EntityDetailTableEntry>
          <EntityDetailTableEntry name={t(i18nArAttr('festival.ends_at'))}>
            {this.props.festival.ends_at ? moment(this.props.festival.ends_at).format() : '-'}
          </EntityDetailTableEntry>
          <EntityDetailTableEntry name={t(i18nArAttr('festival.presale_active'))}>
            {t(i18nBool(this.props.festival.presale_active))}
          </EntityDetailTableEntry>
        </EntityDetailTable>
        <Tab
          panes={[
            {
              menuItem: t(i18nArModel('festival_pass.other')),
              render: () =>
                <Tab.Pane>
                  <FestivalPassList festival={this.props.festival}/>
                </Tab.Pane>
            },
            {
              menuItem: t(i18nArModel('presale_order.other')),
              render: () =>
                <Tab.Pane>
                  <PresaleOrderList festival={this.props.festival} shows={shows}/>
                </Tab.Pane>
            },
            {
              menuItem: t(i18nArModel('cart_discount.other')),
              render: () =>
                <Tab.Pane>
                  <CartDiscountList festivalId={this.props.festival?.id}/>
                </Tab.Pane>
            },
          ]}
        />
      </EntityDetailLayout>
    )
  }

  delete() {
    if (confirmDelete(this.props.t, 'festival')) {
      this.props.store.festivalStore.delete(this.props.festival.id).then(() => {
        this.props.history.push(adminRoute('/festivals'));
      });
    }
  }

  errorCallback(formErrors) {
    console.error({formErrors});
    this.setState({errors: formErrors});
  }
}

export default compose(
  loadEntity('festivalStore', 'festival', adminRoute('/festivals')),
  loadEntities('showStore', 'shows', {
    storeParams: () => ({params: {current_festival: true}})
  }),
  withRouter,
  withTranslation('translation'),
  inject('store'),
  observer
)(FestivalDetail);
