import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom/client';
import * as serviceWorker from './serviceWorker';
import Root from "./root";
import './config/moment';

import 'semantic-ui-less/semantic.less'
import './assets/stylesheets/app.scss'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Root/>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
