import * as React from 'react';
import {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Button, Confirm, Label, Loader, Popup, Table} from "semantic-ui-react";
import {observer} from "mobx-react";
import {i18nArAttr, i18nArModel} from "@semabit/rails-i18n-tools";
import moment from "moment";
import ReservationLink from "../reservations/link";
import {CancelButton} from "../shared/buttons";
import {TicketStates} from "../../../models/ticket";
import PresaleOrderLink from "../presale_orders/link";
import {useStore} from "../../../hooks/useStore";

const TicketListItem = ({
                          onShow = () => undefined,
                          onDownload = () => undefined,
                          ticket,
                          show,
                          prices = [],
                          reservations = []
                        }) => {
  const [showConfirmCancel, setShowConfirmCancel] = useState(false);
  const [isLoadingSalesPoint, setIsLoadingSalesPoint] = useState(true);
  const [salesPoint, setSalesPoint] = useState(undefined);
  const store = useStore();
  const {t} = useTranslation();

  const price = ticket.price_id ? prices.find(p => p.id.toString() === ticket.price_id.toString()) : null;
  const reservation = ticket.reservation_id ? reservations.find(r => r.id.toString() === ticket.reservation_id.toString()) : null;
  const presale = ticket.presale_order_id !== null && ticket.presale_order_id !== 0

  useEffect(() => {
    (async () => {
      if (ticket.receipt_id && show.id) {
        try {
          const receipt = await store.receiptStore.load(ticket.receipt_id, {params: {showId: show.id}});
          if (receipt.sales_point_id) {
            const salesPoint = await store.salesPointStore.load(receipt.sales_point_id);
            setSalesPoint(salesPoint);
          }
        } finally {
          setIsLoadingSalesPoint(false);
        }
      }
    })();
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);

  const hideConfirmCancel = () => {
    setShowConfirmCancel(false);
  }

  const cancelTicket = () => {
    store.ticketStore.update({...ticket, state: TicketStates.canceled})
      .then(() => {
        store.showStore.load(show.id);
        hideConfirmCancel();
      });
  }

  const showClicked = () => {
    onShow(ticket);
  }

  const downloadClicked = () => {
    onDownload(ticket);
  }

  const SalesPoint = () => {
    if (isLoadingSalesPoint) {
      return <Loader active={true} size={'mini'}/>
    }

    if (!salesPoint) {
      return null;
    }

    return <Label style={{marginLeft: 10}} color={'grey'} horizontal>
      {t(i18nArModel('sales_point.one'))}
      <Label.Detail>{salesPoint.name}</Label.Detail>
    </Label>;
  }

  return (
    <Table.Row>
      <Table.Cell>
        {ticket.number}
        {presale
          ? <Label style={{marginLeft: 10}} color={'yellow'}
                   horizontal>{t(i18nArAttr('ticket.presale'))}</Label>
          : null
        }
        {reservation
          ? <Label style={{marginLeft: 10}} color={'blue'}
                   horizontal>{t(i18nArModel('reservation.one'))}</Label>
          : null
        }
        <SalesPoint/>
      </Table.Cell>
      <Table.Cell>
        {ticket.isCanceled
          ? <Label color={'red'} horizontal>{t(i18nArAttr('ticket.canceled'))}</Label>
          : null
        }
        {ticket.isEntered
          ? <Label color={'blue'} horizontal>{t(i18nArAttr('ticket.entered'))}</Label>
          : null
        }
        {ticket.isOpen
          ? <Label color={'teal'} horizontal>{t(i18nArAttr('ticket.active'))}</Label>
          : null
        }
      </Table.Cell>
      <Table.Cell>{moment(ticket.created_at).format()}</Table.Cell>
      <Table.Cell>{price ? t(i18nArAttr(`price.price_types.${price.price_type}`)) : '-'}</Table.Cell>
      <Table.Cell>{ticket.formatPrice()}</Table.Cell>
      <Table.Cell>
        <dl>
          {ticket.remarks && price && (
            <>
              <dt>{t(i18nArAttr(`price.ticket_remark_label.${price?.price_type}`))}</dt>
              <dd>{ticket.remarks}</dd>
            </>
          )}
        </dl>
      </Table.Cell>
      <Table.Cell>
        {reservation ?
          <ReservationLink show={show} reservation={reservation}/>
          : ''
        }
        {presale ?
          <PresaleOrderLink presaleOrderId={ticket.presale_order_id}/>
          : ''
        }
      </Table.Cell>
      <Table.Cell>
        <CancelButton
          className={ticket.isCanceled ? 'disabled' : ''}
          disabled={ticket.isCanceled}
          onClick={() => setShowConfirmCancel(true)}
        />
        <Confirm
          open={showConfirmCancel}
          onCancel={hideConfirmCancel}
          onConfirm={cancelTicket}
          content={t('admin.entity.confirm_cancel', {model_name: t(i18nArModel(`ticket.one`))})}
        />
        <Popup
          content={`${t(i18nArModel('presale_order.one'))} ${t(i18nArModel('ticket.one'))} öffnen`}
          trigger={
            <Button icon={'file pdf'} onClick={showClicked}/>
          }/>
        <Popup
          content={`${t(i18nArModel('presale_order.one'))} ${t(i18nArModel('ticket.one'))} herunterladen`}
          trigger={
            <Button icon={'download'} onClick={downloadClicked}/>
          }/>
      </Table.Cell>
    </Table.Row>
  )
}

export default observer(TicketListItem);
