import * as React from 'react'
import {withRouter} from "react-router";
import {inject, observer} from "mobx-react";
import {Route, Switch} from "react-router-dom";
import NotFound from "./components/notFound";
import Dashboard from "./components/dashboard/dashboard";
import compose from "compose-function";
import AppLayout from "./components/layout/layout";
import Admin from "./components/admin/admin";
import PointOfSale from "./components/point-of-sale/pos";
import Entry from "./components/entry/routes";
import ProtectedRoute from "./components/admin/protectedRoute";
import PrivateRoute from "./components/privateRoute";
import {routerPath} from "./routing/routing";

class Staff extends React.Component {

  render() {
    let path = routerPath(this.props.match);

    return (
      <AppLayout>
        <Switch>
          <Route exact path={`${this.props.match.url}`} component={Dashboard}/>
          <ProtectedRoute path={`${path}/admin`} component={Admin}/>
          <PrivateRoute path={`${path}/pos`} component={PointOfSale}/>
          <PrivateRoute path={`${path}/entry`} component={Entry}/>
          <Route path={`*`} component={NotFound}/>
        </Switch>
      </AppLayout>
    )
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.resetScrollPosition();
    }
  }

  resetScrollPosition() {
    try {
      window.scrollTo(0, 0);
      const appContainer = document.querySelector('.app-container');
      if (appContainer) appContainer.scrollTo(0, 0);
    } catch (e) {
    }
  }
}

export default compose(
  withRouter,
  inject('store'),
  observer
)(Staff);
