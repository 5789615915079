import {Button, Table} from "semantic-ui-react";
import {i18nArAttr} from "@semabit/rails-i18n-tools";
import FestivalPassValidationFormFields from "./festivalPassValidationFormFields";
import {useTranslation} from "react-i18next";
import {useEffect} from "react";
import {useFormApi} from 'informed';

const FestivalPassTableCells = ({price, show, festivalpassFormRef}) => {
  const {t} = useTranslation();
  const formApi = useFormApi();

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (festivalpassFormRef) {
      festivalpassFormRef.current = formApi;
    }
  }, [festivalpassFormRef])

  return (
    <Table.Row key={price.id}>
      <Table.Cell>
        {t(i18nArAttr(`price.price_types.${price.price_type}`))}
        <div className={'small'}>
          {t('components.presale.show_details.prices.add_festivalpass_number')}
        </div>
      </Table.Cell>
      <Table.Cell textAlign={'right'}>
        <FestivalPassValidationFormFields/>
      </Table.Cell>
      <Table.Cell textAlign={'right'}>
        <Button
          disabled={show.available_presale_tickets_count <= 0}
          primary
        >
          {t('components.presale.show_details.prices.add_to_cart')}
        </Button>
      </Table.Cell>
    </Table.Row>
  )
}

export default FestivalPassTableCells;
