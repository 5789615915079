import {useEffect, useState} from "react";
import {useStore} from "./useStore";

const useCart = () => {
  const store = useStore();
  const sessionCartNumber = sessionStorage.getItem('cart');
  const [cart, setCart] = useState();
  const [errors, setErrors] = useState();

  const initCart = async () => {
    const storeCart = store.cartStore.carts.find((cart) => cart.number === sessionCartNumber);

    if (storeCart) {
      setCart(storeCart);
    } else if (!storeCart && sessionCartNumber && !store.cartStore.isLoading) {
      const cart = await store.cartStore.load(sessionCartNumber);
      setCart(cart);
    } else if (!storeCart && !sessionCartNumber && !store.cartStore.isLoading) {
      try {
        const initializedCart = await store.cartStore.create({cart: null});
        sessionStorage.setItem('cart', initializedCart.number);
        setCart(initializedCart);
      } catch (e) {
        setErrors(e);
      }
    }
  }

  const reload = async () => {
    if (sessionCartNumber && !store.cartStore.isLoading) {
      const cart = await store.cartStore.load(sessionCartNumber);
      setCart(cart);
    }
  }

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    initCart();
  }, [store.cartStore.isLoading]);

  return {cart, errors, reload}
}

export default useCart;
