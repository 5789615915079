import {BaseModel} from "./base";

export const SalesPointSettings = {
  print_mode: {
    receipt: 'receipt',
    presale_order: 'presale_order'
  }
}

export class SalesPoint extends BaseModel {
  id = 0;
  name = '';
  location = '';
  settings = {};

  static fromPlainObject(plainObject) {
    const salesPoint = new SalesPoint();
    return Object.assign(salesPoint, plainObject);
  }
}
