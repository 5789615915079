import * as React from "react";
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom'
import {Provider} from "mobx-react";
import {rootStoreInstance} from "./stores/rootStore";
import Staff from './staff';
import NotFound from "./components/notFound";
import PrivateRoute from "./components/privateRoute";
import Login from "./components/auth/login";
import i18n from "./config/i18n";
import {I18nextProvider} from "react-i18next";
import App from "./app";
import ConfigProvider from "./components/shared/ConfigProvider";

const Root = () => {

  return (
    <Provider store={rootStoreInstance}>
      <I18nextProvider i18n={i18n}>
        <React.Fragment>
          <ConfigProvider/>
          <Router>
            <Switch>
              <Redirect from={'/index.html'} to={{
                pathname: '/',
                search: window.location.search
              }}/>
              <Route path={`/login`} component={Login}/>
              <PrivateRoute path={`/staff`} component={Staff}/>
              <Route path={`/`} component={App}/>
              <Route path='*' component={NotFound}/>
            </Switch>
          </Router>
        </React.Fragment>
      </I18nextProvider>
    </Provider>
  )
}

export default Root;
