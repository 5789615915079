import {decorate, observable, runInAction} from "mobx";
import {Api} from "../middleware/api";
import {EntityStore} from "./entityStore";
import {Environment} from "../models/environment";

export class EnvironmentStore extends EntityStore {

  environment = {};

  constructor(rootStore) {
    super(rootStore, 'environments', Api.environments, Environment);
  }

  show() {
    this.pendingRequests++;
    return this.api.show().then((entity) => {
      entity = this.modelClass.fromPlainObject(entity);
      runInAction(() => {
          this.environment = entity;
          this.pendingRequests--;
        }
      );
      return entity;
    }).catch(e => this.handleApiError(e));
  }
}

decorate(EnvironmentStore, {
  environment: observable
});
