import {Button, Table} from "semantic-ui-react";
import {i18nArAttr, i18nArModel} from "@semabit/rails-i18n-tools";
import {useTranslation} from "react-i18next";
import RemarksForm from "./remarksForm";
import {useEffect, useState} from "react";
import {useStore} from "../../../hooks/useStore";
import {observer} from "mobx-react";
import useCartDiscount from "../../../hooks/useCartDiscount";
import DiscountedPrice from "../../shared/discountedPrice";

const DefaultTableCells = ({
                             price,
                             show,
                             handleAdd,
                             handleMinus,
                             priceCounters,
                             totalCounter,
                             addTicketsToCart,
                             cart,
                             remarksFormRefs
                           }) => {
  const {t} = useTranslation();
  const store = useStore();
  const [preview, setPreview] = useState();
  const hasDiscount = !!store.cartDiscountStore.currentCartDiscountUuid && (preview?.total_price_formatted !== preview?.price_formatted);

  useCartDiscount({festivalId: show?.festival_id});

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (cart && show && price) {
      (async () => {
        const preview = await store.cartTicketStore.preview({
          params: {
            cart_number: cart.number,
            cart_id: cart.id,
            show_id: show.id,
            price_id: price.id,
            cart_discount_uuid: !!store.cartDiscountStore.currentCartDiscountUuid ? store.cartDiscountStore.currentCartDiscountUuid : undefined,
            quantity: 1
          }
        })
        setPreview(preview);
      })();
    }
  }, [cart, show, price])

  return (
    <Table.Row key={price.id}>
      <Table.Cell>
        {t(i18nArAttr(`price.price_types.${price.price_type}`))}
      </Table.Cell>
      <Table.Cell>
        {hasDiscount && preview && (
          <DiscountedPrice
            value={preview.total_price_formatted}
            insteadOfValue={preview.price_formatted}
            discountLabel={store.cartDiscountStore.currentDiscount?.fullLabel}
          />
        )}
        {!hasDiscount && preview && (
          preview.price_formatted
        )}
      </Table.Cell>
      <Table.Cell textAlign={'right'}>
        <div className={'flex-column-gap'} style={{'--max-width': '220px'}}>
          <div className={'flex-spaced-row'}>
            <Button
              icon='minus'
              onClick={() => handleMinus(price.id)}
            />
            {priceCounters[price.id]}&nbsp;
            {priceCounters[price.id] === 1 ? t(i18nArModel(`ticket.one`)) : t(i18nArModel(`ticket.other`))}
            <Button
              icon='plus'
              disabled={totalCounter >= show.available_presale_tickets_count}
              onClick={() => handleAdd(price.id)}
            />
          </div>
          {price.require_ticket_remarks && (
            <div>
              <RemarksForm
                remarksFormRefs={remarksFormRefs}
                placeholder={t(i18nArAttr(`price.ticket_remark_label.${price.price_type}`))}
                field={price.price_type}
              />
            </div>
          )}
        </div>
      </Table.Cell>
      <Table.Cell textAlign={'right'}>
        <Button
          onClick={() => addTicketsToCart(cart, price, priceCounters[price.id])}
          disabled={show.available_presale_tickets_count <= 0}
          primary
        >
          {t('components.presale.show_details.prices.add_to_cart')}
        </Button>
      </Table.Cell>
    </Table.Row>
  )
}

export default observer(DefaultTableCells);
