import * as React from 'react';
import {UserStore} from "./userStore";
import {ConfigStore} from "./configStore";
import {AuthStore} from "./authStore";
import {ShowStore} from "./showStore";
import {PriceStore} from "./priceStore";
import {SalesPointStore} from "./salesPointStore";
import {MessageStore} from "./messageStore";
import {ReservationStore} from "./reservationStore";
import {TicketStore} from "./ticketStore";
import {ReceiptStore} from "./receiptStore";
import {EntryStore} from "./entryStore";
import {FestivalStore} from "./festivalStore";
import {FestivalPassCategoryStore} from "./festivalPassCategoryStore";
import {FestivalPassStore} from "./festivalPassStore";
import {CartStore} from "./cartStore";
import {CartTicketStore} from "./cartTicketStore";
import {AddressStore} from "./addressStore";
import {PresaleOrderStore} from "./presaleOrderStore";
import {EnvironmentStore} from "./environmentStore";
import {CartDiscountStore} from "./cartDiscountStore";

export class RootStore {
  constructor() {
    this.userStore = new UserStore(this);
    this.festivalStore = new FestivalStore(this);
    this.festivalPassCategoryStore = new FestivalPassCategoryStore(this);
    this.showStore = new ShowStore(this);
    this.priceStore = new PriceStore(this);
    this.reservationStore = new ReservationStore(this);
    this.salesPointStore = new SalesPointStore(this);
    this.configStore = new ConfigStore(this);
    this.authStore = new AuthStore(this);
    this.messageStore = new MessageStore(this);
    this.ticketStore = new TicketStore(this);
    this.receiptStore = new ReceiptStore(this);
    this.entryStore = new EntryStore(this);
    this.festivalPassStore = new FestivalPassStore(this);
    this.cartStore = new CartStore(this);
    this.cartTicketStore = new CartTicketStore(this);
    this.addressStore = new AddressStore(this);
    this.presaleOrderStore = new PresaleOrderStore(this);
    this.environmentStore = new EnvironmentStore(this)
    this.cartDiscountStore = new CartDiscountStore(this)
  }
}

export const rootStoreInstance = new RootStore();
export const StoreContext = React.createContext(rootStoreInstance);
