import {useEffect} from "react";

const useFacebookPixel = () => {

  const facebookPixelId = '605004014999068';
  const template = document.createElement('div');
  template.innerHTML = `
<!-- Facebook Pixel Code -->
<script>
  !function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '${facebookPixelId}');
  fbq('track', 'PageView');
</script>
<noscript>
  <img height="1" width="1" style="display:none"
       src="https://www.facebook.com/tr?id=${facebookPixelId}&ev=PageView&noscript=1"/>
</noscript>
<!-- End Facebook Pixel Code -->
  `.trim();

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const found = document.head.querySelector('meta[name="facebook-pixel"]');
    // run in production only
    if (!found && !process.env.REACT_APP_ENV) {
      const added = document.createElement('META');
      added.name = 'facebook-pixel';

      document.head.append(...template.children);
      document.head.append(added);
    }
  }, []);
};

export default useFacebookPixel;
