import {decorate, observable, runInAction} from "mobx";
import {Api} from "../middleware/api";
import {EntityStore} from "./entityStore";
import {Entry} from "../models/entry";

decorate(Entry, {
  user_id: observable,
  user: observable,
  show_id: observable,
  quantity: observable,
  entry_type: observable
});

export class EntryStore extends EntityStore {

  entries = [];

  constructor(rootStore) {
    super(rootStore, 'entries', Api.entries, Entry);
  }

  scan(value, showId, options = {}) {
    const { action = 'scan'} = options;
    this.pendingRequests++;
    return this.api.scan(value, showId).then((entity) => {
      this.addMessage({type: 'success', title: 'messages.create_success'}, options);
      entity = this.modelClass.fromPlainObject(entity);
      runInAction(() => {
        this.pendingRequests--;
        this.pendingActions[action] = false;
      });
      this.add(entity);
      return entity;
    }).catch(e => this.handleApiError(e));
  }
}

decorate(EntryStore, {
  entries: observable,
});
