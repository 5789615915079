import {useLocation} from 'react-router-dom';

export const useQuery = (name) => {
  const param = new URLSearchParams(useLocation().search).get(name);

  try {
    return JSON.parse(param);
  } catch (e) {
    return param;
  }
};
