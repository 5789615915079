import axios from "../middleware/axios";
import {saveAs} from "file-saver";
import {endsWith} from "lodash";

export const responseFormats = {
  pdf: {
    extension: 'pdf',
    type: 'application/pdf'
  },
  xlsx: {
    extension: 'xlsx',
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  },
  svg: {
    extension: 'svg',
    type: 'image/svg+xml'
  }
};

export const pathParamsObjToString = (pathParams = {}) => {
  const hasPathParams = !!Object.keys(pathParams).length;
  return hasPathParams ? Object.entries(pathParams).reduce((arrValue, [currKey, currValue]) => {
    return [...arrValue, `${currKey}=${encodeURIComponent(currValue + '')}`];
  }, []).join('&') : '';
}

export const showPdfFromUrl = (url, pathParams = {}) => {
  // window.open has to be called immediately (during the click event), otherwise will be blocked from popup-blocker/ad-blocker
  const win = window.open(window.location, '_blank');

  const params = pathParamsObjToString(pathParams);
  const fullUrl = `${url}${params ? `?${params}` : ''}`;
  return axios.get(fullUrl, {responseType: 'arraybuffer'}).then(({data, headers}) => {

    // const filename = headers['content-disposition'] && headers['content-disposition'].split("filename=").pop().replace(/"/g, '');

    const fileBlob = new Blob([data], {type: "application/pdf"});

    // now we set the real location of the new window/tab
    if (win.document.readyState === 'complete') {
      win.location = window.URL.createObjectURL(fileBlob);
    } else {
      win.onload = () => {
        win.location = window.URL.createObjectURL(fileBlob);
      };
    }
  });
};

export const saveToFile = (data, {filename = 'file', extension = '', type = '', openInBrowser = false} = {}) => {
  const fileBlob = new Blob([data], {type});
  filename = endsWith(filename, `.${extension}`) ? filename : `${filename}.${extension}`;

  if (openInBrowser) {
    const anchor = document.createElement('A');
    anchor.href = window.URL.createObjectURL(fileBlob);
    anchor.target = '_blank';
    anchor.click();
    return;
  }
  return saveAs(fileBlob, filename);
};

export const parseContentDisposition = (headers) => {
  if (!headers) return {};
  const header = headers['content-disposition'];
  if (!header) return {};

  const parts = header.split(';').map(part => part.trim());
  const attributes = {};
  parts.forEach(part => {
    let [name, value] = part.split('=');
    if (value && typeof value === 'string') {
      value = value.replace(/"/g, '');
      value = decodeURIComponent(value)
    }
    attributes[name] = value;
  });
  return attributes;
};

export const saveToPDF = (data, {filename, openInBrowser = false} = {}) => {
  return saveToFile(data, {
    filename,
    extension: responseFormats.pdf.extension,
    type: responseFormats.pdf.type,
    openInBrowser
  });
};

export const downloadPdfFromUrl = (url, options = {}, pathParams = {}) => {
  const params = pathParamsObjToString(pathParams);
  const fullUrl = `${url}${params ? `?${params}` : ''}`;

  return axios.get(fullUrl, {responseType: 'arraybuffer'}).then(({data, headers}) => {
    const filename = parseContentDisposition(headers).filename;
    saveToPDF(data, {options, filename});
  });
};
