import * as React from "react";
import compose from "compose-function";
import {withTranslation} from "react-i18next";
import {inject, observer} from "mobx-react";
import {Grid} from "semantic-ui-react";

class Agb extends React.Component {
  render() {

    return (
      <>
        <Grid>
          <Grid.Row>
            <Grid.Column width={12}>
              <h1>Allgemeine Geschäftsbedingungen cirqu’Aarau</h1>
              <h2>1. Geltungsbereich</h2>
              <p>
                Die Allgemeinen Geschäftsbedingungen (AGB) regeln die rechtlichen Beziehungen zwischen
                dem Kunden einerseits und cirqu’ andererseits. Mit dem Erwerb einer Eintrittskarte oder dem
                Erwerb eines Festivalpasses gelten diese AGB als vereinbart.
              </p>
              <h2>2. Tickets/ Sitzplätze</h2>
              <p>Der Erwerb eines Tickets beinhaltet keine bestimmte Sitzplatzwahl. cirqu’ behält sich das Recht
                vor, auch nach Beginn des Vorverkaufs den Saalplan zu ändern und dem Kunden andere Plätze
                zuzuweisen (sofern nummeriert).</p>
              <h2>3. Reservationen</h2>
              <p>Reservierte Karten sind bis 30 Minuten vor Showbeginn an der Hauptkasse abzuholen. Nach
                Ablauf dieser Frist erlischt die Reservierung. Reservierte Tickets, die nicht bis mindestens 24
                Stunden vor Festivalbeginn annulliert werden, sind zu bezahlen.</p>
              <h2>4. Gruppen</h2>
              <p>Gruppen ab 10 Personen haben dem cirqu’ mindestens 10 Tage vor Festivalbeginn mitzuteilen,
                wie viele Personen definitiv am Anlass teilnehmen bzw. wie viele Tickets tatsächlich benötigt
                werden. Erfolgt keine derartige Mitteilung innert Frist, sind sämtliche reservierten Tickets zu
                bezahlen.</p>
              <h2>5. Rückgabe</h2>
              <p>Gekaufte Karten werden weder zurückgenommen noch umgetauscht. Bei Zuspätkommen oder
                bei Nichterscheinen zu Vorstellungsbeginn besteht kein Anspruch auf Erstattung nicht in
                Anspruch genommener und somit verfallener Eintrittskarten.
              </p>
              <p>Kann eine Veranstaltung aufgrund eines unvorhersehbaren Ereignisses, welches nicht im
                Einflussbereich des Veranstalters liegt (z.B. höhere Gewalt, Epidemie/Pandemie, behördliche
                Restriktionen oder aus Sicherheitsgründen) nicht durchgeführt werden, so kann der
                Veranstalter hierfür nicht haftbar gemacht werden. Die Ticketpreise werden grundsätzlich nicht
                zurückerstattet. Die Rückerstattung/Umtausch gekaufter Tickets, liegt im Ermessen des
                Veranstalters.</p>
              <h2>6. Spielplanänderung</h2>
              <p>Spielplanänderungen bleiben vorbehalten. cirqu’ behält sich auch nach Beginn des Vorverkaufs
                vor, eine Vorstellung abzusagen oder durch die Aufführung eines anderen Werkes zu ersetzen
                sowie das Datum, die Beginnzeit und die Besetzung einer Vorstellung zu ändern. Jede weitere
                Haftung von cirqu’ ist ausgeschlossen; insbesondere haftet cirqu’ nicht für Folgeschäden. Bei
                Änderungen der Besetzung einer Vorstellung besteht kein Anspruch auf Rückvergütung des
                Kaufpreises.</p>
              <h2>7. Haftung</h2>
              <p>Für Schäden jeder Art, die der Kunde in den Räumen des Festivals erleidet, haftet cirqu’ nur im
                Fall der grobfahrlässigen oder absichtlichen Verletzung einer vertraglichen Pflicht durch seine
                Vertreter und Hilfspersonen. Jede weitere Haftung wird hiermit wegbedungen.</p>
              <h2>8. Geltung</h2>
              <p>Diese AGB treten am 27. März 2019 in Kraft. Sollte eine Bestimmung dieser AGB unwirksam sein,
                so berührt dies die Rechtswirksamkeit der übrigen Bestimmungen dieser AGB nicht.
                Gerichtsstand ist Aarau.</p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </>
    )
  }

}

export default compose(
  withTranslation('translation'),
  inject('store'),
  observer
)(Agb);