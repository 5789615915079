import * as React from "react";
import {useEffect, useState} from "react";
import {loadStripe} from "@stripe/stripe-js";
import config from "../../config";
import {useQuery} from "../../hooks/useQuery";
import {Button, Card, Icon, Loader, Message} from "semantic-ui-react";
import {formatCHF} from "../../tools/currency";
import {Link} from "react-router-dom";
import {route} from "../../routing/routing";
import {useTranslation} from "react-i18next";

const CreditCardConfirm = () => {
  const stripePromise = loadStripe(config.STRIPE_PUBLIC_KEY, {apiVersion: '2020-08-27'});
  const paymentIntentParam = useQuery('payment_intent');
  const paymentIntentClientSecretParam = useQuery('payment_intent_client_secret');
  const [paymentIntent, setPaymentIntent] = useState();
  const {t} = useTranslation();

  useEffect(() => {
    if (stripePromise && paymentIntentParam && paymentIntentClientSecretParam) {
      stripePromise.then(stripe => {
        stripe.retrievePaymentIntent(paymentIntentClientSecretParam).then((result) => {
          setPaymentIntent(result.paymentIntent);
        });
      });
    }
  }, [stripePromise, paymentIntentParam, paymentIntentClientSecretParam]);

  if (!paymentIntent) {
    return <Loader active/>
  }

  return (
    <div>
      {(!paymentIntent.next_action && paymentIntent.status === 'succeeded') && (
        <Card fluid color={'green'}>
          <Card.Content>
            <Card.Header>
              <h2>
                {t('stripe.confirm.success_heading')}
              </h2>
            </Card.Header>
            <Card.Meta>
              <span style={{fontWeight: 700}}>{t('stripe.confirm.your_reference')}:</span> {paymentIntent.receipt_email}
            </Card.Meta>
            <Card.Description style={{padding: '2rem 0'}}>
              <Message>
                <Icon name={'check'}
                      color={'green'}/> {t('stripe.confirm.success', {amount: formatCHF(paymentIntent.amount / 100)})}
              </Message>
            </Card.Description>
            <Card.Description>
              <Button primary as={Link} to={route('/')}>{t('stripe.confirm.back_to_start')}</Button>
            </Card.Description>
          </Card.Content>
        </Card>
      )}
      {(paymentIntent.next_action || paymentIntent.status !== 'succeeded') && (
        <Card fluid color={'red'}>
          <Card.Content>
            <Card.Header>
              <h2>
                {t('stripe.confirm.error_heading')}
              </h2>
            </Card.Header>
            <Card.Meta>
              <span style={{fontWeight: 700}}>{t('stripe.confirm.your_reference')}:</span> {paymentIntent.receipt_email}
            </Card.Meta>
            <Card.Description style={{padding: '2rem 0'}}>
              <Message>
                <Icon
                  name={'x icon'}
                  color={'red'}
                />
                {t('stripe.confirm.error', {amount: formatCHF(paymentIntent.amount / 100)})}&nbsp;
                <a style={{textDecoration: 'underline'}} href="mailto:info@cirquaarau.ch">info@cirquaarau.ch</a>
              </Message>
            </Card.Description>
            <Card.Description>
              <Button primary as={Link} to={route('/')}>{t('stripe.confirm.back_to_start')}</Button>
            </Card.Description>
          </Card.Content>
        </Card>
      )}
    </div>
  )
}

export default CreditCardConfirm;
