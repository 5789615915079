import {BaseModel} from "./base";

export class Festival extends BaseModel {
  id = 0;
  name = '';
  starts_at = null;
  starts_at_formatted = null;
  ends_at = null;
  ends_at_formatted = null;
  location = '';
  presale_active = false;
  past = false;
  presale_description = '';
  current = false;

  static fromPlainObject(plainObject) {
    const festival = new Festival();
    return Object.assign(festival, plainObject);
  }
}
