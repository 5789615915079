import * as React from 'react';
import {withTranslation} from 'react-i18next';
import {inject, observer} from "mobx-react";
import compose from "compose-function";
import TicketListItem from "./listItem";
import {i18nArAttr, i18nArModel} from "@semabit/rails-i18n-tools";
import {EntityTable} from "../shared/tables";
import {loadEntities} from "../shared/loadEntities";
import {downloadPdfFromUrl, showPdfFromUrl} from "../../../tools/files";
import {resourcePath} from "../../../middleware/apiPath";
import {presaleOrderResourcePath} from "../../../middleware/endpoints/presaleOrders";
import {receiptsResourcePath} from "../../../middleware/endpoints/receipts";
import {SalesPointSettings} from "../../../models/salesPoint";

class TicketList extends React.Component {

  constructor(props) {
    super(props);
    this.showPresaleOrder = this.showPresaleOrder.bind(this)
    this.downloadPresaleOrder = this.downloadPresaleOrder.bind(this)
  }

  showPresaleOrder(ticket) {
    if (ticket?.presale_order_id) {
      showPdfFromUrl(resourcePath(presaleOrderResourcePath(`/${ticket.presale_order_id}.pdf`)));
      return;
    }
    if (ticket?.receipt_id) {
      showPdfFromUrl(
        resourcePath(receiptsResourcePath(this.props.show.id, `/${ticket.receipt_id}.pdf`)),
        {
          print_mode: SalesPointSettings.print_mode.presale_order
        }
      );
    }
  }

  downloadPresaleOrder(ticket) {
    if (ticket?.presale_order_id) {
      downloadPdfFromUrl(resourcePath(presaleOrderResourcePath(`/${ticket.presale_order_id}.pdf`)));
      return;
    }
    if (ticket?.receipt_id) {
      downloadPdfFromUrl(
        resourcePath(receiptsResourcePath(this.props.show.id, `/${ticket.receipt_id}.pdf`)),
        {},
        {
          print_mode: SalesPointSettings.print_mode.presale_order
        }
      );
    }
  }

  render() {
    const {t} = this.props;
    const headers = [
      t(i18nArAttr('ticket.number')),
      t(i18nArAttr('ticket.state')),
      t(i18nArAttr('common.created_at')),
      t(i18nArAttr('price.price_type')),
      t(i18nArAttr('price.price')),
      t(i18nArAttr('ticket.remarks')),
      `${t(i18nArModel('reservation.one'))} / ${t(i18nArModel('presale_order.one'))}`,
      ''
    ];

    return (
      <div>
        <EntityTable headers={headers}>
          {this.props.tickets.map((ticket) =>
            <TicketListItem
              key={ticket.id}
              ticket={ticket}
              show={this.props.show}
              prices={this.props.prices}
              reservations={this.props.reservations}
              onShow={this.showPresaleOrder}
              onDownload={this.downloadPresaleOrder}
            />
          )}
        </EntityTable>
      </div>
    )
  }
}

export default compose(
  loadEntities('reservationStore', 'reservations', {
    lazyLoad: true,
    storeParams: (props) => ({params: {showId: props.show.id}})
  }),
  loadEntities('priceStore', 'prices', {
    lazyLoad: true,
    storeParams: (props) => ({params: {showId: props.show.id}})
  }),
  loadEntities('ticketStore', 'tickets', {
    storeParams: (props) => ({params: {showId: props.show.id}})
  }),
  withTranslation('translation'),
  inject('store'),
  observer
)(TicketList);
