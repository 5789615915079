import * as React from "react";
import compose from "compose-function";
import ShowForm from "./form";
import {inject, observer} from "mobx-react";
import {adminRoute} from "../../../routing/routing";
import {i18nArModel} from "@semabit/rails-i18n-tools";
import loadEntity from "../shared/loadEntity";
import {EntityDetailLayout} from "../shared/entityDetailLayout";
import {withTranslation} from "react-i18next";

class EditSalesPoint extends React.Component {
  render() {
    const {t} = this.props;

    return <EntityDetailLayout title={t('admin.entity.edit', {model_name: t(i18nArModel(`sales_point.one`))})}
                               backPath={`/sales_points/${this.props.match.params.id}`}>
      <ShowForm redirectTo={adminRoute(`/sales_points/${this.props.match.params.id}`)} salesPoint={this.props.salesPoint}/>
    </EntityDetailLayout>
  }
}

export default compose(
  loadEntity('salesPointStore', 'salesPoint', adminRoute('/sales_points')),
  withTranslation('translation'),
  inject('store'),
  observer
)(EditSalesPoint);
