import {useFormState} from "informed";
import {Form} from "semantic-ui-react";
import {i18nArAttr} from "@semabit/rails-i18n-tools";
import Text from "../../forms/text";
import {useTranslation} from "react-i18next";

const FestivalPassValidationFormFields = () => {
  const {errors} = useFormState();
  const {t} = useTranslation();

  return (
    <Form.Field required style={{maxWidth: '220px'}}>
      <label
        className={'visually-hidden'}
        htmlFor={'festival_pass_number'}
      >
        {t(i18nArAttr('festival_pass.number'))}
      </label>
      <Text
        placeholder={t(i18nArAttr('festival_pass.number'))}
        field="festival_pass_number"
        errors={[errors.festival_pass_number].filter((e) => !!e)}
        validate={(value) => {
          if (!value) return 'Bitte geben Sie Ihre Festivalpass Nummer ein'
        }}
      />
    </Form.Field>
  );
};

export default FestivalPassValidationFormFields;
