import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react";
import {Grid, Header, Icon, Image, Loader, Message} from "semantic-ui-react";
import {i18nArAttr} from "@semabit/rails-i18n-tools";
import {useStore} from "../../hooks/useStore";
import {useParams} from "react-router";
import useCart from "../../hooks/useCart";
import {Link} from "react-router-dom";
import ShowDetailsPrices from "./partials/showDetailsPrices";
import {LabeledIconLinkButton} from "../admin/shared/buttons";
import moment from "moment";

const ShowDetail = () => {

  const store = useStore();
  const {t} = useTranslation();
  const {id: showId} = useParams();
  const [priceCounters, setPriceCounters] = useState({});
  const totalCounter = Object.values(priceCounters).reduce((acc, curr) => acc + curr, 0);
  const festivalpassFormRef = useRef();
  const remarksFormRefs = useRef(new Map());
  const [message, setMessage] = useState(false);
  const [errors, setErrors] = useState([]);
  const prices = store.priceStore.prices;
  const show = store.showStore.getById(showId);
  const {cart} = useCart();
  let description = []
  if (show?.description != null) {
    description = show.description.split(/\r?\n/);
  }
  const hasDiscount = !!store.cartDiscountStore.currentCartDiscountUuid;

  const initPriceCounters = () => {
    const initPriceCounter = {};

    prices.filter(p => !p.internal).forEach((price) => {
      initPriceCounter[price.id] = 0;
    });
    setPriceCounters(initPriceCounter);
  }

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (cart) {
      store.cartTicketStore.loadAll({params: {cartId: cart.number}})
    }
  }, [cart]);

  useEffect(() => {
    if (showId) {
      store.showStore.load(showId);
    }
  }, [showId, cart])

  useEffect(() => {
    if (showId) {
      (async () => {
        await store.priceStore.loadAll({params: {showId, public_only: true}});
        initPriceCounters();
      })();
    }
  }, [showId])

  const handleMinus = (id) => {
    const update = {...priceCounters};
    update[id] = update[id] - 1;
    setPriceCounters(update);
  }

  const handleAdd = (id) => {
    if (totalCounter < show.available_presale_tickets_count) {
      const update = {...priceCounters};
      update[id] = update[id] + 1;
      setPriceCounters(update);
    } else {
      setErrors(['Es können nicht mehr Tickets hinzugefügt werden.']);
      setTimeout(() => {
        setErrors([]);
      }, 4000);
    }
  }

  const successfulAdded = () => {
    setPriceCounters({});
    remarksFormRefs.current.forEach((formApi) => formApi.reset());
    store.showStore.load(show.id).then(() => {
      store.cartStore.load(sessionStorage.getItem('cart')).then(() => {
        setMessage(true);
        setErrors([]);

        initPriceCounters();
        setTimeout(() => {
          setMessage(false);
        }, 4000);
      })
    })
  }

  const handleErrors = (e, price) => {
    if (e) {
      if (e.price?.length) {
        e.price = e.price.map((msg) => {
          if (msg === t('errors.messages.blank')) {
            return t('activerecord.errors.models.cart_ticket.festival_pass.not_found');
          }
          return msg;
        })
      }
      if (e.remarks?.length) {
        e.remarks = e.remarks.map((msg) => {
          if (msg === t('errors.messages.blank')) {
            const label = t(i18nArAttr(`price.ticket_remark_label.${price.price_type}`));

            return t('activerecord.errors.models.cart_ticket.remarks.required', {label});
          }
          return msg;
        })
      }
      setErrors(Object.values(e));
    }
  }

  const handleAddingToExistingCart = async ({cart, price, ticketCount, remarks}) => {
    const existingCartTicket = store.cartTicketStore.cartTickets.find((cartTicket) =>
      cartTicket.show_id === show.id
      && cartTicket.cart_id === cart.id
      && cartTicket.price_id === price.id
    );

    const request = (() => {
      if (price.festival_pass_number) {
        return {
          quantity: ticketCount,
          cart_id: cart.id,
          show_id: show.id,
          cart_number: cart.number,
          festival_pass_number: price.festival_pass_number.trim(),
          cart_discount_uuid: hasDiscount ? store.cartDiscountStore.currentCartDiscountUuid : undefined,
          remarks
        }
      }

      if (existingCartTicket) {
        existingCartTicket.quantity += ticketCount
        existingCartTicket.cart_number = cart.number
        return existingCartTicket;
      }

      return {
        quantity: ticketCount,
        cart_id: cart.id,
        show_id: show.id,
        price_id: price.id,
        cart_number: cart.number,
        cart_discount_uuid: hasDiscount ? store.cartDiscountStore.currentCartDiscountUuid : undefined,
        remarks
      };
    })();

    try {
      if (existingCartTicket) {
        await store.cartTicketStore.update(request);
      } else {
        await store.cartTicketStore.create(request);
      }
      const formApi = festivalpassFormRef.current;
      if (formApi) {
        formApi.setValue('festival_pass_number', '');
      }
      successfulAdded();
    } catch (e) {
      handleErrors(e, price);
    }
  }

  const addTicketsToCart = async (cart, price, ticketCount) => {
    const remarksFormApi = remarksFormRefs.current.get(price.price_type);
    let remarks = '';
    if (remarksFormApi) {
      remarks = remarksFormApi.getValue(price.price_type);
      remarksFormApi.validate();
      if (remarksFormApi.getState().invalid) {
        return undefined;
      }
    }

    if (ticketCount === 0) return;
    await handleAddingToExistingCart({cart, price, remarks, ticketCount});
  }

  if (store.showStore.isLoading) {
    return <Loader active={true}/>
  }

  if (!show) {
    return null;
  }

  return (
    <>
      <Grid.Row style={{marginBottom: 40}}>
        <Grid.Column>
          <Link to={"/"}>
            <Icon name={"chevron left"}/>{t('components.presale.show_details.overview')}
          </Link>
        </Grid.Column>
      </Grid.Row>
      <Grid stackable>
        <Grid.Row>
          <Grid.Column className={"show-detail-header"} width={16}>
            <Header as='h1' style={{marginBottom: 20}}>{show.name}</Header>
          </Grid.Column>
          <Grid.Column width={8}>
            <Image src={show.presale_image.detail_size.url} wrapped ui={true}/>
          </Grid.Column>
          <Grid.Column width={8}>
            <p style={{marginBottom: 15}}>
              <Icon name='map marker alternate' style={{marginRight: 10}}/>
              <span className='date'>{show.location}</span>
            </p>
            <p style={{marginBottom: 5}}>
              <Icon name='calendar alternate outline' style={{marginRight: 10}}/>
              <span
                className='date'>{show.begins_at
                ? moment(show.begins_at).format("DD.MM.YYYY") + " um " +
                moment(show.begins_at).format("HH:mm")
                : '-'}</span>
            </p>
            <p style={{paddingLeft: 25, position: 'relative'}}>
              {
                description.map((line, index) => (
                  <React.Fragment key={index}>{line}{index < (description.length - 1) ? <br/> : ''}</React.Fragment>
                ))
              }
              <Icon style={{position: 'absolute', left: 0, top: 0}} name='info circle'/>
            </p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <Header as='h2' style={{marginBottom: 20}}>
              Tickets kaufen
              <span style={{fontSize: '0.5em', paddingLeft: 40, fontWeight: 300, position: 'relative', top: '-4px'}}>
                <Icon name='circle' style={{color: show.available_presale_tickets_count > 0 ? 'lightgreen' : 'red'}}/>
                {show.available_presale_tickets_count > 0
                  ? t('components.presale.show_details.available_tickets_amount', {amount: show.available_presale_tickets_count})
                  : t('components.presale.show_details.sold_out')
                }
              </span>
            </Header>
          </Grid.Column>

          <Grid.Column width={16}>
            <ShowDetailsPrices
              festivalpassFormRef={festivalpassFormRef}
              remarksFormRefs={remarksFormRefs}
              prices={prices.filter(p => !p.internal)}
              show={show}
              cart={cart}
              totalCounter={totalCounter}
              priceCounters={priceCounters}
              handleAdd={handleAdd}
              handleMinus={handleMinus}
              addTicketsToCart={addTicketsToCart}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{marginTop: 40}}>
          <Grid.Column floated={'left'} width={8}>
            <LabeledIconLinkButton
              primary={false}
              text={t('components.presale.show_details.overview')}
              route={'/'}
              icon={'ticket'}
            />
          </Grid.Column>
          <Grid.Column floated={'right'} width={8} style={{textAlign: 'right'}}>
            <LabeledIconLinkButton
              primary={true}
              text={t('components.presale.show_details.cart')}
              route={'/cart'}
              icon={'cart'}
            />
          </Grid.Column>
        </Grid.Row>
        {message ?
          <Grid.Row>
            <Grid.Column width={14}>
              <Message
                positive
                icon='ticket'
                header={t('components.presale.show_details.add_ticket_success')}
              />
            </Grid.Column>
          </Grid.Row>
          : <></>
        }
        {errors.length ?
          <Grid.Row>
            <Grid.Column width={14}>
              {errors.map((msg, idx) => (
                <Message
                  key={`${idx}.${msg}`}
                  error
                  icon='ticket'
                  header={msg}
                />
              ))}
            </Grid.Column>
          </Grid.Row>
          : <></>
        }
      </Grid>
    </>
  )
}

export default observer(ShowDetail);
