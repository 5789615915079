import * as React from 'react';
import {withTranslation} from "react-i18next";
import {inject, observer} from "mobx-react";
import compose from "compose-function";
import {EntityDetailTable, EntityDetailTableEntry} from "../shared/tables";
import {i18nArAttr, i18nArModel} from "@semabit/rails-i18n-tools";
import {adminRoute} from "../../../routing/routing";
import {DeleteButton, EditButton} from "../shared/buttons";
import {withRouter} from "react-router";
import {confirmDelete} from "../shared/confirm";
import {EntityDetailLayout} from "../shared/entityDetailLayout";
import {loadEntity} from "../shared/loadEntity";
import moment from "moment";
import {loadEntities} from "../shared/loadEntities";
import {downloadPdfFromUrl, showPdfFromUrl} from "../../../tools/files";
import {resourcePath} from "../../../middleware/apiPath";
import {festivalPassResourcePath} from "../../../middleware/endpoints/festivalPasses";
import {Button, Icon} from "semantic-ui-react";

class FestivalPassDetail extends React.Component {

  constructor(props) {
    super(props);
    this.showFestivalPass = this.showFestivalPass.bind(this);
    this.downloadFestivalPass = this.downloadFestivalPass.bind(this);
  }

  async showFestivalPass(festivalPass) {
    showPdfFromUrl(resourcePath(festivalPassResourcePath(this.props.festival.id, `/${festivalPass.id}.pdf`)));
  }

  async downloadFestivalPass(festivalPass) {
    downloadPdfFromUrl(resourcePath(festivalPassResourcePath(this.props.festival.id, `/${festivalPass.id}.pdf`)));
  }

  render() {
    const {t} = this.props;
    let festivalPassCategory = null

    if (this.props.festivalPass) {
      festivalPassCategory = this.props.festivalPassCategories.find(fp => fp.id === this.props.festivalPass.festival_pass_category_id)
    }

    return (
      <EntityDetailLayout title={t(i18nArModel('festival_pass.one'))} backPath={`/festivals/${this.props.festival.id}`}>
        <EditButton
          route={adminRoute(`/festivals/${this.props.festival.id}/festival_passes/${this.props.festivalPass.id}/edit`)}/>
        <DeleteButton onClick={() => this.delete()}/>
        <Button icon onClick={() => this.showFestivalPass(this.props.festivalPass)} labelPosition='left'>
          <Icon name='eye'/>
          Vorschau
        </Button>
        <Button icon onClick={() => this.downloadFestivalPass(this.props.festivalPass)} labelPosition='left'>
          <Icon name='download'/>
          PDF
        </Button>
        <EntityDetailTable>
          <EntityDetailTableEntry
            name={t(i18nArModel('festival.one'))}>{this.props.festival.name}</EntityDetailTableEntry>
          <EntityDetailTableEntry
            name={t(i18nArAttr('festival_pass.category'))}>
            {festivalPassCategory ? festivalPassCategory.name : '-'}
          </EntityDetailTableEntry>
          <EntityDetailTableEntry
            name={t(i18nArAttr('festival_pass.number'))}>
            {this.props.festivalPass.number}
          </EntityDetailTableEntry>
          <EntityDetailTableEntry
            name={t(i18nArAttr('festival_pass.name'))}><b>{this.props.festivalPass.fullName()}</b></EntityDetailTableEntry>
          <EntityDetailTableEntry
            name={t(i18nArAttr('festival_pass.email'))}>{this.props.festivalPass.email}</EntityDetailTableEntry>
          <EntityDetailTableEntry
            name={t(i18nArAttr('festival_pass.birth_date'))}>
            {this.props.festivalPass.birth_date ? moment(this.props.festivalPass.birth_date).format('LL') : '-'}
          </EntityDetailTableEntry>
        </EntityDetailTable>
      </EntityDetailLayout>
    )
  }

  delete() {
    if (confirmDelete(this.props.t, 'festival_pass')) {
      this.props.store.festivalPassStore.delete(this.props.festivalPass.id, {params: {festivalId: this.props.festival.id}}).then(() => {
        this.props.history.push(adminRoute(`/festivals/${this.props.festival.id}`));
      });
    }
  }

  errorCallback(formErrors) {
    console.error({formErrors});
    this.setState({errors: formErrors});
  }
}

export default compose(
  loadEntity('festivalStore', 'festival', adminRoute('/festivals'), {paramName: 'festivalId'}),
  loadEntity('festivalPassStore', 'festivalPass', (props) => adminRoute(`/festivals/${props.festival.id}`)),
  loadEntities('festivalPassCategoryStore', 'festivalPassCategories'),
  withRouter,
  withTranslation('translation'),
  inject('store'),
  observer
)(FestivalPassDetail);
