import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react";
import {EntityDetailTable, EntityDetailTableEntry} from "../shared/tables";
import {i18nArAttr, i18nArModel} from "@semabit/rails-i18n-tools";
import {adminRoute} from "../../../routing/routing";
import {DeleteButton, EditButton} from "../shared/buttons";
import {useHistory, useParams} from "react-router";
import {confirmDelete} from "../shared/confirm";
import {EntityDetailLayout} from "../shared/entityDetailLayout";
import {useStore} from "../../../hooks/useStore";
import {Button, Icon, Loader, Popup} from "semantic-ui-react";
import LineBreakText from "../../shared/lineBreakText";

const CartDiscountDetail = () => {

  const store = useStore();
  const history = useHistory();
  const {t} = useTranslation();
  const {festivalId, id: cartDiscountId} = useParams();
  const [loadingFestival, setLoadingFestival] = useState(false);
  const [loadingCartDiscount, setLoadingCartDiscount] = useState(false);
  const loading = loadingFestival || loadingCartDiscount;
  const festival = store.festivalStore.getById(festivalId);
  const cartDiscount = store.cartDiscountStore.getById(cartDiscountId);
  const copyToClipboardTimeoutRef = useRef(null);
  const [copyToClipboardOpen, setCopyToClipboardOpen] = useState(false);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (festivalId && !festival && !loadingFestival) {
      (async () => {
        setLoadingFestival(true);
        await store.festivalStore.load(festivalId);
        setLoadingFestival(false);
      })();
    }
  }, [festivalId]);

  useEffect(() => {
    if (cartDiscountId && !cartDiscount && !loadingCartDiscount) {
      (async () => {
        setLoadingCartDiscount(true);
        await store.cartDiscountStore.load(cartDiscountId, {params: {festivalId}});
        setLoadingCartDiscount(false);
      })();
    }
  }, [cartDiscountId]);

  const handleDelete = () => {
    if (confirmDelete(t, 'cart_discount')) {
      store.cartDiscountStore.delete(cartDiscountId, {params: {festivalId}}).then(() => {
        history.push(adminRoute(`/festivals/${festivalId}`));
      });
    }
  }

  const handleCopyToClipboardOpen = () => {
    setCopyToClipboardOpen(true);
    copyToClipboardTimeoutRef.current = setTimeout(() => {
      clearTimeout(copyToClipboardTimeoutRef.current);
      copyToClipboardTimeoutRef.current = null;
      setCopyToClipboardOpen(false);
    }, 2500);
  }

  const handleCopyToClipboardClose = () => {
    setCopyToClipboardOpen(false);
    if (copyToClipboardTimeoutRef.current) {
      clearTimeout(copyToClipboardTimeoutRef.current);
      copyToClipboardTimeoutRef.current = null;
    }
  }

  return (
    <EntityDetailLayout title={t(i18nArModel('cart_discount.one'))} backPath={`/festivals/${festivalId}`}>
      <EditButton
        route={adminRoute(`/festivals/${festivalId}/cart_discounts/${cartDiscountId}/edit`)}
        disabled={loading}
      />
      <DeleteButton onClick={() => handleDelete()}/>
      <EntityDetailTable>
        <EntityDetailTableEntry name={t(i18nArAttr('cart_discount.uuid'))}>
          {loadingCartDiscount ? <Loader/> : cartDiscount?.uuid}
        </EntityDetailTableEntry>
        <EntityDetailTableEntry name={t(i18nArAttr('cart_discount.label'))}>
          {loadingCartDiscount ? <Loader/> : cartDiscount?.label}
        </EntityDetailTableEntry>
        <EntityDetailTableEntry name={t(i18nArAttr('cart_discount.description'))}>
          <LineBreakText>
            {loadingCartDiscount ? <Loader/> : cartDiscount?.description}
          </LineBreakText>
        </EntityDetailTableEntry>
        <EntityDetailTableEntry name={t(i18nArAttr('cart_discount.percentage'))}>
          {loadingCartDiscount ? <Loader/> : cartDiscount?.percentageFormatted}
        </EntityDetailTableEntry>
        <EntityDetailTableEntry name={t(i18nArAttr('cart_discount.url'))}>
          {loadingCartDiscount ? <Loader/> : `${window?.location?.origin || ''}?${cartDiscount?.queryString}`}
          &nbsp;
          &nbsp;
          <Popup
            trigger={(
              <Button
                onClick={() => {
                  navigator.clipboard.writeText(`${window?.location?.origin || ''}?${cartDiscount?.queryString}`)
                }}
                size={'mini'}
                icon
              >
                <Icon name={'clipboard outline'}/>
              </Button>
            )}
            content={t('components.admin.cart_discount.detail.copy_to_clipboard_popup_content')}
            on='click'
            open={copyToClipboardOpen}
            onClose={handleCopyToClipboardClose}
            onOpen={handleCopyToClipboardOpen}
            position='top center'
          />

        </EntityDetailTableEntry>
      </EntityDetailTable>
    </EntityDetailLayout>
  )
}

export default observer(CartDiscountDetail);
