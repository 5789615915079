import * as React from 'react';
import {withTranslation} from 'react-i18next';
import {inject, observer} from "mobx-react";
import {adminRoute} from "../../../routing/routing";
import compose from "compose-function";
import ShowListItem from "./listItem";
import {LabeledIconLinkButton} from "../shared/buttons";
import {i18nArAttr, i18nArModel} from "@semabit/rails-i18n-tools";
import {EntityTable} from "../shared/tables";
import {loadEntities} from "../shared/loadEntities";
import {Message, Select} from "semantic-ui-react";

class ShowList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFestival: null
    };
  }

  componentDidMount() {
    if (this.props.festivals.length) {
      const firstFestival = this.props.festivals[0];
      const currentFestivals = this.props.festivals.filter((festival) => !festival.past)
      if (currentFestivals.length) {
        this.setState({selectedFestival: currentFestivals[0].id});
      } else {
        this.setState({selectedFestival: firstFestival.id});
      }
    }
  }

  render() {
    const {t, festivals} = this.props;

    if (!this.state.selectedFestival) return null;

    const headers = [
      t(i18nArAttr('show.name')),
      t(i18nArModel('festival.one')),
      t(i18nArAttr('show.location')),
      t(i18nArAttr('show.begins_at')),
      t(i18nArAttr('show.available_tickets_count')),
      t(i18nArAttr('show.max_tickets_available_for_presale_count')),
      t(i18nArAttr('show.presale_starts_at')),
      t(i18nArAttr('show.presale_ends_at')),
      ''
    ];

    const handleOnChange = (e, data) => {
      this.setState({
        selectedFestival: data.value
      })
    }

    const options = festivals.map((festival) => ({
      key: festival.id,
      value: festival.id,
      text: festival.name,
    }))

    const filteredShows = this.props.shows.filter((show) => !this.state.selectedFestival || (show.festival_id === this.state.selectedFestival))

    return (
      <>
        <div style={{marginBottom: '40px'}}>
          <Select
            field={'selected_festival'}
            defaultValue={this.state.selectedFestival}
            placeholder={'Wählen Sie ein Festival aus'}
            options={options}
            onChange={handleOnChange}
          />
        </div>
        <div>
          <LabeledIconLinkButton
            text={t(i18nArModel('show.one'))}
            route={adminRoute(`/shows/create`)}
          />
          {filteredShows.length > 0 ? (
            <EntityTable headers={headers}>
              {filteredShows.map((show) =>
                <ShowListItem
                  key={show.id}
                  show={show}
                  festival={this.props.festivals.find((festival) => festival.id === show.festival_id)}
                />
              )}
            </EntityTable>
          ) : <Message>Für dieses Festival wurden noch keine Shows erfasst</Message>}
        </div>
      </>

    )
  }
}

export default compose(
  loadEntities('festivalStore', 'festivals'),
  loadEntities('showStore', 'shows'),
  withTranslation('translation'),
  inject('store'),
  observer
)(ShowList);
