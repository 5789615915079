import {Form, Loader} from "semantic-ui-react";
import * as React from "react";
import {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useStore} from "../../../../hooks/useStore";
import {observer} from "mobx-react";
import {i18nArModel} from "@semabit/rails-i18n-tools";
import Select from "../../../forms/select";

const PriceFestivalPassCategoriesSelect = ({errors = {}, show}) => {

  const store = useStore();
  const {t} = useTranslation();
  const festival = store.festivalStore.getById(show.festival_id);

  const festivalPassCategoryOptions = (() => {
    if (festival) {
      return store.festivalPassCategoryStore.festivalPassCategories
        .filter((category) => category.festival_id === festival.id)
        .map((category) => {
          return {value: category.id, text: category.name}
        });
    }

    return store.festivalPassCategoryStore.festivalPassCategories
      .map((category) => {
        return {value: category.id, text: category.name}
      });
  })();

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    store.festivalPassCategoryStore.loadAll();
  }, [festival]);

  useEffect(() => {
    if (!festival && show?.festival_id) {
      store.festivalStore.load(show.festival_id);
    }
  }, [show]);

  return (
    <Form.Field>
      {(store.festivalPassCategoryStore.isLoading || store.festivalStore.isLoading) && (
        <Loader/>
      )}
      {!(store.festivalPassCategoryStore.isLoading || store.festivalStore.isLoading) && (
        <>
          <label htmlFor={'price_type'}>{t(i18nArModel('festival_pass_category.one'))}*</label>
          <Select
            field={'festival_pass_category_id'}
            errors={errors.festival_pass_category_id}
            options={festivalPassCategoryOptions}
          />
        </>
      )}
    </Form.Field>
  );
}

export default observer(PriceFestivalPassCategoriesSelect);
