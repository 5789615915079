import {BaseModel} from "./base";
import {Ticket} from "./ticket";

export class PresaleOrder extends BaseModel {
  address_id = 0;
  address = null;
  tickets = null;
  tickets_count = 0;
  festival = 0;
  total_price = 0;
  total_price_formatted = '';
  current_mail_delivery_state = '';
  current_mail_delivery_error = '';

  static fromPlainObject(plainObject) {
    const presaleOrder = new PresaleOrder();
    const initializedPresaleOrder = Object.assign(presaleOrder, plainObject);

    if (initializedPresaleOrder.tickets?.length > 0) {
      initializedPresaleOrder.tickets = initializedPresaleOrder.tickets.map(Ticket.fromPlainObject)
    }

    return initializedPresaleOrder;
  }
}

